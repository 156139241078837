// react
import { useState } from "react";
// components
import dateformat from "dateformat";
import UsernameModal from "../../../UI/Modal/UsernameModal/UsernameModal";
import EmailModal from "../../../UI/Modal/EmailModal/EmailModal";
import PhoneNumberModal from "../../../UI/Modal/PhoneNumberModal/PhoneNumberModal";
// css
import classes from "./AuthorDetailsTables.module.css";
import BankNumberModal from "../../../UI/Modal/BankNumberModal/BankNumberModal";
// assets
// import plus from "../../../../assets/icons/plus.svg";

const AuthorDetailsTables = (props) => {
  // props
  const { user, getDetails } = props;
  // state
  const [usernameModalVisible, setUsernameModalVisible] = useState(false);
  const [emailModalVisible, setEmailModalVisible] = useState(false);
  const [phoneNumberModalVisible, setPhoneNumberModalVisible] = useState(false);
  const [bankAccountModalVisible, setBankAccountModalVisible] = useState(false);

  // handlers

  // username
  const openUsernameModalHandler = () => {
    setUsernameModalVisible(true);
  };
  const closeUsernameModalHandler = () => {
    setUsernameModalVisible(false);
  };
  // email
  const openEmailModalHandler = () => {
    setEmailModalVisible(true);
  };
  const closeEmailModalHandler = () => {
    setEmailModalVisible(false);
  };
  // phone number
  const openPhoneNumberModalHandler = () => {
    setPhoneNumberModalVisible(true);
  };
  const closePhoneNumberModalHandler = () => {
    setPhoneNumberModalVisible(false);
  };
  //bank account
  const openBankAccountModalHandler = () => {
    setBankAccountModalVisible(true);
  };
  const closeBankAccountModalHandler = () => {
    setBankAccountModalVisible(false);
  };

  // view
  return (
    <div className={classes.AuthorDetailsTables}>
      <table className={classes.AuthorDetailsTables__Table}>
        <tbody>
          <tr>
            <th>اسم الكاتب</th>
            <td>{user.userName}</td>
            <td>
              <button
                className={classes.AuthorDetailsTables__Table__Btn}
                onClick={openUsernameModalHandler}
              >
                تعديل
              </button>
            </td>
          </tr>
          <tr>
            <th>تاريخ الاشتراك</th>
            <td>{dateformat(user.joinedDate, "yyyy-mm-dd")}</td>
          </tr>
          <tr>
            <th>الحساب البنكي</th>
            <td>{user.BankAccount || "لا يوجد"}</td>
            <td>
              <button
                className={classes.AuthorDetailsTables__Table__Btn}
                onClick={openBankAccountModalHandler}
              >
                تعديل
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      <table className={classes.AuthorDetailsTables__Table}>
        <tbody>
          <tr>
            <th>الايميل</th>
            <td>{user.email}</td>
            <td>
              {/* <button
                className={classes.AuthorDetailsTables__Table__Btn}
                onClick={openEmailModalHandler}
              >
                تعديل
              </button> */}
            </td>
          </tr>
          <tr>
            <th>الهاتف</th>
            <td>{user.phoneNumber}</td>
            <td>
              {/* <button
                className={classes.AuthorDetailsTables__Table__Btn}
                onClick={openPhoneNumberModalHandler}
              >
                تعديل
              </button> */}
            </td>
          </tr>
        </tbody>
      </table>
      <UsernameModal
        close={closeUsernameModalHandler}
        visible={usernameModalVisible}
        getDetails={getDetails}
        currentUsername={user.userName}
      />
      <EmailModal
        close={closeEmailModalHandler}
        visible={emailModalVisible}
        getDetails={getDetails}
        currentEmail={user.email}
      />
      <PhoneNumberModal
        close={closePhoneNumberModalHandler}
        visible={phoneNumberModalVisible}
        getDetails={getDetails}
        currentPhoneNumber={user.phoneNumber}
      />
       <BankNumberModal
        close={closeBankAccountModalHandler}
        visible={bankAccountModalVisible}
        getDetails={getDetails}
        currentUsername={user.BankAccount}
      />
    </div>
  );
};

export default AuthorDetailsTables;
