// components
import BookCloudApps from "./BookCloudApps/BookCloudApps";

// css
import classes from "./AppSection.module.css";

const AppSection = () => {
  return (
    <div className={classes.AppSection}>
      <h3 className={classes.AppSection__Header}>حمل التطبيق لتتمكن من القراءة</h3>
      <BookCloudApps />
    </div>
  );
};

export default AppSection;
