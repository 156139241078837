// css
import classes from "./HorizontalWriters.module.css";
// classnames
import classNames from "classnames";
// components
import Slick from "../UI/Slick/Slick";
import Writer from "./Writer/Writer";
import Info from "..//UI/Info/Info";

const carouselSettings = {
  pagination: false,
  itemsToShow: 4,
  itemsToScroll: 1,
  isRTL: true,
};

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 400, itemsToShow: 2 },
  { width: 740, itemsToShow: 3 },
];

const HorizontalWriters = (props) => {
  const { margin, writers, title } = props;

  let content;
  if (writers.length !== 0) {
    content = (
      <Slick settings={carouselSettings} breakPoints={breakPoints}>
        {writers.map((writer, index) => (
          <Writer key={index} writer={writer} />
        ))}
      </Slick>
    );
  } else {
    content = (
      <div className={classes.HorizontalWriters__Feedback}>
        <Info infoMessage="عفوا لا يوجد نتائج بحث" />
      </div>
    );
  }
  return (
    <section
      className={classNames(
        classes.HorizontalWriters,
        classes[`HorizontalWriters--mb-${margin.bottom}`]
      )}
    >
      <header className={classes.HorizontalWriters__Header}>
        <h2 className={classes.HorizontalWriters__Header__Title}>{title}</h2>
      </header>
      <section>{content}</section>
    </section>
  );
};

export default HorizontalWriters;
