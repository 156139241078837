// components
import Spinner from "../Spinner/Spinner";
// css
import classes from "./PrimaryButton.module.css";
// classnames
import classNames from "classnames";

const PrimaryButton = (props) => {
  // props
  const { text, clicked, loading, disabled, rounded, type, buttonRef } = props;

  return (
    <button
      className={classNames(classes.PrimaryButton, {
        [classes["PrimaryButton--rounded"]]: rounded,
      })}
      onClick={clicked}
      disabled={disabled}
      type={type}
      ref={buttonRef}
    >
      {loading ? <Spinner /> : text}
    </button>
  );
};

export default PrimaryButton;
