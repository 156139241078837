// endpoints
import * as authorEndPoints from "../constants/endpoints/author";
// axios
import { axiosMainInstance, axiosPrivateInstance } from "../axios/axios";

export const getAuthorDetails = (params) => {
  return axiosMainInstance({
    url: authorEndPoints.getAuthorDetails,
    method: "GET",
    params,
  });
};


export const getAuthorIncomeBreakdown = () => {
  return axiosPrivateInstance({
    url: authorEndPoints.getAuthorIncomeBreakdown,
    method: "GET",
    responseType: 'blob'

  }).then((response) => {
    // create file link in browser's memory
    const href = URL.createObjectURL(response.data);

    // create "a" HTML element with href to file & click
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', 'IncomeBreakdown.xlsx'); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    URL.revokeObjectURL(href);
});;
};