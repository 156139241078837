// components
import SortDropdown from "../../SortDropdown/SortDropdown";
import AddResourceLink from "../../AddResourceLink/AddResourceLink";
// constants
import { booksSortOptions } from "../../../constants/sortOptions";
// css
import classes from "./ControlsBar.module.css";

const ControlsBar = (props) => {
  // props
  const { onSortOptionClick, initialSortOption } = props;
  // data
  const sortInitialValue = booksSortOptions.find(
    (option) => option.key === initialSortOption
  )?.label;

  // view
  return (
    <div className={classes.ControlsBar}>
      <SortDropdown
        onSortOptionClick={onSortOptionClick}
        sortOptions={booksSortOptions}
        initialValue={sortInitialValue}
      />
      <AddResourceLink path="/books/add" resourceName="أضف كتاب" />
    </div>
  );
};

export default ControlsBar;
