// components
import Spinner from "../Spinner/Spinner";
// css
import classes from "./HoldScreen.module.css";

const HoldScreen = () => {
  return (
    <div className={classes.HoldScreen}>
      <div className="spinner-parent w-full h-full">
        <Spinner />
      </div>
    </div>
  );
};

export default HoldScreen;
