// endpoints
import * as adEndPoints from "../constants/endpoints/ads";
// axios
import { axiosMainInstance } from "../axios/axios";

export const getRandomAd = (params) => {
  return axiosMainInstance({
    url: adEndPoints.getRandomAd,
    method: "GET",
    params,
  });
};
