// react
import { useState, useRef } from "react";
// components
import Overlay from "../../Overlay/Overlay";
import Modal from "../Modal";
import Spinner from "../../Spinner/Spinner";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CancelButton from "../../CancelButton/CancelButton";
import { toast } from "react-toastify";
// services
import { updateProfilePhoto } from "../../../../services/account";
// css
import classes from "./ProfileImageModal.module.css";
// classnames
import classNames from "classnames";

const ProfileImageModal = (props) => {
  // props
  const { visible, close, getDetails } = props;
  // state
  const [image, setImage] = useState({
    value: null,
    hasError: true,
    touched: false,
    errorMessage: "",
  });
  const [uploadLoading, setUploadLoading] = useState(false);
  // refs
  const inputRef = useRef();

  // handler
  const fileChangedHandler = (event) => {
    const file = event.target.files[0];
    // validate
    let valid = true;
    if (!/(\.png|\.jpg|\.jpeg)$/i.exec(file.name)) {
      valid = false;
    }
    // update state
    setImage({
      touched: true,
      value: valid ? file : null,
      hasError: valid ? false : true,
      errorMessage: valid ? "" : "من فضل اختر صيغة صحيحة",
    });
  };

  const closeHandler = () => {
    setImage({
      value: null,
      hasError: true,
      touched: false,
      errorMessage: "",
    });
    setUploadLoading(false);
    inputRef.current.value = "";
    close();
  };

  const updateImage = async (event) => {
    event.preventDefault();
    // validate
    if (!image.value) {
      return;
    }
    // data
    const data = new FormData();
    data.append("Image", image.value);
    setUploadLoading(true);

    try {
      await updateProfilePhoto(data);
      getDetails();
      toast.success("تم تحديث صورة الملف الشخصي بنجاح", {
        position: "bottom-right",
      });
      close();
    } catch {
      toast.error("حدث خطأ ما اثناء تحديث صورة الملف الشخصي", {
        position: "bottom-right",
      });
    }

    setUploadLoading(false);
  };

  return (
    <>
      {visible && <Overlay clicked={closeHandler} />}
      <Modal visible={visible}>
        <form onSubmit={updateImage} className={classes.ProfileForm}>
          <div className="input-control">
            <label
              htmlFor="image"
              className={classNames(
                "input-control__label",
                "input-control__label--required"
              )}
            >
              اختر صورة جديدة .. (png,jpg,jpeg)
            </label>
            <input
              id="image"
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={fileChangedHandler}
              className="input-control__input"
              ref={inputRef}
            />
            {image.touched && image.hasError && (
              <p className="input-control__error">{image.errorMessage}</p>
            )}
          </div>

          <div className={classes.ProfileForm__Actions}>
            <div className={classes.ProfileForm__Actions__Cancel}>
              <CancelButton
                type="button"
                clicked={closeHandler}
                text="غلق"
                rounded
              />
            </div>
            {uploadLoading && (
              <div className="spinner-parent">
                <Spinner />
              </div>
            )}
            <div className={classes.ProfileForm__Actions__Submit}>
              <PrimaryButton
                type="submit"
                text="تحميل"
                clicked={updateImage}
                rounded
                disabled={image.hasError || uploadLoading}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ProfileImageModal;
