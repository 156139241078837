const accountSubDirectory = "/Account";

export const loginUser = `${accountSubDirectory}/authenticate`;

export const registerUser = `${accountSubDirectory}/register`;

export const getUserInfo = `${accountSubDirectory}/GetUserInfo`;

export const getUserDetails = `${accountSubDirectory}/GetUserDetails`;

export const updateProfilePhoto = `${accountSubDirectory}/updateProfilePhoto`;

export const updateProfile = `${accountSubDirectory}/updateProfile`;

export const sendVerification = `${accountSubDirectory}/SendVerification`;

export const validateVerificationCode = `${accountSubDirectory}/ValidateVeirifcationCode`;

export const changePassword = `${accountSubDirectory}/changePassword`;

export const googleLogin = `${accountSubDirectory}/GoogleLogin`;

export const updateExternalUserInfo = `${accountSubDirectory}/UpdateExternalUserInfo`;

export const authenticateFacebookExternalUser = `${accountSubDirectory}/AuthenticateFacebookExternalUser`;
