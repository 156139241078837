// components
import Star from "../../UI/Star/Star";
// constants
import { MAXIMUM_RATING_STARS } from "../../../constants/ratings";
// css
import classes from "./Rating.module.css";

const Rating = (props) => {
  const { rating, numberOfRatings, onlyStars } = props;
  const getStars = () => {
    const currentRating = Math.floor(rating);
    const stars = [];
    for (let counter = 0; counter < MAXIMUM_RATING_STARS; counter++) {
      if (currentRating > counter) {
        stars.push("filled");
      } else {
        stars.push("outlined");
      }
    }

    return stars;
  };
  return (
    <div className={classes.Rating}>
      <p className={classes.Rating__Stars}>
        {getStars().map((starType, index) => (
          <Star key={index} filled={starType === "filled"} />
        ))}
      </p>
      {!onlyStars && (
        <p className={classes.Rating__Numbers}>
          <span className={classes.Rating__Numbers__Number}>
            {rating.toFixed(1)}
          </span>
          <span className={classes.Rating__Numbers__Total}>
            {numberOfRatings} تقييم
          </span>
        </p>
      )}
    </div>
  );
};

export default Rating;
