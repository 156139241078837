// react router
import { Link } from "react-router-dom";
// css
import classes from "./Writer.module.css";
// assets
import premiumIcon from "../../../assets/icons/premium.svg";

const Writer = (props) => {
  // props
  const { writer } = props;

  return (
    <article className={classes.Writer}>
      <Link
        to={`/authors/${writer.userId}`}
        className={classes.Writer__ImageLink}
      >
        <img
          src={writer.imgUrl}
          alt={writer.Name}
          className={classes.Writer__ImageLink__Image}
        />
      </Link>

      <div className={classes.Writer__Info}>
        {writer.isVerified ||
          (true && (
            <img
              src={premiumIcon}
              className={classes.Writer__Info__Verified}
              alt="verified"
            />
          ))}
        <Link
          to={`/authors/${writer.userId}`}
          className={classes.Writer__Info__Name}
        >
          {writer.Name}
        </Link>
      </div>
    </article>
  );
};

export default Writer;
