// components
import classNames from "classnames";
import Layout from "../../Layout/Layout";
import { useParams } from "react-router-dom";

// css
import classes from "./CartPaymentStatus.module.css";

const CartPaymentStatus = () => {
  const { s } = useParams();
  console.log(s);

if(s == 1){
  return (
    <Layout shortHeader>
    <div className={classes.Card}>
    <h1 className={classes.MainHeading}>
  <div className={classes.CheckMark} >
  <i >✓</i>
  </div>
<br></br>
    عملية مقبولة
    </h1>
    </div>
  
   
  </Layout>
  );
}else{
  return (
    
    <Layout shortHeader>
      <div className={classes.Card}>
      <h1 className={classes.MainHeading}>
    <div className={classes.CrossMark} >
    <i >✗</i>
    </div>
<br></br>
      عملية مرفوضة
      </h1>
      </div>
    
     
    </Layout>
  );
}


};

export default CartPaymentStatus;
