// constants
import * as booksEndPoints from "../constants/endpoints/books";
// axios
import { axiosMainInstance, axiosPrivateInstance } from "../axios/axios";

export const getMostReadBooks = () => {
  return axiosPrivateInstance({
    url: booksEndPoints.getMostReadBooks,
    method: "GET",
  });
};

export const getMostRecentBooks = () => {
  return axiosPrivateInstance({
    url: booksEndPoints.getMostRecentBooks,
    method: "GET",
  });
};

export const getMostRatedBooks = () => {
  return axiosPrivateInstance({
    url: booksEndPoints.getMostRatedBooks,
    method: "GET",
  });
};

export const getBookCategories = () => {
  return axiosPrivateInstance({
    url: booksEndPoints.getBookCategories,
    method: "GET",
  });
};
export const GetBookCategoriesForAddBook = () => {
  return axiosMainInstance({
    url: booksEndPoints.GetBookCategoriesForAddBook,
    method: "GET",
  });
};
export const getBooksByCategoryId = (categoryId) => {
  return axiosPrivateInstance({
    url: booksEndPoints.getBooksByCategoryId,
    method: "GET",
    params: { CategoryId: categoryId },
  });
};

export const getPagedBooksByCategory = (params) => {
  return axiosPrivateInstance({
    url: booksEndPoints.getPagedBooksByCategory,
    method: "GET",
    params,
  });
};

export const convertToEpub = (book) => {
  const bodyFormData = new FormData();
  bodyFormData.append("BookPdf", book);
  return axiosPrivateInstance({
    url: booksEndPoints.convertToEpub,
    method: "POST",
    data: bodyFormData,
    responseType: "arraybuffer",
    headers: {
      Accept: "application/epub",
    },
  });
};

export const addBook = (formData) => {
  return axiosPrivateInstance({
    url: booksEndPoints.addBook,
    method: "POST",
    data: formData,
  });
};

export const getBookById = (bookId) => {
  return axiosPrivateInstance({
    url: booksEndPoints.getBookById,
    method: "GET",
    params: { BookId: bookId },
  });
};

export const addBookReview = (data) => {
  return axiosPrivateInstance({
    url: booksEndPoints.addBookReview,
    method: "POST",
    data,
  });
};

export const reportBook = (params) => {
  return axiosPrivateInstance({
    url: booksEndPoints.reportBook,
    method: "POST",
    params,
  });
};

export const likeBook = (params) => {
  return axiosPrivateInstance({
    url: booksEndPoints.likeBook,
    method: "POST",
    params,
  });
};

export const removeFromCart = (params) => {
  return axiosPrivateInstance({
    url: booksEndPoints.removeFromCart,
    method: "POST",
    params,
  });
};

export const purchaseBooks = () => {
  return axiosPrivateInstance({
    url: booksEndPoints.purchaseBooks,
    method: "POST",
  });
};

export const addToCart = (bookId) => {

  return axiosPrivateInstance({
    url: booksEndPoints.addToCart,
    method: "POST",
    params: { id: bookId },
  });
  
};

export const getFavouriteBooks = () => {
  return axiosPrivateInstance({
    url: booksEndPoints.getFavouriteBooks,
    method: "GET",
  });
};

export const addOrRemoveFromFavourite = (bookId) => {
  return axiosPrivateInstance({
    url: booksEndPoints.addOrRemoveFromFavourite,
    method: "POST",
    params: { id: bookId },
  });
};

export const getMyLibrary = () => {
  return axiosPrivateInstance({
    url: booksEndPoints.getMyLibrary,
    method: "GET",
  });
};

export const searchForBooksAndAuthors = (params) => {
  return axiosMainInstance({
    url: booksEndPoints.searchForBooksAndAuthors,
    method: "GET",
    params,
  });
};

export const shareBook = (bookId) => {
  return axiosPrivateInstance({
    url: booksEndPoints.shareBook,
    method: "POST",
    params: {
      id: bookId,
    },
  });
};
