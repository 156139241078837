// react
import { useCallback, useEffect, useRef, useState } from "react";
// custom hooks
import { useIsFirstRender } from "../../hooks/useIsFirstRender";
// react router
import { useSearchParams } from "react-router-dom";
// components
import Layout from "../Layout/Layout";
import ControlsBar from "./ControlsBar/ControlsBar";
import HorizontalBooksSections from "./HorizontalBooksSections/HorizontalBooksSections";
import BooksGrid from "./BooksGrid/BooksGrid";
// services
import { getPagedBooksByCategory } from "../../services/books";

const Books = () => {
  // state
  const [books, setBooks] = useState([]);
  const [booksLoading, setBooksLoading] = useState(true);
  const [booksError, setBooksError] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentPage, setCurrentPage] = useState(1);
  const booksContainerRef = useRef();
  const isFirstRender = useIsFirstRender();

  // data
  const pageSize = 12;

  // handlers
  const categorySelectedHandler = (categoryId) => {
    searchParams.set("categoryId", categoryId);
    setSearchParams(searchParams);
    setCurrentPage(1);
  };

  const sortOptionSelectedHandler = (sortKey) => {
    searchParams.set("sortBy", sortKey);
    setSearchParams(searchParams);
    setCurrentPage(1);
  };

  const pageChangedHandler = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  // scroll smoothly
  useEffect(() => {
    if (!isFirstRender) {
      booksContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [searchParams, currentPage]);

  // refine search params
  const getRefinedParams = useCallback(() => {
    const refinedParams = {};

    const searchedCatetogyId = Number(searchParams.get("categoryId"));
    const sortedKey = Number(searchParams.get("sortBy"));

    if (searchParams.has("categoryId") && !isNaN(searchedCatetogyId)) {
      refinedParams.categoryCode = searchedCatetogyId;
    }
    if (searchParams.has("sortBy") && !isNaN(sortedKey)) {
      refinedParams.SortByType = sortedKey;
    }else{
      refinedParams.SortByType = 2;
    }

    return refinedParams;
  }, [searchParams]);

  // effect
  useEffect(() => {
    const fetchBooks = async () => {
      setBooksLoading(true);
      setBooksError("");

      const params = {
        PageNumber: currentPage,
        PageSize: pageSize,
        ...getRefinedParams(),
      };

      try {
        const response = await getPagedBooksByCategory(params);
        setBooks(response.data.books);
        setTotalCount(response.data.metadata.totalCount);
      } catch (error) {
        console.log(error);
        setBooksError("حدث خطأ ما أثناء تحميل الكتب");
      }

      setBooksLoading(false);
    };

    if (Object.keys(getRefinedParams()).length !== 0) {
      fetchBooks();
    }
  }, [getRefinedParams, currentPage]);

  // view
  let content;
  if (Object.keys(getRefinedParams()).length !== 0) {
    content = (
      <BooksGrid
        books={books}
        booksLoading={booksLoading}
        booksError={booksError}
        changePage={pageChangedHandler}
        totalCount={totalCount}
        pageSize={pageSize}
        currentPage={currentPage}
      />
    );
  } else {
    content = <HorizontalBooksSections />;
  }
  return (
    <Layout
      shortHeader
      showFilter
      categorySelectedHandler={categorySelectedHandler}
      currentSelectedCategoryId={searchParams.get("categoryId")}
    >
      <div ref={booksContainerRef}>
        <ControlsBar
          onSortOptionClick={sortOptionSelectedHandler}
          initialSortOption={Number(searchParams.get("sortBy"))}
        />
        {content}
      </div>
    </Layout>
  );
};

export default Books;
