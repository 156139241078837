// components
import Rating from "../../UI/Rating/Rating";
import PriceBadge from "../../UI/Badge/PriceBadge/PriceBadge";
import CartBadge from "../../UI/Badge/CartBadge/CartBadge";
import FavouriteBadge from "../../UI/Badge/FavouriteBadge/FavouriteBadge";
// react router
import { Link } from "react-router-dom";
// classes
import classes from "./Book.module.css";

const Book = (props) => {
  // props
  const { book, hideTitle, hideAuthorName, noCart } = props;

  // view
  return (
    <article className={classes.Book}>
      <div className={classes.Book__Cover}>
        <Link to={`/books/${book.id}`}>
          <img
            src={book.coverUrl}
            alt="book cover"
            className={classes.Book__Cover__Img}
          />
        </Link>
        <span className={classes.Book__Cover__PriceBadgeWrapper}>
          <PriceBadge price={book.price} isFree={book.free} />
        </span>
        <div className={classes.Book__Cover__FooterBadges}>
          {!noCart && (
            <span className={classes.Book__Cover__CartBadgeWrapper}>
              <CartBadge book={book} />
            </span>
          )}
          <span className={classes.Book__Cover__FavouriteBadgeWrapper}>
            <FavouriteBadge book={book} />
          </span>
        </div>
      </div>

      {!hideTitle && (
        <Link
          to={`/books/${book.id}`}
          className={classes.Book__Title}
          title={book.title}
        >
          {book.title}
        </Link>
      )}

      {!hideAuthorName && (
        <Link
          to={`/authors/${book.writerUserId}`}
          className={classes.Book__Author}
        >
          {book.writerUserName}
        </Link>
      )}
      <Rating numberOfRatings={book.numberOfRatings} rating={book.rating} />
    </article>
  );
};

export default Book;
