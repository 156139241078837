// endpoints
import { axiosMainInstance, axiosPrivateInstance } from "../axios/axios";
// axios
import * as postsEndPoints from "../constants/endpoints/posts";

export const getHorizontalPosts = () => {
  return axiosMainInstance({
    url: postsEndPoints.getPosts,
    method: "GET",
    params: {
      PageNumber: 1,
      PageSize: 10,
      SortByType:2,
    },
  });
};

export const getPosts = (params) => {
  return axiosPrivateInstance({
    url: postsEndPoints.getPosts,
    method: "GET",
    params,
  });
};

export const addPost = (data) => {
  return axiosPrivateInstance({
    url: postsEndPoints.addPost,
    method: "POST",
    data,
  });
};

export const getMostRecentPosts = () => {
  return axiosMainInstance({
    url: postsEndPoints.getMostRecentPosts,
    method: "GET",
  });
};

export const getMostReadPosts = () => {
  return axiosMainInstance({
    url: postsEndPoints.getMostReadPosts,
    method: "GET",
  });
};

export const getPost = (params) => {
  return axiosPrivateInstance({
    url: postsEndPoints.getPost,
    method: "GET",
    params,
  });
};

export const addComment = (data) => {
  return axiosPrivateInstance({
    url: postsEndPoints.addComment,
    method: "POST",
    data,
  });
};

export const likePost = (params) => {
  return axiosPrivateInstance({
    url: postsEndPoints.likePost,
    method: "POST",
    params,
  });
};

export const getAuthorPosts = (params) => {
  return axiosMainInstance({
    url: postsEndPoints.getAuthorPosts,
    method: "GET",
    params,
  });
};

export const sharePost = (postId) => {
  return axiosPrivateInstance({
    url: postsEndPoints.sharePost,
    method: "POST",
    params: {
      postId,
    },
  });
};
