// react
import { useState } from "react";
// components
import { toast } from "react-toastify";
import Overlay from "../../Overlay/Overlay";
import Modal from "../Modal";
import Spinner from "../../Spinner/Spinner";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CancelButton from "../../CancelButton/CancelButton";
// services
import { addComment } from "../../../../services/posts";
// css
import classes from "./CommentModal.module.css";
// classnames
import classNames from "classnames";

const CommentModal = (props) => {
  // props
  const { visible, close, postId, getPostDetails } = props;
  // state
  const [comment, setComment] = useState({
    value: "",
    hasError: true,
    touched: false,
    errorMessage: "",
  });
  const [submitCommentLoading, setSubmitCommentLoading] = useState(false);

  // handler
  const commentChangedHandler = (event) => {
    const { value } = event.target;
    // validate
    let valid = true;
    if (value.trim() === "") {
      valid = false;
    }
    // update state
    setComment({
      touched: true,
      value,
      hasError: valid ? false : true,
      errorMessage: valid ? "" : "من فضلك ادخل تعليق",
    });
  };

  const closeHandler = () => {
    setComment({
      value: "",
      hasError: true,
      touched: false,
      errorMessage: "",
    });
    setSubmitCommentLoading(false);
    close();
  };

  const commentHandler = async (event) => {
    event.preventDefault();
    // validate
    if (comment.hasError) {
      return;
    }
    const data = {
      postId: postId,
      commentContent: comment.value,
    };

    setSubmitCommentLoading(true);
    try {
      await addComment(data);
      toast.success("تم نشر التعليق بنجاح", { position: "bottom-right" });
      getPostDetails(false);
      close();
    } catch {
      toast.error("حدث خطأ ما أثناء نشر تعليقك", {
        position: "bottom-right",
      });
    }
    setSubmitCommentLoading(false);
  };

  return (
    <>
      {visible && <Overlay clicked={closeHandler} />}
      <Modal visible={visible}>
        <form onSubmit={commentHandler} className={classes.CommentForm}>
          <div className="input-control">
            <label
              htmlFor="comment"
              className={classNames(
                "input-control__label",
                "input-control__label--required"
              )}
            >
              اكتب تعليقك
            </label>
            <textarea
              id="comment"
              name="comment"
              rows={6}
              value={comment.value}
              onChange={commentChangedHandler}
              className="input-control__input"
            />
            {comment.touched && comment.hasError && (
              <p className="input-control__error">{comment.errorMessage}</p>
            )}
          </div>

          <div className={classes.CommentForm__Actions}>
            <div className={classes.CommentForm__Actions__Cancel}>
              <CancelButton
                type="button"
                clicked={closeHandler}
                text="غلق"
                rounded
              />
            </div>
            {submitCommentLoading && (
              <div className="spinner-parent">
                <Spinner />
              </div>
            )}
            <div className={classes.CommentForm__Actions__Submit}>
              <PrimaryButton
                type="submit"
                text="نشر"
                clicked={commentHandler}
                rounded
                disabled={comment.hasError || submitCommentLoading}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default CommentModal;
