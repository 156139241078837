// react
import { useEffect, useState, useRef } from "react";
// components
import Layout from "../Layout/Layout";
import ContestsIntro from "./ContestsIntro/ContestsIntro";
import Error from "../UI/Error/Error";
import Spinner from "../UI/Spinner/Spinner";
import ContestsSection from "./ContestsSection/ContestsSection";
import Pagination from "../Pagination/Pagination";
// hooks
import { useIsFirstRender } from "../../hooks/useIsFirstRender";
// services
import { getAllContests } from "../../services/contests";

// css
import classes from "./Contests.module.css";

const Contests = () => {
  // state
  const [openContests, setOpenContests] = useState([]);
  const [closedContests, setClosedContests] = useState([]);
  const [openContestsLoading, setOpenContestsLoading] = useState(true);
  const [closedContestsLoading, setClosedContestsLoading] = useState(true);
  const [openContestsLoadError, setOpenContestsLoadError] = useState("");
  const [closedContestsLoadError, setClosedContestsLoadError] = useState("");
  const [openCurrentPage, setOpenCurrentPage] = useState(1);
  const [closedCurrentPage, setClosedCurrentPage] = useState(1);
  const [totalOpenContests, setTotalOpenContests] = useState(0);
  const [totalClosedContests, setTotalClosedContests] = useState(0);
  // data
  const pageLimit = 2;
  // refs
  const openContestsSectionRef = useRef();
  const closedContestsSectionRef = useRef();
  // hooks
  const isFirstRender = useIsFirstRender();

  // handlers
  const openCurrentPageChangedHandler = (pageNumber) => {
    setOpenCurrentPage(pageNumber);
  };
  const closedCurrentPageChangedHandler = (pageNumber) => {
    setClosedCurrentPage(pageNumber);
  };

  useEffect(() => {
    const getOpenContests = async () => {
      const params = {
        PageNumber: openCurrentPage,
        PageSize: pageLimit,
      };
      setOpenContestsLoading(true);
      setOpenContestsLoadError("");
      try {
        const response = await getAllContests(params);
        setOpenContests(response.data.OpenContests);
        setTotalOpenContests(response.data.metadata.openContestsTotalCount);
      } catch (error) {
        setOpenContestsLoadError("حدث خطأ ما أثناء تحميل المسابقات الحالية");
      }
      setOpenContestsLoading(false);
    };

    getOpenContests();
  }, [openCurrentPage, pageLimit]);

  useEffect(() => {
    const getClosedContests = async () => {
      const params = {
        PageNumber: closedCurrentPage,
        PageSize: pageLimit,
      };
      setClosedContestsLoading(true);
      setClosedContestsLoadError("");
      try {
        const response = await getAllContests(params);
        setClosedContests(response.data.ClosedContests);
        setTotalClosedContests(response.data.metadata.closedContestsTotalCount);
      } catch (error) {
        setClosedContestsLoadError("حدث خطأ ما أثناء تحميل المسابقات السابقة");
      }
      setClosedContestsLoading(false);
    };

    getClosedContests();
  }, [closedCurrentPage, pageLimit]);

  // smooth scroll
  useEffect(() => {
    if (!isFirstRender && openContestsSectionRef.current) {
      openContestsSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [openCurrentPage]);

  useEffect(() => {
    if (!isFirstRender && closedContestsSectionRef.current) {
      closedContestsSectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [closedCurrentPage]);

  let openContestsContent;
  if (openContestsLoading) {
    openContestsContent = <Spinner />;
  } else if (openContestsLoadError) {
    openContestsContent = (
      <div className="h-v-center text-center">
        <Error errorMessage={openContestsLoadError} />
      </div>
    );
  } else {
    openContestsContent = (
      <>
        <ContestsSection contests={openContests} open={true} />
        <Pagination
          total={totalOpenContests}
          pageSize={pageLimit}
          changePage={openCurrentPageChangedHandler}
          currentPage={openCurrentPage}
        />
      </>
    );
  }

  let closedContestsContent;
  if (closedContestsLoading) {
    closedContestsContent = <Spinner />;
  } else if (closedContestsLoadError) {
    closedContestsContent = (
      <div className="h-v-center text-center">
        <Error errorMessage={closedContestsLoadError} />
      </div>
    );
  } else {
    closedContestsContent = (
      <>
        <ContestsSection contests={closedContests} open={false} />
        <Pagination
          total={totalClosedContests}
          pageSize={pageLimit}
          changePage={closedCurrentPageChangedHandler}
          currentPage={closedCurrentPage}
        />
      </>
    );
  }

  return (
    <Layout shortHeader>
      <div className={classes.Contests}>
        <ContestsIntro />
        <section
          ref={openContestsSectionRef}
          className={classes.ContestsSection}
        >
          {openContestsContent}
        </section>
        <div className="separator" />
        <section
          ref={closedContestsSectionRef}
          className={classes.ContestsSection}
        >
          {closedContestsContent}
        </section>
      </div>
    </Layout>
  );
};

export default Contests;
