// css
import classes from "./Info.module.css";

const Info = (props) => {
  // props
  const { infoMessage } = props;

  return (
    <div className={classes.Info}>
      <p className={classes.Info__Message}>{infoMessage}</p>
    </div>
  );
};

export default Info;
