// react
import { useEffect, useState } from "react";
// react router dom
import { useParams } from "react-router-dom";
// components
import Layout from "../../Layout/Layout";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import { ToastContainer, toast } from "react-toastify";
import Error from "../../UI/Error/Error";
import Spinner from "../../UI/Spinner/Spinner";
import dateFormat from "dateformat";
// services
import { addNovel, getContestInfo } from "../../../services/contests";
// css
import classes from "./PublishNovel.module.css";
// classnames
import classNames from "classnames";
import Info from "../../UI/Info/Info";

const PublishNovel = () => {
  // state
  const [form, setForm] = useState({
    novelTitle: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
      },
    },
    novelFile: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isFile: true,
      },
    },
    novelCoverImage: {
      value: null,
      hasError: true,
      touched: false,
      errorMessage: "",
      rules: {
        isImage: true,
      },
    },
    novelIntro: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
        maxLength: 100,
      },
    },
  });
  const [contest, setContest] = useState(null);
  const [contestLoading, setContestLoading] = useState(true);
  const [contestLoadError, setContestLoadError] = useState("");
  const [publishLoading, setPublishLoading] = useState(false);
  // params
  const { contestId } = useParams();

  // handlers

  const checkValidity = (type, value, rules) => {
    const validity = { valid: true, message: "" };

    if (rules.isRequired && (type === "file" ? !value : value.trim() === "")) {
      validity.valid = false;
      validity.message = "هذا الحقل مطلوب";
    }

    if (rules.maxLength && value.split(" ").length > 100) {
      validity.valid = false;
      validity.message = "لقد تجاوزت الحد الاقصي المسموح للكتابة";
    }

    if (
      rules.isFile &&
      validity.valid &&
      !/(\.pdf|\.epub)$/i.exec(value.name)
    ) {
      validity.valid = false;
      validity.message = "من فضلك ارفع ملف في صيغة PDF/EPUB";
    }

    if (
      validity.valid &&
      rules.isImage &&
      !/(\.png|\.jpg|\.jpeg)$/i.exec(value.name)
    ) {
      validity.valid = false;
      validity.message = "من فضلك ارفع صورة في صيغة png/jpg/jpeg";
    }

    return validity;
  };

  const inputChangedHandler = (event) => {
    const { name, type } = event.target;
    let value;
    if (type === "file") {
      value = event.target.files[0];
    } else {
      value = event.target.value;
    }
    const { valid, message } = checkValidity(type, value, form[name].rules);
    setForm((prevForm) => ({
      ...prevForm,
      [name]: {
        ...prevForm[name],
        touched: true,
        hasError: !valid,
        errorMessage: message,
        value,
      },
    }));
  };

  const isCanSubmitted = () => {
    let can = true;
    for (let key in form) {
      if (form[key].hasError) {
        can = false;
      }
    }
    return can;
  };

  const resetState = () => {
    setForm({
      novelTitle: {
        value: "",
        touched: false,
        hasError: true,
        errorMessage: "",
        rules: {
          isRequired: true,
        },
      },
      novelFile: {
        value: "",
        touched: false,
        hasError: true,
        errorMessage: "",
        rules: {
          isFile: true,
        },
      },
      novelCoverImage: {
        value: null,
        hasError: true,
        touched: false,
        errorMessage: "",
        rules: {
          isImage: true,
        },
      },
      novelIntro: {
        value: "",
        touched: false,
        hasError: true,
        errorMessage: "",
        rules: {
          isRequired: true,
        },
      },
    });
  };

  const publishNovelHandler = async (event) => {
    event.preventDefault();
    // validate
    if (!isCanSubmitted()) {
      return;
    }
    // date
    const data = new FormData();
    data.set("NovelName", form.novelTitle.value);
    data.set("NovelFile", form.novelFile.value);
    data.set("NovelImage", form.novelCoverImage.value);
    data.set("Content", form.novelIntro.value);
    data.set("ContestId", Number(contestId));

    setPublishLoading(true);

    try {
      await addNovel(data);
      toast.success("تم نشر قصتك بنجاح", { position: "bottom-right" });
      resetState();
    } catch (error) {
      toast.error("حدث خطأ ما اثناء نشر القصة", {
        position: "bottom-right",
      });
    }

    setPublishLoading(false);
  };

  // effects
  useEffect(() => {
    const getContest = async () => {
      const params = {
        id: Number(contestId),
      };
      setContestLoading(true);
      setContestLoadError("");

      try {
        const response = await getContestInfo(params);
        setContest(response.data.Contest);
      } catch (error) {
        setContestLoadError("حدث خطأ ما أثناء تحميل تفاصيل هذة المسابقة");
      }

      setContestLoading(false);
    };
    if (!isNaN(Number(contestId))) {
      getContest();
    } else {
      setContestLoading(false);
      setContestLoadError("طلب غير صحيح !");
    }
  }, [contestId]);

  // view
  let content;
  if (contestLoading) {
    content = <Spinner />;
  } else if (contestLoadError) {
    content = (
      <div className="h-v-center text-center">
        <Error errorMessage={contestLoadError} />
      </div>
    );
  } else {
    content = (
      <>
        <header className={classes.Header}>
          <h2 className={classes.Header__Title}>{contest.Name}</h2>
          <p className={classes.Header__Text}>
            {contest.Closed
              ? `تم انتهاء هذه المسابقة في ${dateFormat(
                  contest.EndDate,
                  "dd/mm/yyyy"
                )}`
              : `اخر ميعاد لقبول القصص القصيرة المقامة حاليا ${dateFormat(
                  contest.EndDate,
                  "dd/mm/yyyy"
                )}`}
          </p>
        </header>
        <div className={classes.PublishNovel__Intro}>
          <h1 className={classes.PublishNovel__Intro__Header}>
            مستعد لرفع قصتك الان
          </h1>
          <p className={classes.PublishNovel__Intro__Stat}>
            <span>عدد المشتركين الي الان:</span>
            <span>{contest.ContestantsCount} مشترك</span>
          </p>
        </div>
        {contest.Closed ? (
          <div className="text-center">
            <Info infoMessage="عفواً تم انتهاء هذه المسابقة" />
          </div>
        ) : contest.IsJoined ? (
          <div className="text-center">
            <Info infoMessage="لقد اشتركت بالفعل في هذه المسابقة" />
          </div>
        ) : (
          <form
            className={classes.PublishNovel__Form}
            method="POST"
            onSubmit={publishNovelHandler}
          >
            {/* novel title */}
            <div
              className={classNames(
                "input-control",
                classes.PublishNovel__Form__Row
              )}
            >
              <div className={classes.PublishNovel__Form__Row__Title}>
                <label
                  htmlFor="novelTitle"
                  className={classNames(
                    "input-control__label",
                    "input-control__label--required"
                  )}
                >
                  اسم القصة
                </label>
                <input
                  id="novelTitle"
                  type="text"
                  name="novelTitle"
                  value={form.novelTitle.value}
                  onChange={inputChangedHandler}
                  className="input-control__input"
                />
                {form.novelTitle.touched && form.novelTitle.hasError && (
                  <p className="input-control__error">
                    {form.novelTitle.errorMessage}
                  </p>
                )}
              </div>
              <div>
                <label
                  htmlFor="novelFile"
                  className={classes.PublishNovel__Form__Upload}
                  role="button"
                >
                  حمل القصة
                </label>
                <input
                  id="novelFile"
                  name="novelFile"
                  type="file"
                  accept=".pdf, .epub"
                  onChange={inputChangedHandler}
                  hidden
                />
                {form.novelFile.touched && form.novelFile.hasError ? (
                  <p className="input-control__error">
                    {form.novelFile.errorMessage}
                  </p>
                ) : (
                  <p className={classes.PublishNovel__Form__Upload__Name}>
                    {form.novelFile.value.name}
                  </p>
                )}
              </div>
            </div>
            <div className="input-control">
              <label
                htmlFor="novelCoverImage"
                className={classNames(
                  "input-control__label",
                  "input-control__label--required"
                )}
              >
                صورة الغلاف
              </label>
              <input
                id="novelCoverImage"
                type="file"
                name="novelCoverImage"
                accept=".png, .jpg, .jpeg"
                onChange={inputChangedHandler}
                className="input-control__input"
              />
              {form.novelCoverImage.touched &&
                form.novelCoverImage.hasError && (
                  <p className="input-control__error">
                    {form.novelCoverImage.errorMessage}
                  </p>
                )}
            </div>
            <div className="input-control">
              <label
                htmlFor="novelIntro"
                className={classNames(
                  "input-control__label",
                  "input-control__label--required"
                )}
              >
                مقدمتك (لا تزيد عن 100 كلمة)
              </label>
              <textarea
                id="novelIntro"
                name="novelIntro"
                value={form.novelIntro.value}
                onChange={inputChangedHandler}
                className="input-control__input"
                rows={6}
              />
              {form.novelIntro.touched && form.novelIntro.hasError && (
                <p className="input-control__error">
                  {form.novelIntro.errorMessage}
                </p>
              )}
            </div>
            <div>
              <PrimaryButton
                type="submit"
                clicked={publishNovelHandler}
                disabled={publishLoading || !isCanSubmitted()}
                text="تم..قم بالاشتراك"
              />
              {publishLoading && (
                <div className="spinner-parent">
                  <Spinner />
                </div>
              )}
            </div>
          </form>
        )}
      </>
    );
  }
  return (
    <Layout shortHeader>
      <div className={classes.PublishNovel}>
        {content}
        <ToastContainer rtl autoClose={500} closeButton={false} />
      </div>
    </Layout>
  );
};

export default PublishNovel;
