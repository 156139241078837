// react
import { useEffect } from "react";
// react router
import { NavLink } from "react-router-dom";
// constants
import { navItems } from "../../../../../constants/navItems";
// css
import classes from "./MobileNavigationMenu.module.css";
// classnames
import classNames from "classnames";
const MobileNavigationMenu = (props) => {
  // props
  const { open } = props;

  // handlers
  const navItemClickedHandler = () => {
    document.querySelector("body").classList.remove("mobile-navigation-open");
  };

  // effects
  useEffect(() => {
    const bodyElement = document.querySelector("body");
    if (open) {
      bodyElement.classList.add("mobile-navigation-open");
    } else {
      bodyElement.classList.remove("mobile-navigation-open");
    }
  }, [open]);

  // view
  return (
    <div
      className={classNames(classes.MobileNavigationMenu, {
        [classes["MobileNavigationMenu--open"]]: open,
      })}
    >
      {open && (
        <nav className={classes.MobileNavigationMenu__Nav}>
          <ul className={classes.MobileNavigationMenu__NavList}>
            {navItems.map((navItem, index) => (
              <li
                key={index}
                className={classes.MobileNavigationMenu__NavList__Item}
              >
                <NavLink
                  to={navItem.href}
                  className={classes.MobileNavigationMenu__NavList__Item__Link}
                  onClick={navItemClickedHandler}
                >
                  {navItem.content}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default MobileNavigationMenu;
