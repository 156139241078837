// react
import { useEffect, useState } from "react";
// react-router
import { useParams } from "react-router-dom";
// components
import Layout from "../../Layout/Layout";
import ContestsIntro from "../ContestsIntro/ContestsIntro";
import Spinner from "../../UI/Spinner/Spinner";
import Error from "../../UI/Error/Error";
import ContestsBanner from "../ContestsBanner/ContestsBanner";
import dateFormat from "dateformat";
import NovelsFilters from "./NovelsFilters/NovelsFilters";
import Novels from "./Novels/Novels";
import { toast, ToastContainer } from "react-toastify";
// hooks
import { useIsFirstRender } from "../../../hooks/useIsFirstRender";
// services
import { getContest, addToLibrary, vote } from "../../../services/contests";
// css
import classes from "./Contest.module.css";

const Contest = () => {
  // state
  const [contest, setContest] = useState(null);
  const [novels, setNovels] = useState([]);
  const [showMyNovels, setShowMyNovels] = useState(false);
  const [showFavouriteNovels, setShowFavouriteNovels] = useState(false);
  const [showLibraryNovels, setShowLibraryNovels] = useState(false);
  const [contestDataLoading, setContestDataLoading] = useState(true);
  const [contestDataLoadError, setContestDataLoadError] = useState("");
  // params
  const { contestId } = useParams();
  // hooks
  const isFirstRender = useIsFirstRender();

  const showMyNovelsChangedHandler = (value) => {
    setShowMyNovels(value);
  };
  const showFavouriteNovelsChangedHandler = (value) => {
    setShowFavouriteNovels(value);
  };
  const showLibraryNovelsChangedHandler = (value) => {
    setShowLibraryNovels(value);
  };
  const novelLibraryToggleHandler = async (novelId, isCurrentlyInLibrary) => {
    const params = {
      NovelId: novelId,
    };
    let successMessage;
    let errorMessage;
    if (isCurrentlyInLibrary) {
      successMessage = "تم إزالة الرواية من المكتبة";
      errorMessage = "حدث خدأ ما أثناء إزالة الرواية من المكتبة";
    } else {
      successMessage = "تم إضافة الرواية الي المكتبة";
      errorMessage = "حدث خدأ ما أثناء إضافة الرواية الي المكتبة";
    }

    try {
      await addToLibrary(params);
      setNovels((prevNovels) =>
        prevNovels.reduce((accu, current) => {
          if (current.Id === novelId) {
            if (showLibraryNovels && current.IsAddedToLibrary) {
              return [...accu];
            } else {
              return [
                ...accu,
                { ...current, IsAddedToLibrary: !current.IsAddedToLibrary },
              ];
            }
          } else {
            return [...accu, current];
          }
        }, [])
      );
      toast.success(successMessage, {
        position: "bottom-right",
      });
    } catch (error) {
      toast.error(errorMessage, {
        position: "bottom-right",
      });
    }
  };

  const novelFavouriteToggleHandler = async (novelId, isCurrentlyVoted) => {
    const params = {
      NovelId: novelId,
    };
    let successMessage;
    let errorMessage;
    if (isCurrentlyVoted) {
      successMessage = "تم إلغاء تصويتك للرواية ";
      errorMessage = "حدث خدأ ما أثناء إلغاء تصويتك للرواية";
    } else {
      successMessage = "تم تسجيل تصويتك بنجاح";
      errorMessage = "حدث خدأ ما أثناء تسجيل تصويتك للرواية";
    }
    try {
      await vote(params);
      setNovels((prevNovels) =>
        prevNovels.reduce((accu, current) => {
          if (current.Id === novelId) {
            if (showFavouriteNovels && current.IsFavourite) {
              return [...accu];
            } else {
              return [
                ...accu,
                { ...current, IsFavourite: !current.IsFavourite },
              ];
            }
          } else {
            return [...accu, current];
          }
        }, [])
      );
      toast.success(successMessage, {
        position: "bottom-right",
      });
    } catch (error) {
      toast.error(errorMessage, {
        position: "bottom-right",
      });
    }
  };

  // effects
  useEffect(() => {
    const getData = async () => {
      // params
      const params = {
        id: Number(contestId),
      };
      if (showMyNovels) {
        params.MyNovels = true;
      }
      if (showFavouriteNovels) {
        params.isFavourite = true;
      }
      if (showLibraryNovels) {
        params.isInLibrary = true;
      }

      if (isFirstRender) {
        setContestDataLoading(true);
      }
      setContestDataLoadError("");

      try {
        const response = await getContest(params);
        setContest(response.data.Contest);
        setNovels(response.data.Novels);
      } catch (error) {
        setContestDataLoadError("حدث خطأ ما أثناء تحميل بيانات المسابقة");
      }

      setContestDataLoading(false);
    };
    if (!isNaN(Number(contestId))) {
      getData();
    } else {
      setContestDataLoading(false);
      setContestDataLoadError("حدث خطأ ما أثناء تحميل بيانات المسابقة");
    }
    // eslint-disable-next-line
  }, [contestId, showMyNovels, showFavouriteNovels, showLibraryNovels]);

  // view
  let content;
  if (contestDataLoading) {
    content = <Spinner />;
  } else if (contestDataLoadError) {
    content = (
      <div className="h-v-center text-center">
        <Error errorMessage={contestDataLoadError} />
      </div>
    );
  } else {
    content = (
      <>
        <ContestsIntro
          enableJoin={!contest.Closed}
          isJoined={contest.IsJoined}
          contestId={contestId}
        />
        <ContestsBanner
          title={contest.Name}
          subTitle={`اخر معاد للإشتراك في المسابقة ${dateFormat(
            contest.EndDate,
            "dd/mm/yyyy"
          )}`}
        />
        <NovelsFilters
          myNovelsChecked={showMyNovels}
          favouriteNovelsChecked={showFavouriteNovels}
          libraryNovelsChecked={showLibraryNovels}
          onMyNovelsChange={showMyNovelsChangedHandler}
          onFavouriteChange={showFavouriteNovelsChangedHandler}
          onLibraryChange={showLibraryNovelsChangedHandler}
        />
        <Novels
          novels={novels}
          onFavouriteClick={novelFavouriteToggleHandler}
          onLibraryClick={novelLibraryToggleHandler}
        />
      </>
    );
  }

  return (
    <Layout shortHeader>
      <div className={classes.Contest}>{content}</div>
      <ToastContainer rtl autoClose={500} closeButton={false} />
    </Layout>
  );
};

export default Contest;
