// ToDo Change end points
const authorSubDirectory = "/author";

export const getAuthorDetails = `${authorSubDirectory}/GetAuthorDetails`;

export const getAuthorDetailedData = `${authorSubDirectory}/GetAuthorDetailedData`;

export const getAuthorSummarizedData = `${authorSubDirectory}/GetAuthorSummarizedData`;

export const getWithdrawRequests = `${authorSubDirectory}/GetAuthorWithdrawRequests`;

export const getAuthorIncomeBreakdown = `/account/GetIncomeBreakDown`;
