// endpoints
import * as contestsEndPoints from "../constants/endpoints/contests";
// axios
import { axiosPrivateInstance } from "../axios/axios";

export const getAllContests = (params) => {
  return axiosPrivateInstance({
    url: contestsEndPoints.getAllContests,
    method: "GET",
    params,
  });
};

export const getContest = (params) => {
  return axiosPrivateInstance({
    url: contestsEndPoints.getContest,
    method: "GET",
    params,
  });
};

export const addNovel = (data) => {
  return axiosPrivateInstance({
    url: contestsEndPoints.addNovel,
    method: "POST",
    data,
  });
};

export const addToLibrary = (params) => {
  return axiosPrivateInstance({
    url: contestsEndPoints.addToLibrary,
    method: "POST",
    params,
  });
};

export const vote = (params) => {
  return axiosPrivateInstance({
    url: contestsEndPoints.vote,
    method: "POST",
    params,
  });
};

export const getContestInfo = (params) => {
  return axiosPrivateInstance({
    url: contestsEndPoints.getContestInfo,
    method: "GET",
    params,
  });
};
