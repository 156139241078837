// react
import { useState } from "react";
// components
import Overlay from "../../Overlay/Overlay";
import Modal from "../Modal";
import Spinner from "../../Spinner/Spinner";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CancelButton from "../../CancelButton/CancelButton";
// react toastify
import { toast } from "react-toastify";
// services
import { reportBook } from "../../../../services/books";
// css
import classes from "./ReportModal.module.css";
// classnames
import classNames from "classnames";

const ReportModal = (props) => {
  // props
  const { visible, close, bookId } = props;
  // state
  const [reason, setReason] = useState({
    value: "",
    hasError: true,
    touched: false,
    errorMessage: "",
  });
  const [reportLoading, setReportLoading] = useState(false);

  // handler
  const reasonChangedHandler = (event) => {
    const { value } = event.target;
    // validate
    let valid = true;
    if (value.trim() === "") {
      valid = false;
    }
    // update state
    setReason({
      touched: true,
      value,
      hasError: valid ? false : true,
      errorMessage: valid ? "" : "من فضلك ادخل السبب",
    });
  };

  const closeHandler = () => {
    setReason({
      value: "",
      hasError: true,
      touched: false,
      errorMessage: "",
    });
    setReportLoading(false);
    close();
  };

  const report = async (event) => {
    event.preventDefault();
    // validate
    if (reason.hasError) {
      return;
    }
    const params = {
      id: Number(bookId),
      reason: reason.value,
    };
    setReportLoading(true);
    try {
      await reportBook(params);
      toast.success("تم استلام التقرير بنجاح", { position: "bottom-right" });
      close();
    } catch {
      toast.error("حدث خطأ ما اثناء استلام التقرير", {
        position: "bottom-right",
      });
    }
    setReportLoading(false);
  };

  return (
    <>
      {visible && <Overlay clicked={closeHandler} />}
      <Modal visible={visible}>
        <form onSubmit={report} className={classes.ReportForm}>
          <div className="input-control">
            <label
              htmlFor="reason"
              className={classNames(
                "input-control__label",
                "input-control__label--required"
              )}
            >
              اكتب السبب
            </label>
            <textarea
              id="reason"
              name="reason"
              rows={6}
              value={reason.value}
              onChange={reasonChangedHandler}
              className="input-control__input"
            />
            {reason.touched && reason.hasError && (
              <p className="input-control__error">{reason.errorMessage}</p>
            )}
          </div>

          <div className={classes.ReportForm__Actions}>
            <div className={classes.ReportForm__Actions__Cancel}>
              <CancelButton
                type="button"
                clicked={closeHandler}
                text="غلق"
                rounded
              />
            </div>
            {reportLoading && (
              <div className="spinner-parent">
                <Spinner />
              </div>
            )}
            <div className={classes.ReportForm__Actions__Submit}>
              <PrimaryButton
                type="submit"
                text="حفظ"
                clicked={report}
                rounded
                disabled={reason.hasError || reportLoading}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ReportModal;
