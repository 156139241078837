// components
import Badge from "../Badge";
// css
import classes from "./PriceBadge.module.css";

const PriceBadge = (props) => {
  const { price, isFree } = props;

  return (
    <Badge>
      <span className={classes.BookPrice}>
        {isFree ? "مجاني" : `${price} ج `}
      </span>
    </Badge>
  );
};

export default PriceBadge;
