const postsSubDirectory = "/v1/Posts";

export const getPosts = `${postsSubDirectory}/GetPosts`;

export const addPost = `${postsSubDirectory}/AddPosts`;

export const getMostRecentPosts = `${postsSubDirectory}/GetMostRecentPosts`;

export const getMostReadPosts = `${postsSubDirectory}/GetMostReadPosts`;

export const getPost = `${postsSubDirectory}/GetPost`;

export const addComment = `${postsSubDirectory}/AddComment`;

export const likePost = `${postsSubDirectory}/LikePost`;

export const getPostComments = `${postsSubDirectory}/GetComments`;

export const getAuthorPosts = `${postsSubDirectory}/GetPosts`;

export const sharePost = `${postsSubDirectory}/SharePost`;
