// endpoints
import * as notificationEndPoints from "../constants/endpoints/notification";
// axios
import { axiosPrivateInstance } from "../axios/axios";

export const getNotifications = () => {
  return axiosPrivateInstance({
    url: notificationEndPoints.getNotifications,
    method: "GET",
  });
};
