// react
import { useState } from "react";
// components
import ProfileImageModal from "../../Modal/ProfileImageModal/ProfileImageModal";
// css
import classes from "./AuthorBadge.module.css";
// classnames
import classNames from "classnames";
// assets
import premiumIcon from "../../../../assets/icons/premium.svg";

const AuthorBadge = (props) => {
  // props
  const { imageSource, premium, smallPremiumIcon, showEdit, getDetails } =
    props;
  // state
  const [profileImageModalVisible, setProfileImageModalVisible] =
    useState(false);
  // handlers
  const openProfileImageModalHandler = () => {
    setProfileImageModalVisible(true);
  };
  const closeProfileImageModalHandler = () => {
    setProfileImageModalVisible(false);
  };

  // view
  return (
    <div className={classes.AuthorBadge}>
      <img
        src={imageSource}
        alt="badge"
        className={classes.AuthorBadge__AuthorImage}
      />
      {premium && (
        <img
          src={premiumIcon}
          alt="premium"
          className={classNames(classes.AuthorBadge__Premium, {
            [classes["AuthorBadge__Premium--small"]]: smallPremiumIcon,
          })}
        />
      )}
      {showEdit && (
        <>
          <button
            className={classes.AuthorBadge__Edit}
            onClick={openProfileImageModalHandler}
          >
            تعديل
          </button>
          <ProfileImageModal
            visible={profileImageModalVisible}
            close={closeProfileImageModalHandler}
            getDetails={getDetails}
          />
        </>
      )}
    </div>
  );
};

export default AuthorBadge;
