// assets
import whiteLogo from "../../../assets/images/whiteLogo.png";

// css
import classes from "./Logo.module.css";

const Logo = () => {
  return <img src={whiteLogo} alt="book-cloud-logo" className={classes.Logo} />;
};

export default Logo;
