// react
import { useEffect, useState, useRef, useCallback } from "react";
// custom hooks
import { useIsFirstRender } from "../../hooks/useIsFirstRender";
// react router
import { useSearchParams } from "react-router-dom";
// components
import Layout from "../Layout/Layout";
import PostsGrid from "./PostsGrid/PostsGrid";
import PostsControls from "./PostsControls/PostsControls";
// services
import { getPosts } from "../../services/posts";

const Posts = () => {
  // state
  const [posts, setPosts] = useState([]);
  const [postsLoading, setPostsLoading] = useState(true);
  const [postsError, setPostsError] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();
  // custom hooks
  const isFirstRender = useIsFirstRender();

  // data
  const pageSize = 12;

  // refs
  const mainContainerRef = useRef();

  // handlers
  const pageChangedHandler = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const sortOptionSelectedHandler = (sortKey) => {
    searchParams.set("sortBy", sortKey);
    setSearchParams(searchParams);
    setCurrentPage(1);
  };

  const myPostsChangedHandler = (checked) => {
    if (checked) {
      searchParams.set("myPosts", true);
    } else {
      searchParams.delete("myPosts");
    }
    setSearchParams(searchParams);
    setCurrentPage(1);
  };

  const getRefinedParams = useCallback(() => {
    const refinedParams = {};

    const sortedKey = Number(searchParams.get("sortBy"));
    const myPostsKey = searchParams.get("myPosts");

    if (searchParams.has("sortBy") && !isNaN(sortedKey)) {
      refinedParams.SortByType = sortedKey;
    }else{
      refinedParams.SortByType = 2;
    }
    if (searchParams.has("myPosts") && myPostsKey === "true") {
      refinedParams.MyPosts = myPostsKey;
    }

    return refinedParams;
  }, [searchParams]);

  // effect
  useEffect(() => {
    const fetchPosts = async () => {
      setPostsLoading(true);
      setPostsError("");

      const params = {
        PageNumber: currentPage,
        PageSize: pageSize,
        ...getRefinedParams(),
      };

      try {
        const response = await getPosts(params);
        setPosts(response.data.posts);
        setTotalCount(response.data.metadata.totalCount);
      } catch (error) {
        console.log(error);
        setPostsError("حدث خطأ ما أثناء تحميل التدوينات");
      }

      setPostsLoading(false);
    };

    fetchPosts();
  }, [getRefinedParams, currentPage]);

  useEffect(() => {
    if (!isFirstRender) {
      mainContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }
    // eslint-disable-next-line
  }, [searchParams, currentPage]);

  return (
    <Layout shortHeader>
      <div ref={mainContainerRef}>
        <PostsControls
          onSortOptionClick={sortOptionSelectedHandler}
          onMyPostsChange={myPostsChangedHandler}
          initialMyPostsCheck={searchParams.get("myPosts")}
          initialSortOption={Number(searchParams.get("sortBy"))}
        />
        <PostsGrid
          changePage={pageChangedHandler}
          currentPage={currentPage}
          totalCount={totalCount}
          pageSize={pageSize}
          postsLoading={postsLoading}
          postsError={postsError}
          posts={posts}
        />
      </div>
    </Layout>
  );
};

export default Posts;
