// components
import AuthorBadge from "../../../UI/Badge/AuthorBadge/AuthotBadge";
// react router
import { Link } from "react-router-dom";
// css
import classes from "./Post.module.css";

const Post = (props) => {
  // props
  const { post } = props;

  // view
  return (
    <article className={classes.Post}>
      <Link
        to={`/posts/${post.postId}`}
        className={classes.Post__Title}
        title={post.PostTitle}
      >
        {post.PostTitle}
      </Link>
      <div className={classes.Post__Author}>
        <div className={classes.Post__Author__Badge}>
          <AuthorBadge
            imageSource={post.postedByUserImage}
            premium={post.postedByIsVerified}
            smallPremiumIcon
          />
        </div>

        <Link
          to={`/authors/${post.postedByUid}`}
          className={classes.Post__Author__Name}
        >
          {post.postedByUserName}
        </Link>
      </div>
      <p className={classes.Post__Content}>{post.postContent}</p>
      <p className={classes.Post__Footer}>
        <time className={classes.Post__Footer__Date}>{post.postedDate}</time>
        <Link
          to={`/posts/${post.postId}`}
          className={classes.Post__Footer__More}
        >
          المزيد
        </Link>
      </p>
    </article>
  );
};
export default Post;
