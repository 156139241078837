// css
import classes from "./Star.module.css";
// assets
import filledStar from "../../../assets/icons/filledStar.svg";
import outlinedStar from "../../../assets/icons/outlinedStar.svg";

const Star = (props) => {
  const { filled } = props;
  return (
    <img
      className={classes.Star}
      src={filled ? filledStar : outlinedStar}
      alt={filled ? "filled star" : "outlined star"}
    />
  );
};

export default Star;
