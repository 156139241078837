// components
import AuthorBadge from "../../UI/Badge/AuthorBadge/AuthotBadge";
import AuthorDetailsTables from "./AuthorDetailsTables/AuthorDetailsTables";
// css
import classes from "./AuthorDetails.module.css";

const AuthorDetails = (props) => {
  // props
  const { user, getDetails } = props;

  return (
    <section className={classes.AuthorDetails}>
      <div className={classes.AuthorDetails__Badge}>
        <AuthorBadge
          imageSource={user.userProfilePhoto}
          premium={user.isVerified}
          showEdit
          getDetails={getDetails}
        />
      </div>
      <AuthorDetailsTables user={user} getDetails={getDetails} />
    </section>
  );
};

export default AuthorDetails;
