// constants
import { ACCESS_TOKEN } from "../constants/auth";

export const getStorageItem = (name) => {
  return (
    window.sessionStorage.getItem(name) || window.localStorage.getItem(name)
  );
};

export const persistItem = (name, token) => {
  window.localStorage.setItem(name, token);
};

export const clearStorageItem = (name) => {
  window.localStorage.removeItem(name);
};

export const persistAuth = (accessToken) => {
  window.localStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const persisSessionAuth = (accessToken) => {
  window.sessionStorage.setItem(ACCESS_TOKEN, accessToken);
};

export const clearAuth = () => {
  window.localStorage.removeItem(ACCESS_TOKEN);
  window.sessionStorage.removeItem(ACCESS_TOKEN);
};

export const parseJWT = (token) => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
};

export const getInitialAuthentication = () => {
  const accessToken =
    window.sessionStorage.getItem(ACCESS_TOKEN) ||
    window.localStorage.getItem(ACCESS_TOKEN);

  if (accessToken) {
    return { isAuthenticated: true, user: parseJWT(accessToken) };
  }
  return { isAuthenticated: false, user: null };
};
