// css
import classes from "./ContestsBanner.module.css";

const ContestsBanner = (props) => {
  // props
  const { title, subTitle } = props;

  return (
    <div className={classes.ContestsBanner}>
      <h1 className={classes.ContestsBanner__Title}>{title}</h1>
      {subTitle && (
        <p className={classes.ContestsBanner__SubTitle}>{subTitle}</p>
      )}
    </div>
  );
};

export default ContestsBanner;
