// react
import { useState } from "react";
// react router dom
import { useNavigate } from "react-router-dom";
// components
import PrimaryButton from "../../../../UI/PrimaryButton/PrimaryButton";
import Spinner from "../../../../UI/Spinner/Spinner";
import Error from "../../../../UI/Error/Error";
// services
import { changePassword } from "../../../../../services/account";
// css
import classes from "./PasswordResetForm.module.css";
import classNames from "classnames";

const PasswordResetForm = (props) => {
  // porps
  const { verificationCode } = props;
  // state
  const [form, setForm] = useState({
    newPassword: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
        minLength: 6,
      },
    },
    newPasswordConfirm: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {},
    },
  });
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
  const [ResetFormError, setResetFormError] = useState("");
  // react router
  const navigate = useNavigate();

  // handlers
  const checkValidity = (value, rules) => {
    const validity = { valid: true, message: "" };

    if (rules.isRequired && value.trim() === "") {
      validity.valid = false;
      validity.message = "هذا الحقل مطلوب";
    }

    if (validity.valid && rules.minLength && value.length < rules.minLength) {
      validity.valid = false;
      validity.message = "كلمة المرور يجب الا تقل عن 6 احرف";
    }

    return validity;
  };

  const inputChangedHandler = (event) => {
    const { name, value } = event.target;
    const { valid, message } = checkValidity(value, form[name].rules);
    setForm((prevForm) => ({
      ...prevForm,
      [name]: {
        ...prevForm[name],
        touched: true,
        hasError: !valid,
        errorMessage: message,
        value,
      },
    }));
  };

  const isFormCanBeSubmitted = () => {
    let can = true;
    for (let key in form) {
      if (form[key].hasError) {
        can = false;
      }
    }
    return can;
  };

  const updatePasswordHandler = async (event) => {
    event.preventDefault();
    const data = {
      verificationCode,
      newPassword: form.newPassword.value,
    };
    setUpdatePasswordLoading(true);
    try {
      await changePassword(data);
      navigate("/account", {
        state: { successMessage: "تم تحديث كلمة المرور بنجاح" },
        replace: true,
      });
    } catch (error) {
      setResetFormError("حدث خطأ ما برجاء المحاولة مرة أخري");
    }
    setUpdatePasswordLoading(false);
  };

  return (
    <>
      {ResetFormError && (
        <div className={classes.ResetFormError}>
          <Error errorMessage={ResetFormError} />
        </div>
      )}

      <form method="POST" onSubmit={updatePasswordHandler}>
        <div className="input-control">
          <label
            htmlFor="newPassword"
            className={classNames(
              "input-control__label",
              "input-control__label--required"
            )}
          >
            ادخل الرقم السري الجديد
          </label>
          <input
            autoFocus
            id="newPassword"
            type="password"
            name="newPassword"
            value={form.newPassword.value}
            onChange={inputChangedHandler}
            className="input-control__input"
          />
          {form.newPassword.touched && form.newPassword.hasError && (
            <p className="input-control__error">
              {form.newPassword.errorMessage}
            </p>
          )}
        </div>
        <div className="input-control">
          <label
            htmlFor="newPasswordConfirm"
            className={classNames(
              "input-control__label",
              "input-control__label--required"
            )}
          >
            تأكيد الرقم السري الجديد
          </label>
          <input
            id="newPasswordConfirm"
            type="password"
            name="newPasswordConfirm"
            value={form.newPasswordConfirm.value}
            onChange={inputChangedHandler}
            className="input-control__input"
          />
          {form.newPassword.value !== form.newPasswordConfirm.value &&
            form.newPasswordConfirm.value.trim() !== "" && (
              <p className="input-control__error">كلمة المرور غير متطابقة</p>
            )}
        </div>
        <div>
          <PrimaryButton
            type="submit"
            clicked={updatePasswordHandler}
            disabled={updatePasswordLoading || !isFormCanBeSubmitted()}
            text="تغيير كلمة المرور"
          />
        </div>
        {updatePasswordLoading && (
          <div className="spinner-parent ">
            <Spinner />
          </div>
        )}
      </form>
    </>
  );
};

export default PasswordResetForm;
