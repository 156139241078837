// react
import { useEffect } from "react";
// react router
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// redux
import { useSelector, useDispatch } from "react-redux";
import { addItems } from "./store/cartSlice";
import { addFavouriteBooks } from "./store/favouriteBooksSlice";
// components
import Account from "./components/Account/Account";
import LoginForm from "./components/Account/LoginForm/LoginForm";
import RegisterForm from "./components/Account/RegisterForm/RegisterForm";
import PasswordReset from "./components/Account/LoginForm/PasswordReset/PasswordReset";
// services
import { getCartItems } from "./services/cart";
import { getFavouriteBooks } from "./services/books";
import { getUserInfo } from "./services/account";
// constants
import { routes } from "./constants/routes";
import { addUserInfo } from "./store/authSlice";

function App() {
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const dispatch = useDispatch();
  // effects
  useEffect(() => {
    const getCart = async () => {
      const response = await getCartItems();
      dispatch(addItems(response.data.books));
    };
    const getFavourite = async () => {
      const response = await getFavouriteBooks();
      dispatch(addFavouriteBooks(response.data.books));
    };
    const getInfo = async () => {
      const response = await getUserInfo();
      dispatch(addUserInfo(response.data));
    };
    if (isAuthenticated) {
      getCart();
      getFavourite();
      getInfo();
    }
  }, [dispatch, isAuthenticated]);

  // get element to render
  const getElement = (route) => {
    if (route.private) {
      if (isAuthenticated) {
        return <route.component />;
      } else {
        return <Navigate to="/account" replace={true} state={location} />;
      }
    } else {
      return <route.component />;
    }
  };
  // scroll to top for every route change
  const location = useLocation();
  const { pathname } = location;
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [pathname]);
  return (
    <Routes>
      {routes.map((route, index) => (
        <Route key={index} path={route.path} element={getElement(route)} />
      ))}
      {!isAuthenticated && (
        <Route path="/account" element={<Account />}>
          <Route index element={<LoginForm />} />
          <Route path="login" element={<LoginForm />} />
          <Route path="register" element={<RegisterForm />} />
          <Route path="password-reset" element={<PasswordReset />} />
          <Route path="*" element={<Navigate to="login" />} />
        </Route>
      )}
      <Route
        path="*"
        element={
          location.state ? (
            <Navigate
              to={`${location.state.pathname}${location.state.search}`}
              replace={true}
            />
          ) : (
            <Navigate to="/" replace={true} />
          )
        }
      />
    </Routes>
  );
}

export default App;
