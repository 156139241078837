// react
import { useState } from "react";
// redux
import { useSelector } from "react-redux";
// components
import SortDropdown from "../../SortDropdown/SortDropdown";
import AddResourceLink from "../../AddResourceLink/AddResourceLink";
// constants
import { postsSortOptions } from "../../../constants/sortOptions";
// css
import classes from "./PostsControls.module.css";

const PostsControls = (props) => {
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // props
  const {
    onSortOptionClick,
    onMyPostsChange,
    initialSortOption,
    initialMyPostsCheck,
  } = props;
  // state
  const [myPostsChecked, setMyPostsChecked] = useState(
    initialMyPostsCheck?.toLowerCase() === "true"
  );
  // data
  const sortInitialValue = postsSortOptions.find(
    (option) => option.key === initialSortOption
  )?.label;

  // handlers
  const myPostsChangedHandler = (e) => {
    setMyPostsChecked(e.target.checked);
    onMyPostsChange(e.target.checked);
  };
  return (
    <div className={classes.PostsControls}>
      <div className={classes.PostsControls__Actions}>
        <SortDropdown
          sortOptions={postsSortOptions}
          onSortOptionClick={onSortOptionClick}
          initialValue={sortInitialValue}
        />
        {isAuthenticated && (
          <div className="checkbox__control">
            <input
              id="myPosts"
              type="checkbox"
              checked={myPostsChecked}
              onChange={myPostsChangedHandler}
              className="checkbox"
            />
            <label htmlFor="myPosts" className="checkbox__label">
              تدويناتي فقط
            </label>
          </div>
        )}
      </div>
      <AddResourceLink path="/posts/add" resourceName="أضف تدوينة" />
    </div>
  );
};

export default PostsControls;
