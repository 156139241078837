// assets
import resume from "../../../assets/images/resume.png";
// css
import classes from "./AddResourceHeader.module.css";

const AddResourceHeader = (props) => {
  // props
  const { title } = props;

  return (
    <div className={classes.AddResourceHeader}>
      <img
        className={classes.AddResourceHeader__Img}
        src={resume}
        alt="add book"
      />
      <h2 className={classes.AddResourceHeader__Title}>{title}</h2>
    </div>
  );
};

export default AddResourceHeader;
