// assets
import appStore from "../../../../../../assets/icons/appStore.svg";
import playStore from "../../../../../../assets/icons/playStore.svg";
// css
import classes from "./BookCloudApps.module.css";
// classnames
import classNames from "classnames";

const BookCloudApps = () => {
  return (
    <div className={classes.BookCloudApps}>
      <a
        href="https://apps.apple.com/us/app/bookcloud/id6449370708"
        target="_blank"
        rel="noreferrer"
        className={classNames(
          classes.BookCloudApps__Store,
          classes.BookCloudApps__AppStore
        )}
      >
        <img src={appStore} alt="app store" />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.bookcloudme"
        target="_blank"
        rel="noreferrer"
        className={classNames(
          classes.BookCloudApps__Store,
          classes.BookCloudApps__PlayStore
        )}
      >
        <img src={playStore} alt="play store" />
      </a>
    </div>
  );
};

export default BookCloudApps;
