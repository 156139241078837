// react router
import { Link, useLocation } from "react-router-dom";
// css
import classes from "./AddResourceLink.module.css";
// classnames
import classnames from "classnames";
// assets
import plus from "../../assets/icons/plus.svg";

const AddResourceLink = (props) => {
  // props
  const { path, resourceName, classNames } = props;
  // react router
  const location = useLocation();
  const locationToPreserve = { ...location, pathname: path };

  return (
    <Link
      to={path}
      className={classnames(classes.Link, classNames)}
      state={locationToPreserve}
      replace={false}
    >
      <img src={plus} alt="plus" className={classes.Link__Plus} />
      <span className={classes.Link__Text}>{resourceName}</span>
    </Link>
  );
};

export default AddResourceLink;
