// react
import { useState } from "react";
// redux
import { useSelector } from "react-redux";
// react router
import { useLocation, useNavigate } from "react-router-dom";
// components
import Star from "../../../UI/Star/Star";
// react toastify
import { ToastContainer } from "react-toastify";
// css
import classes from "./BookReview.module.css";
import ReviewModal from "../../../UI/Modal/ReviewModal/ReviewModal";

const BookReview = (props) => {
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // react router
  const location = useLocation();
  const navigate = useNavigate();
  // props
  const { bookId } = props;
  // state
  const [rating, setRating] = useState(0);
  const [hover, setHover] = useState(0);

  const [reviewModalVisible, setReviewModalVisible] = useState(false);

  const starClickedHandler = (rating) => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: false });
    } else {
      setRating(rating);
      setReviewModalVisible(true);
    }
  };

  const reviewModalClosedHandler = () => {
    setReviewModalVisible(false);
  };

  return (
    <ul className={classes.BookReviewList}>
      {[...Array(5)].map((_, index) => (
        <li
          key={index}
          onClick={() => starClickedHandler(index + 1)}
          onMouseEnter={() => setHover(index + 1)}
          onMouseLeave={() => setHover(rating)}
          className={classes.BookReviewList__Item}
          role="button"
        >
          <Star filled={index + 1 <= (hover || rating) ? true : false} />
        </li>
      ))}
      <ReviewModal
        visible={reviewModalVisible}
        starCount={rating}
        bookId={bookId}
        close={reviewModalClosedHandler}
      />
      <ToastContainer rtl autoClose={500} closeButton={false} />
    </ul>
  );
};

export default BookReview;
