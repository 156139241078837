import {
  getMostRecentPosts,
  getHorizontalPosts,
  getMostReadPosts,
} from "../services/posts";

export const postsClassifications = [
  {
    label: "الاحدث تدوينا",
    handler: getMostRecentPosts,
  },
  {
    label: "الاكثر قراءة ",
    handler: getMostReadPosts,
  },
  {
    label: "كل التدوينات",
    handler: getHorizontalPosts,
  },
];
