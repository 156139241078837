// endpoints
import * as accountEndPoints from "../constants/endpoints/account";
// axios
import { axiosMainInstance, axiosPrivateInstance } from "../axios/axios";

export const loginUser = (data) => {
  return axiosMainInstance({
    url: accountEndPoints.loginUser,
    method: "POST",
    data,
  });
};

export const registerUser = (data) => {
  return axiosMainInstance({
    url: accountEndPoints.registerUser,
    method: "POST",
    data,
  });
};

export const getUserInfo = () => {
  return axiosPrivateInstance({
    url: accountEndPoints.getUserInfo,
    method: "GET",
  });
};

export const getUserDetails = () => {
  return axiosPrivateInstance({
    url: accountEndPoints.getUserDetails,
    method: "GET",
  });
};

export const updateProfilePhoto = (data) => {
  return axiosPrivateInstance({
    url: accountEndPoints.updateProfilePhoto,
    method: "POST",
    data,
  });
};

export const updateProfile = (data) => {
  return axiosPrivateInstance({
    url: accountEndPoints.updateProfile,
    method: "POST",
    data,
  });
};

export const sendVerification = (data) => {
  return axiosMainInstance({
    url: accountEndPoints.sendVerification,
    method: "POST",
    data,
  });
};

export const validateVerificationCode = (data) => {
  return axiosMainInstance({
    url: accountEndPoints.validateVerificationCode,
    method: "POST",
    data,
  });
};

export const changePassword = (data) => {
  return axiosMainInstance({
    url: accountEndPoints.changePassword,
    method: "POST",
    data,
  });
};

export const googleLogin = (data) => {
  return axiosMainInstance({
    url: accountEndPoints.googleLogin,
    method: "POST",
    data,
  });
};

export const updateExternalUserInfo = (accessToken, data) => {
  return axiosMainInstance({
    url: accountEndPoints.updateExternalUserInfo,
    method: "POST",
    data,
    headers: { Authorization: `Bearer ${accessToken}` },
  });
};

export const authenticateFacebookExternalUser = (data) => {
  return axiosMainInstance({
    url: accountEndPoints.authenticateFacebookExternalUser,
    method: "POST",
    data,
  });
};
