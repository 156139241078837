// redux
import { useSelector } from "react-redux";
// react router
import { NavLink } from "react-router-dom";
// constants
import { navItems } from "../../../../../constants/navItems";
// css
import classes from "./NavigationMenu.module.css";
// classnames
import classNames from "classnames";

const NavigationMenu = () => {
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  if (isAuthenticated && navItems.length < 6) {
    navItems.splice(2, 0, { content: "كتبي", href: "/myBooks" });
    navItems.splice(4, 0, { content: "المسابقات", href: "/contests" });
  }
  return (
    <nav className={classes.NavMenu}>
      <ul className={classes.NavMenu__List}>
        {navItems.map((navItem, index) => (
          <li key={index} className={classes.NavMenu__List__Item}>
            <NavLink
              to={navItem.href}
              className={({ isActive }) =>
                classNames(classes.NavMenu__List__Item__Link, {
                  [classes["NavMenu__List__Item__Link--active"]]: isActive,
                })
              }
            >
              {navItem.content}
            </NavLink>
          </li>
        ))}
      </ul>
    </nav>
  );
};

export default NavigationMenu;
