// css
import classes from "./NotificationBadge.module.css";

const NotificationBadge = (props) => {
  // props
  const { number } = props;

  return (
    <span className={classes.NotificationBadge}>
      {number > 99 ? "+99" : number}
    </span>
  );
};

export default NotificationBadge;
