// react
import { useEffect, useState } from "react";
// constants
import { postsClassifications } from "../../../constants/posts";
// css
import classes from "./HorizontalPosts.module.css";
// classnames
import classNames from "classnames";
// components
import Spinner from "../../UI/Spinner/Spinner";
import Post from "./Post/Post";
import Slick from "../../UI/Slick/Slick";
import Error from "../../../components/UI/Error/Error";
import Info from "../../../components/UI/Info/Info";

const carouselSettings = {
  pagination: false,
  itemsToShow: 3,
  itemsToScroll: 1,
  isRTL: true,
};

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 260, itemsToShow: 2 },
  { width: 600, itemsToShow: 3 },
];

const HorizontalPosts = () => {
  const [selectedClassification, setSelectedClassification] = useState(2);
  const [posts, setPosts] = useState([]);
  const [postsLoading, setPostsLoading] = useState(true);
  const [postsError, setPostsError] = useState("");

  useEffect(() => {
    const getPosts = async (index) => {
      setPostsLoading(true);
      setPostsError("");

      try {
        const response = await postsClassifications[index].handler();
        setPosts(response.data.posts);
        setPostsError("");
      } catch (error) {
        console.log(error);
        setPostsError("حدث خطأ ما أثناء تحميل التدوينات");
      }

      setPostsLoading(false);
    };

    getPosts(selectedClassification);
  }, [selectedClassification]);

  const classificationClickedHandler = async (index) => {
    setSelectedClassification(index);
  };

  let content;
  if (postsLoading) {
    content = <Spinner />;
  } else if (postsError) {
    content = (
      <div className={classes.HorizontalPosts__Feedback}>
        <Error errorMessage={postsError} />
      </div>
    );
  } else if (posts.length !== 0) {
    content = (
      <section className={classes.Posts}>
        <Slick settings={carouselSettings} breakPoints={breakPoints}>
          {posts.map((post) => (
            <Post key={post.postId} post={post} />
          ))}
        </Slick>
      </section>
    );
  } else {
    content = (
      <div className={classes.HorizontalPosts__Feedback}>
        <Info infoMessage="عفواً لا يوجد تدوينات في الوقت الحالي" />
      </div>
    );
  }

  return (
    <section className={classes.HorizontalPosts}>
      <header className={classes.Header}>
        <h2 className={classes.Header__Title}>التدوينات</h2>
        <ul className={classes.TabsList}>
          {postsClassifications.map((classification, index) => (
            <li
              key={index}
              className={classNames(classes.TabsList__ListItem, {
                [classes["TabsList__ListItem--selected"]]:
                  index === selectedClassification,
              })}
              role="button"
              onClick={() => classificationClickedHandler(index)}
            >
              {classification.label}
            </li>
          ))}
        </ul>
      </header>
      {content}
    </section>
  );
};

export default HorizontalPosts;
