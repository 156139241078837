import { configureStore } from "@reduxjs/toolkit";
// reducers
import authReducer from "./authSlice";
import cartReducer from "./cartSlice";
import favouriteBooksReducer from "./favouriteBooksSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    cart: cartReducer,
    favouriteBooks: favouriteBooksReducer,
  },
  devTools: process.env.NODE_ENV === "development",
});
