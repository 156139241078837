// css
import classes from "./Error.module.css";

const Error = (props) => {
  // props
  const { errorMessage } = props;

  return (
    <div className={classes.Error}>
      <p className={classes.Error__Message}>{errorMessage}</p>
    </div>
  );
};

export default Error;
