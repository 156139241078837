// components
import { Link } from "react-router-dom";
// css
import classes from "./ConvertInstructions.module.css";
// assets
import stepOneDemoOne from "../../../../../assets/screenShots/stepOneDemoOne.png";
import stepOneDemoTwo from "../../../../../assets/screenShots/stepOneDemoTwo.png";
import stepOneDemoThree from "../../../../../assets/screenShots/stepOneDemoThree.png";
import stepTwoDemoOne from "../../../../../assets/screenShots/stepTwoDemoOne.png";
import stepThreeDemoOne from "../../../../../assets/screenShots/stepThreeDemoOne.png";
import stepFourDemoOne from "../../../../../assets/screenShots/stepFourDemoOne.png";

const ConvertInstructions = () => {
  return (
    <div className={classes.ConvertInstructions}>
      <h3>كيفية تحويل ملف word إلى epub ؟</h3>
      <ol className={classes.Instrcutions}>
        <li className={classes.Instruction}>
          <span className={classes.Instruction__Text}>
            الخطوة الاولى : اجعل عناوين الفصول متناسقة لضمان تكوين الفهرس بطريقة
            صحيحة أثناء عملية التحويل
          </span>
          <ul className={classes.Instruction__Images}>
            <li
              className={classes.Instruction__Images__Item}
              title="اضغط علي الصورة لعرضها"
            >
              <Link
                className={classes.Instruction__Images__Item__Link}
                to={stepOneDemoOne}
                target="_blank"
              >
                <img
                  className={classes.Instruction__Images__Item__Image}
                  src={stepOneDemoOne}
                  alt="step-screen-shot"
                />
              </Link>
            </li>
            <li
              className={classes.Instruction__Images__Item}
              title="اضغط علي الصورة لعرضها"
            >
              <Link
                className={classes.Instruction__Images__Item__Link}
                to={stepOneDemoTwo}
                target="_blank"
              >
                <img
                  className={classes.Instruction__Images__Item__Image}
                  src={stepOneDemoTwo}
                  alt="step-screen-shot"
                />
              </Link>
            </li>
            <li
              className={classes.Instruction__Images__Item}
              title="اضغط علي الصورة لعرضها"
            >
              <Link
                className={classes.Instruction__Images__Item__Link}
                to={stepOneDemoThree}
                target="_blank"
              >
                <img
                  className={classes.Instruction__Images__Item__Image}
                  src={stepOneDemoThree}
                  alt="step-screen-shot"
                />
              </Link>
            </li>
          </ul>
        </li>
        <li className={classes.Instruction}>
          <span className={classes.Instruction__Text}>
            الخطوة الثانية : قم برفع ملف ال.doc أو .docx من خلال أداة التحويل
            على المنصة
          </span>
          <ul className={classes.Instruction__Images}>
            <li
              className={classes.Instruction__Images__Item}
              title="اضغط علي الصورة لعرضها"
            >
              <Link
                className={classes.Instruction__Images__Item__Link}
                to={stepTwoDemoOne}
                target="_blank"
              >
                <img
                  className={classes.Instruction__Images__Item__Image}
                  src={stepTwoDemoOne}
                  alt="step-screen-shot"
                />
              </Link>
            </li>
          </ul>
        </li>
        <li className={classes.Instruction}>
          <span className={classes.Instruction__Text}>
            الخطوة الثالثة : قم بتحميل ملف ال.epub بعد عملية التحويل
          </span>
          <ul className={classes.Instruction__Images}>
            <li
              className={classes.Instruction__Images__Item}
              title="اضغط علي الصورة لعرضها"
            >
              <Link
                className={classes.Instruction__Images__Item__Link}
                to={stepThreeDemoOne}
                target="_blank"
              >
                <img
                  className={classes.Instruction__Images__Item__Image}
                  src={stepThreeDemoOne}
                  alt="step-screen-shot"
                />
              </Link>
            </li>
          </ul>
        </li>
        <li className={classes.Instruction}>
          <span className={classes.Instruction__Text}>
            الخطوة الرابعة : قم برفع ملف ال.epub أثناء عملية اضافة كتاب جديد
          </span>
          <ul className={classes.Instruction__Images}>
            <li
              className={classes.Instruction__Images__Item}
              title="اضغط علي الصورة لعرضها"
            >
              <Link
                className={classes.Instruction__Images__Item__Link}
                to={stepFourDemoOne}
                target="_blank"
              >
                <img
                  className={classes.Instruction__Images__Item__Image}
                  src={stepFourDemoOne}
                  alt="step-screen-shot"
                />
              </Link>
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};

export default ConvertInstructions;
