// react
import { useRef } from "react";
// reacyt router
import { Link } from "react-router-dom";
// components
import AuthorBadge from "../../../UI/Badge/AuthorBadge/AuthotBadge";
// dateformat
import dateformat from "dateformat";
// css
import classes from "./PostComment.module.css";

const PostComment = (props) => {
  // props
  const { comment } = props;
  // state
  // const [reply, setReply] = useState("");

  // refs
  const repliesRef = useRef();
  const repliesContainerRef = useRef();

  // handlers
  const showRepliesHandler = () => {
    // if (comment.commentReplies?.length !== 0) {
    //   const sectionHeight = repliesRef.current.offsetHeight;
    //   repliesContainerRef.current.setAttribute(
    //     "style",
    //     "height: " + sectionHeight + "px"
    //   );
    // }
  };
  const hideRepliesHandler = () => {
    repliesContainerRef.current.style.height = 0;
  };
  // const replyChangedHandler = (event) => {
  //   const { value } = event.target;
  //   setReply(value);
  // };
  // const publishReplyHandler = async () => {
  //   if (reply.trim() === "") {
  //     return;
  //   }
  // };

  // view
  return (
    <div className={classes.Comment}>
      <div className={classes.Comment__Writer}>
        <div className={classes.Comment__Writer__Badge}>
          <AuthorBadge
            imageSource={comment.commentedByProfilePhoto}
            premium={comment.commentedByIsVerified}
            smallPremiumIcon={true}
          />
        </div>
        <Link
          to={`/authors/${comment.userID}`}
          className={classes.Comment__Writer__Name}
          target="_blank"
        >
          {comment.commentedByUserName}
        </Link>
      </div>
      <p className={classes.Comment__Text}>{comment.commentContent}</p>
      <div className={classes.Comment__Footer}>
        <span
          role="button"
          className={classes.Comment__Footer__Replies}
          onClick={showRepliesHandler}
        >
          {/* <span className={classes.Comment__Footer__Replies__Stat}>
            {comment.commentReplies?.length || 0}
          </span> */}
          {/* <span className={classes.Comment__Footer__Replies__Text}>رد</span> */}
        </span>
        <div className={classes.Comment__Footer__Date}>
          {dateformat(comment.commentTime)}
        </div>
      </div>
      <div className={classes.CommentReplies} ref={repliesContainerRef}>
        <div ref={repliesRef}>
          <ul className={classes.CommentReplies__List}>
            {comment.commentReplies.map((reply, index) => (
              <li key={index} className={classes.CommentReplies__List__Item}>
                <div className={classes.Reply__Writer}>
                  <div className={classes.Reply__Writer__Badge}>
                    <AuthorBadge
                      imageSource={reply.replyUserProfilePhoto}
                      premium={reply.replyUserIsVerified}
                      smallPremiumIcon={true}
                    />
                  </div>
                  <Link
                    to={`/authors/${reply.replyUId}`}
                    className={classes.Reply__Writer__Name}
                    target="_blank"
                  >
                    {reply.replyUserName}
                  </Link>
                </div>
                <p className={classes.Reply__Text}>{reply.replyContent}</p>
                <div className={classes.Reply__Date}>
                  {dateformat(reply.replyDate)}
                </div>
              </li>
            ))}
          </ul>
          <div className={classes.CommentReplies__Control}>
            {/* <input
            type="text"
            value={reply}
            onChange={replyChangedHandler}
            placeholder="اكتب ردا هنا ..."
          />
          <button
            className={classes.CommentReplies__Control__Publish}
            onClick={publishReplyHandler}
          >
            نشر
          </button> */}
            <button
              className={classes.CommentReplies__Control__Hide}
              onClick={hideRepliesHandler}
            >
              إخفاء الردود
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PostComment;
