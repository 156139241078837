// react
import { useState, useEffect } from "react";
// redux
import { useSelector } from "react-redux";
// components
import Layout from "../Layout/Layout";
import Error from "../UI/Error/Error";
import Spinner from "../UI/Spinner/Spinner";
import HorizontalBooks from "../Books/HorizontalBooksSections/HorizontalBooks/HorizontalBooks";
import HorizontalNovels from "../HorizontalNovels/HorizontalNovels";
// services
import { getMyLibrary } from "../../services/books";
// css
import classes from "./MyLibrary.module.css";

const MyLibrary = () => {
  // state
  const [library, setLibrary] = useState(null);
  const [libraryLoading, setLibraryLoading] = useState(true);
  const [libraryError, setLibraryError] = useState("");
  // redux
  const favouriteBooks = useSelector((state) => state.favouriteBooks);

  // effects
  useEffect(() => {
    const getLibrary = async () => {
      setLibraryLoading(true);
      setLibraryError("");

      try {
        const reponse = await getMyLibrary();
        setLibrary(reponse.data.Library);
      } catch (error) {
        setLibraryError("حدث خطأ ما اثناء تحميل مكتبتك");
      }

      setLibraryLoading(false);
    };

    getLibrary();
  }, []);

  // view
  let content;
  if (libraryLoading) {
    content = <Spinner />;
  } else if (libraryError) {
    content = (
      <div className="h-v-center w-60 text-center">
        <Error errorMessage={libraryError} />
      </div>
    );
  } else {
    content = (
      <>
        <HorizontalBooks
          margin={{ bottom: 64 }}
          books={library.PurchasedBooks}
          title="كتب اضفتها للمكتبة"
          noCart
        />
        <HorizontalBooks
          margin={{ bottom: 64 }}
          books={library.PublishedBooks}
          title="الكتب المرفوعة"
          noCart
        />
        <HorizontalBooks
          margin={{ bottom: 64 }}
          books={favouriteBooks}
          title="الكتب المفضلة"
        />
        <HorizontalNovels novels={library.Novels} title="الروايات"  />
      </>
    );
  }
  return (
    <Layout shortHeader>
      <div className={classes.MyLibrary}>{content}</div>
    </Layout>
  );
};

export default MyLibrary;
