// react router
import { Outlet } from "react-router-dom";
// components
import Layout from "../Layout/Layout";
// css
import classes from "./Account.module.css";

const Account = () => {
  return (
    <Layout noAds navbarOnly>
      <div className={classes.AccountContainer}>
        <div className={classes.AccountContainer__Form}>
          <Outlet />
        </div>
        <div className={classes.AccountContainer__Image} />
      </div>
    </Layout>
  );
};

export default Account;
