// react
import { useState } from "react";
// components
import Error from "../../../UI/Error/Error";
import Spinner from "../../../UI/Spinner/Spinner";
import PrimaryButton from "../../../UI/PrimaryButton/PrimaryButton";
import OTPBox from "./OTPBox/OTPBox";
import PasswordResetForm from "./PasswordResetForm/PasswordResetForm";
// services
import { sendVerification } from "../../../../services/account";
// css
import classes from "./PasswordReset.module.css";
import classNames from "classnames";
//Phone Input
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
import ar from 'react-phone-input-2/lang/ar.json'

const PasswordReset = () => {
  // state
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isCodeVerified, setIsCodeVerified] = useState(false);
  const [code, setCode] = useState("");
  const [phoneNumber, setPhoneNumber] = useState({
    value: "",
    hasError: false,
    errorMessage: "",
  });
  const [phone, setPhone] = useState("");

  const [sendCodeLoading, setSendCodeLoading] = useState(false);
  const [emailWithCode, setEmailWithCode] = useState("");

  // handlers
  const phoneNumberChangedHandler = (event) => {
    const { value } = event.target;
    setPhoneNumber((prevPhoneNumber) => ({ ...prevPhoneNumber, value }));
  };
  const sendCodeHandler = async (event) => {
    event.preventDefault();
    const requestData = {
      PhoneNumber: phone,
    };
    console.log(requestData);
    setSendCodeLoading(true);
    try {
      const response = await sendVerification(requestData);
      const { succeeded, data, message } = response.data;
      if (succeeded) {
        setIsCodeSent(true);
        setEmailWithCode(data);
      } else {
        setPhoneNumber((prevPhoneNumber) => ({
          ...prevPhoneNumber,
          hasError: true,
          errorMessage:
            message ===
            "Please wait at least 20 seconds to receive another verification code"
              ? "من فضلك انتظر 20 ثانية لتتمكن من استلام كود جديد"
              : "تأكد من ادخال رقم هاتف مسجل صحيح",
        }));
      }
    } catch (error) {
      setPhoneNumber((prevPhoneNumber) => ({
        ...prevPhoneNumber,
        hasError: true,
        errorMessage: "حدث خطأ ما برجاء المحاولة مرة اخري",
      }));
      console.log(error);
    }
    setSendCodeLoading(false);
  };

  // view
  let content;
  if (!isCodeSent) {
    content = (
      <>
        {phoneNumber.hasError && (
          <div className={classNames("text-center", classes.FormError)}>
            <Error errorMessage={phoneNumber.errorMessage} />
          </div>
        )}
        <form method="POST" onSubmit={sendCodeHandler}>
          <div className="input-control">
          
                  <PhoneInput
id="phoneNumber"
name="phoneNumber"
localization={ar}
style={{direction: "ltr", width: '100%'}}
country={"eg"}
enableSearch={true}
value={phone}
onChange={(phone) => {
  setPhone(phone);
}}
/>
          </div>
          <div>
            <PrimaryButton
              type="submit"
              clicked={sendCodeHandler}
              disabled={sendCodeLoading}
              text="تغيير كلمة المرور"
            />
          </div>
          {sendCodeLoading && (
            <div className="spinner-parent ">
              <Spinner />
            </div>
          )}
        </form>
      </>
    );
  }
  if (isCodeSent) {
    content = (
      <OTPBox
        email={emailWithCode}
        setIsCodeVerified={setIsCodeVerified}
        setCode={setCode}
      />
    );
  }
  if (isCodeVerified) {
    content = <PasswordResetForm verificationCode={code} />;
  }

  return <div className={classes.PasswordReset}>{content}</div>;
};

export default PasswordReset;
