// css
import classes from "./NovelsFilters.module.css";

const NovelsFilters = (props) => {
  // props
  const {
    myNovelsChecked,
    favouriteNovelsChecked,
    libraryNovelsChecked,
    onMyNovelsChange,
    onFavouriteChange,
    onLibraryChange,
  } = props;

  return (
    <div className={classes.NovelsFilters}>
      <div className="checkbox__control">
        <input
          id="myNovels"
          type="checkbox"
          className="checkbox"
          checked={myNovelsChecked}
          onChange={(e) => onMyNovelsChange(e.target.checked)}
        />
        <label htmlFor="myNovels" className="checkbox__label">
          قصصي فقط
        </label>
      </div>

      <div className="checkbox__control">
        <input
          id="favouriteNovels"
          type="checkbox"
          className="checkbox"
          checked={favouriteNovelsChecked}
          onChange={(e) => onFavouriteChange(e.target.checked)}
        />
        <label htmlFor="favouriteNovels" className="checkbox__label">
          اظهر المفضلة فقط
        </label>
      </div>

      <div className="checkbox__control">
        <input
          id="libraryNovels"
          type="checkbox"
          className="checkbox"
          checked={libraryNovelsChecked}
          onChange={(e) => onLibraryChange(e.target.checked)}
        />
        <label htmlFor="libraryNovels" className="checkbox__label">
          المضافة للمكتبة فقط
        </label>
      </div>
    </div>
  );
};

export default NovelsFilters;
