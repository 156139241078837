// components
import CircleStat from "../../../UI/CircleStat/CircleStat";
import { Link } from "react-router-dom";
// css
import classes from "./AuthorSummarizedStats.module.css";
import { getAuthorIncomeBreakdown } from "../../../../services/author";
import { toast } from "react-toastify";

const authorBreakdownClickedHandler = async () => {
  var response;
  
      try {

        response = await getAuthorIncomeBreakdown();

        toast.success("جاري تحميل التقرير", { position: "bottom-right" });
      } catch (error) {
        console.log(error);
        //console.log(error.response);
      //  toast.error(error.response.data.data, { position: "bottom-right" });
      }

 return response;
};


const AuthorSummarizedStats = (props) => {
  // props
  const { user } = props;
  const data = [
    {
      display: "عدد الكتب المرفوعة",
      value: user.publishedBooksCount,
      to: "/myBooks",
    },
    {
      display: "عدد الكتب المشتراه",
      value: user.purchasedBooksCount,
      to: "/myBooks",
    },
    {
      display: "عدد التدوينات",
      value: user.userPublishedPostsCount,
      to: "/posts?myPosts=true",
    },
    {
      display: "اجمالي الدخل",
      value: user.totalIncome,      
    },
    {
      display: "اجمالي المشتريات",
      value: user.totalPurchases,
    },
    {
      display: "رصيدك الفعلي",
      value: user.credit,
    },
    {
      display: "الكتب المفضلة",
      value: user.favouriteBooksCount,
      to: "/myBooks",
    },
    {
      display: "كتب في السلة",
      value: user.cartBooksCount,
      to: "/cart",
    },
  ];
  return (
    
    <section className={classes.AuthorSummarizedStats}>
      {data.map((dataItem, index) => (

        <div key={index} className={classes.AuthorSummarizedStats__Stat}>
          {(dataItem.to && index!== 3) ? (
            <Link
              className={classes.AuthorSummarizedStats__Stat__Title}
              to={dataItem.to}
            >
              {dataItem.display}
            </Link>
          ) : (index == 3 ? 
            
            (
              <span
            role="button"
            className={classes.AuthorSummarizedStats__Stat__Title}
            onClick={authorBreakdownClickedHandler}
          >
             {dataItem.display}
          </span>
             
         
            ):
            (
              <p className={classes.AuthorSummarizedStats__Stat__Title}>
                {" "}
                {dataItem.display}
              </p>
            )
            )}

          <div>
            <CircleStat number={dataItem.value} smallText />
          </div>
        </div>
      ))}
    </section>
  );
};

export default AuthorSummarizedStats;
