// components
import classNames from "classnames";
import Layout from "../Layout/Layout";
// css
import classes from "./TermsAndConditions.module.css";

const TermsAndConditions = () => {
  return (
    <Layout shortHeader>
      <h1 className={classes.MainHeading}>
        شروط واحكام استخدام موقع BookCloudme.com
      </h1>
      <p className={classes.Intro}>
        التالي، يقدم لك الشروط و القيود التي يسمح وفقها موقع BookCloudme.com لك
        بالدخول إلى الموقع او تطبيقات الهاتف الخاصة به (المشار إليه بالموقع).
        باستخدامك للموقع او تطبيقات الهاتف الخاصة به (المشار إليك بالمستخدم ),
        تقر و توافق على تقيدك بهذه الشروط و القيود.{" "}
        <strong>يرجى قراءة هذه الصفحة بتمعن و انتباه</strong>. إذا لم توافق على
        الشروط و القيود، يرجى عدم استخدامك للموقع او تطبيقات الهاتف. تستخدم
        التعابير التالية (أنت والمستخدم) للإشارة إلى جميع الأفراد و/ أو الشركات
        التي تدخل الموقع او تطبيق الهاتف لأي غرض.
      </p>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>
          تفاصيل قانونية لاستخدام خدمات الموقع:
        </h2>
        <p className={classes.Section__Content}>
          - هذا الموقع يعتمد ربحه على العمولة بعد البيع، أو نظير تقديم خدمات
          مدفوعة، وهو للاستخدام المجاني بشكل عام مالم يطلب المستخدم شراء كتاب
          غير مجاني أو استخدام خدمة غير مجانية.
        </p>
        <p className={classes.Section__Content}>
          - يخولك الموقع أو التطبيق{" "}
          <strong>رفع محتوى نصي، مرئي أو مسموع</strong> وبيعه بشكل مجاني أو غير
          مجاني.
        </p>
        <p className={classes.Section__Content}>
          - بالنسبة للأفراد، سيتم احتساب عمولة للموقع من خلال عمليات البيع
          للمحتوى الواحد، عن طريق خصم القيمة الكاملة لأول عملية بيع للمحتوى، ثم
          اجتزاء قيمة <strong>40%</strong> من سعر البيع عن عمليات البيع التالية.
        </p>
        <p className={classes.Section__Content}>
          - بالنسبة للمؤسسات ودور النشر، سيتم احتساب عمولة للموقع من خلال عمليات
          البيع للمحتوى الواحد، عن طريق خصم القيمة الكاملة لأول عملية بيع
          للمحتوى، ثم اجتزاء قيمة <strong>30%</strong> من سعر البيع عن عمليات
          البيع التالية، مالم ينص العقد المبرم بين الموقع والمؤسسة أو دار النشر
          غير ذلك.
        </p>
        <p className={classes.Section__Content}>
          - <strong>العملة المستخدمة في هذا الموقع هي الجنيه المصري</strong>، أو
          ما يعادلها بالعملات الأخرى على حسب سعر الصرف.
        </p>
        <p className={classes.Section__Content}>
          - سيتم إضافة رصيد قيمة نسبة المستخدم ناشر المحتوى من عمليات البيع الى
          رصيد حسابه في <strong>محفظة الموقع أو التطبيق</strong> بعد كل عملية
          بيع.
        </p>
        <p className={classes.Section__Content}>
          - سيتم تحويل نسبة المستخدم ناشر المحتوى من عمليات بيع محتواه الى حسابه
          البنكي دورياً <strong>كل ستة أشهر</strong>، ويتحمل المستخدم ناشر
          المحتوى قيمة مصاريف التحويل المقررة.
        </p>
        <p className={classes.Section__Content}>
          - سيتطلب تحويل المبالغ الى ناشر المحتوى{" "}
          <strong>تقديم حساب بنكي فعّال</strong>، وتقديم ما يثبت شخصيته، ولا
          يتحمل الموقع أو التطبيق ادخال أي <strong>بيانات بنكية خاطئة</strong>{" "}
          من قبل المستخدم.
        </p>
        <p className={classes.Section__Content}>
          - في حالة قرر أحد المستخدمين سواء كان فرداً أو مؤسسة أو دار نشر{" "}
          <strong>حذف حسابه</strong>، فإن الموقع سيقوم بإلغاء حسابه مع كامل
          المحتوى المرفوع الا ما قد تم شرائه أو تحميله من قبل المستخدمين
          الآخرين، فإنه سيتم حذفه من الموقع مع بقائه في مكتبات المستخدمين الذين
          اشتروا هذا المحتوى.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>
          شروط استخدام المواد و الموقع او تطبيق الهاتف
        </h2>
        <p className={classes.Section__Content}>
          - يخولك الموقع او تطبيق الهاتف بمشاهدة نسخة من المادة المنشورة على
          الموقع او تطبيق الهاتف{" "}
          <strong>لاستخدامك الخاص فقط و ليس للأغراض التجارية</strong>. هذه
          الإجازة باستخدام الموقع او تطبيق الهاتف لا تعتبر بيع لأي حق من حقوق
          المالك. يسمح استخدام الموقع او تطبيق الهاتف من جهتك فقط و لا يمكنك أن
          تقوم أو باستئجار أو بتأجير أو إعارة أو تحويل الموقع او تطبيق الهاتف أو
          أي من المعلومات التي يحتوي عليها أو أي حق من حقوقك المنصوص عليها في
          هذا الاتفاق لأي جهة اخرى. لا يسمح لك{" "}
          <strong>تحويل أي جزء من المعلومات</strong> التي توجد في الموقع او
          تطبيق الهاتف أو تلك التي تتم مداولتها في الموقع او تطبيق الهاتف ولا
          يحق لك تخزينها إلا بعد حصولك على إذن مكتوب من الموقع او تطبيق الهاتف.
        </p>
        <p className={classes.Section__Content}>
          - يجب عليك المحافظة على جميع{" "}
          <strong>حقوق الطبع و العلامة التجارية و جميع إشعارات الملكية</strong>{" "}
          التي تحتويها المادة الأصلية في أي نسخة منها. لا يحق لك بيع، تعديل،
          إعادة إنتاج، عرض، توزيع، تحويل، نشر، ترخيص أو إنشاء أعمال مشتقة من أي
          مادة أو محتوى يحتويه الموقع او تطبيق الهاتف. إن استخدام المادة في أي
          موقع آخر أو في بيئة حاسوبية مشتركة لأي غرض ممنوع. لن تقوم بنسخ أو
          استخدام رموز ال HTML التي يبنيها الموقع او تطبيق الهاتف لإنشاء
          صفحاتها. إن جميع ما سبق ذكره محمي من قبل القوانين.
        </p>
        <p className={classes.Section__Content}>
          - قد يراجع الموقع او تطبيق الهاتف هذه الشروط و القيود في أي وقت عند
          تحديث هذا الإشعار. <strong>عليك زيارة هذه الصفحة بشكل دوري</strong>{" "}
          لقراءة الشروط و القيود لحكمها استخدامك للموقع.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>ترخيص</h2>
        <p className={classes.Section__Content}>
          - بنشرك أي محتوى بأي قسم عام أو غير عام في الموقع او تطبيق الهاتف سواء
          أكان ذلك الكتب، التدوينات، المسابقات أو أي قسم آخر، ستقوم بمنح الموقع او تطبيق
          الهاتف و الجهات التابعة لها <strong>الحق الكامل</strong> لتوزيعها على
          العامة و عرض المحتوى كله أو جزء منه دوليا و/ أو ضمه لأعمال اخرى بأي
          شكل من الأشكال سواء أكان ذلك في الإعلام أو باستخدام وسيلة تكنولوجية
          معروفة الآن أو اخرى تم تطويرها في المستقبل للمدة الكاملة لأي من الحقوق
          التي يحتويها ذلك المحتوى.
        </p>
        <p className={classes.Section__Content}>
          - أنت تضمن تنازل الشخص الذي يملك أية حقوق تشمل{" "}
          <strong>الحقوق الأخلاقية</strong> في محتوى ما بشكل كامل و فعال عنها في
          السماح لأي مشترك آخر بالدخول إلى، عرض، تخزين و إعادة إنتاج أي محتوى
          للاستخدام الخاص. يخضع احتفاظ مالك المحتوى المنشور على الموقع او تطبيق
          الهاتف لجميع الحقوق التي توجد في ذلك المحتوى.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>
          قواعد أمن الموقع او تطبيق الهاتف
        </h2>
        <p className={classes.Section__Content}>
          - يمنع المستخدمين من القيام بخرق أو{" "}
          <strong>محاولة خرق أمن هذا الموقع</strong> او تطبيق الهاتف و التي تشمل
          دون استثناء:
        </p>
        <ol className={classes.Section__List}>
          <li className={classes.Section__List__Item}>
            <strong>الدخول</strong> إلى معلومات غير مخصصة للمستخدم أو الدخول إلى
            خادم أو حساب لا يسمح للمستخدم الدخول إليه؛
          </li>
          <li className={classes.Section__List__Item}>
            <strong>محاولة</strong> كشف، مسح أو اختبار ضعف النظام أو الشبكة أو
            لخرق النظام الأمني معايير التحقق من دون التصريح المناسب؛
          </li>
          <li className={classes.Section__List__Item}>
            <strong>محاولة</strong> التدخل بالخدمة لأي مستخدم من خلال إرسال بريد
            غير مرغوب به أو لغم الموقع او تطبيق الهاتف بالفيروسات وغيرها من
            أساليب؛
          </li>
          <li className={classes.Section__List__Item}>
            <strong>إرسال</strong> رسائل إلكترونية غير مرغوب بها تتضمن إعلانات
            لبعض المنتجات و للترويج لها؛
          </li>
          <li className={classes.Section__List__Item}>
            <strong>تزوير</strong> إي من برتوكول أو أي جزء من المعلومات في
            البريد إلكتروني.TCP/IP
          </li>
        </ol>
        <p className={classes.Section__Content}>
          - قد تؤدي خروقات النظام أو النظام الأمني للشبكة إلى{" "}
          <strong>تجريم الفاعل مدنيا</strong>. ستتحقق الموقع او تطبيق الهاتف من
          خروقات كهذه بالتعاون مع{" "}
          <strong>السلطات و الأجهزة الأمنية المعنية</strong> في مقاضاة
          المستخدمين الذين أقدموا على ارتكاب أفعال كهذه.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>
          استخدامات ممنوعة على وجه الخصوص
        </h2>
        <p className={classes.Section__Content}>
          - يستخدم الموقع او تطبيق الهاتف فقط{" "}
          <strong>للأغراض الشرعية من قبل المستخدمين</strong>. أنت تضمن و توافق
          على أنك لن تستخدم الموقع او تطبيق الهاتف (أو تخطط أو تحرض على أو تساعد
          الآخرين على استخدامه) لأي غرض أو في أي أسلوب تمنعه هذه الشروط، القيود
          و الإشعارات أو القوانين المعمول بها تمنع الموقع او تطبيق الهاتف على
          وجه الخصوص أي استخدام للموقع و يجب على المستخدمين الموافقة على عدم
          استخدام الموقع او تطبيق الهاتف لأي من هذه الأغراض:
        </p>
        <ol
          className={classNames(
            classes.Section__List,
            classes["Section__List--numbers"]
          )}
        >
          <li className={classes.Section__List__Item}>
            <strong>نشر</strong> أي معلومات غير كاملة، خاطئة أو غير دقيقة عن
            أنفسهم في سيرهم الذاتية أو عن نفسك
          </li>
          <li className={classes.Section__List__Item}>
            <strong>تقمص</strong> شخصية أي شخص أو مؤسسة بشكل سواء أكان ممثل عن
            شركة، دار نشر، أو تشويه صورة شخص أو كيان تعمل معه أو آخر زورا.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>نشر</strong> أو بث أي محتوى ليس لديك الحق أن تقوم بإعادة
            نشره، عرضه أو بثه تحت أي قانون أو علاقات تعاقدية أو مبنية على أساس
            الثقة (كالعقود الغير مفصح عنها).
          </li>
          <li className={classes.Section__List__Item}>
            <strong>نشر</strong> أية امتيازات، مخططات هرمية، عضويات نوادي أو أية
            اتفاق مع وكالة مندوبي مبيعات أو أي فرصة تجارية تتطلب الدفع مقدم أو
            على أساس دوري و التي بدورها تتطلب توظيف موظفين آخرين، موزعين فرعين
            أو وكلاء فرعين.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>نشر</strong> أو بث أي مواد إعلانية، ترويجية، رسائل إلكترونية
            أو أية مادة اخرى غير مرغوب بها أو أي معلومات لا تتعلق بالسير الذاتية
            كالآراء و الإشعارات و غيرها من مواد
          </li>
          <li className={classes.Section__List__Item}>
            <strong>حذف</strong> أو مراجعة أي مادة تم نشرها من قبل أي شركة أو
            شخص آخرين
          </li>
          <li className={classes.Section__List__Item}>
            <strong>استخدام</strong> أي أداة أو برنامج للتدخل أو محاولة التدخل
            في تشغيل الموقع او تطبيق الهاتف أو أي نشاط يتم القيام به على هذا
            الموقع او تطبيق الهاتف.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>اتخاذ</strong> أي إجراء يعرض البنية التحتية للموقع لحمل غير
            ضروري يؤدي إلى إجهادها بشكل كبير.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>مشاركتك</strong> لكلمة سر تمكنك من الدخول إلى أماكن غير عامة
            من الموقع او تطبيق الهاتف مع طرف آخر أو استخدامك لكلمة السر هذه لعرض
            غير شرعي.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>مشاركتك</strong> لكلمة سر تمكنك من الدخول إلى أماكن غير عامة
            من الموقع او تطبيق الهاتف مع طرف آخر أو استخدامك لكلمة السر هذه لعرض
            غير شرعي.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>استخدام</strong> أي محرك، برنامج أو أي وسيلة اخرى (تتضمن
            العناكب، المتصفحات و غيرها من وسائل) لتصفح الموقع او تطبيق الهاتف أو
            البحث فيه بغير محركات البحث و البواحث المحفوظة التي توفرها الموقع او
            تطبيق الهاتف بالإضافة إلى متصفحات الإنترنت التي توفرها جهات اخرى مثل
            Google Chrome, Microsoft Internet Explorer.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>محاولة</strong> فك تشفير، تفريق، أو تفكيك و إعادة تجميع أي
            من البرامج التي تشكل الموقع او تطبيق الهاتف أو جزء منه.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>بث</strong>، نشر ، توزيع أي محتوى غير شرعي، مؤذ، مسيء، فاضح
            أو مكروه أخلاقيا أو دينياً أو يكون مسيئا لشخص آخر أو جهة اخرى.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>نشر</strong> أو بث أي محتوى ضار يحتوي على برامج ضارة
            كالفيروسات و غيرها تهدف إلى الإضرار أو التدخل، استقبال أي نظام أو
            معلومات بأسلوب متحايل أو الاستيلاء على ملكيتها.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>حذف</strong> أية إيعازات للمؤلف أو إشعارات قانونية، تعينات
            ملكية التي ترفقها في المراسلات.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>استخدام</strong> المراسلات التي يقدمها الموقع او تطبيق
            الهاتف بأسلوب يؤثر على توفر مصادره أو يؤثر على مستخدميه بشكل سلبي.
            مثل (استخدام الأحرف الإنجليزية الكبيرة و الذي يعطي معنى الصراخ بصوت
            عال أو إرسال نص بشكل متكرر)
          </li>
          <li className={classes.Section__List__Item}>
            <strong>إرفاق</strong>، نشر أو بث أي محتوى أو استخدام الموقع او
            تطبيق الهاتف بأسلوب يؤذي أي براءة، علامة تجارية أو أية حقوق ملكية
            اخرى أو سر مهنة تعود ملكيته لأي طرف أو خرق الحقوق الشخصية لأي طرف
            آخر.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>طباعة أو نسخ</strong> أي معلومات شخصية يمكن التعرف عليها عن
            المستخدمين، أو عن طبيعة عمل المؤسسات والشركات بالإضافة إلى الرسائل
            الإلكترونية، المكالمات الهاتفية الغير مرغوبة وإرسالها إلى الآخرين من
            الأشخاص و الشركات او المؤسسات ممنوع.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>إعادة</strong> بيع أو تحويل واجباتك أو حقوقك الموجودة في هذه
            الشروط و القيود.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>خرق</strong> أي قوانين مطبقة في هذا الاتفاق سواء أكانت
            محلية، وطنية، دولية. لا يجوز استخدام هذه الخدمة لنشر محتوى عنصري، أو
            غير شرعي، أو متطاول، أو مفعم بالكراهية، أو جنسي، أو موحٍ جنسيًا.
          </li>
        </ol>
        <p className={classes.Section__Content}>
          - يجب عليك ألا{" "}
          <strong>
            تشوّه، أو تلاحق، أو تضايق، أو تسيء، أو تزعج، أو تهدد، أو تنتحل، أو
            ترهب
          </strong>{" "}
          أشخاص أو كيانات. كما يجب عليك ألا تنشر محتوى خاص أو سري عبر هذه
          الخدمة، على سبيل المثال لا الحصر،{" "}
          <strong>
            المعلومات الخاصة ببطاقتك الائتمانية أو الخاصة بأي شخص آخر،
          </strong>{" "}
          أو أرقام بطاقة تحقيق الشخصية الخاصة بك أو الخاصة بأي شخص آخر، أو أرقام
          الهواتف غير العمومية، أو عناوين البريد الإلكتروني غير العمومية، الا
          اذا طلب منك الموقع هذا للتأكد من شخصية المستخدم الحقيقية.
        </p>
        <p className={classes.Section__Content}>
          - لا يجوز استخدام هذه الخدمة في أي أغراض{" "}
          <strong>غير قانونية أو غير مصرح بها</strong>. أنت توافق على الامتثال
          لجميع القوانين، والقواعد واللوائح (على سبيل المثال: الفيدرالية،
          المدنية، المحلية، الإقليمية) السارية على استخدامك لهذه الخدمة ومحتواها
          (كما هو موضح أدناه)، على سبيل المثال لا الحصر، قوانين حقوق الملكية.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>معلومات المستخدم</h2>
        <p className={classes.Section__Content}>
          - سيطلب منك كجزء من تسجيلك على الموقع او تطبيق الهاتف تزويد الموقع او
          تطبيق الهاتف بمعلومات معينة عنك و التي تضمن دون استثناء{" "}
          <strong>بريدك الإلكتروني</strong> (معلوماتك). كما انه قد يطلب منك{" "}
          <strong>تقديم حسابك البنكي</strong>.
        </p>
        <p className={classes.Section__Content}>
          - توافق أيضا على قيام الموقع او تطبيق الهاتف بمشاركة معلومات إجمالية
          متوفرة في طلب تسجيلك على أساس مجهول المصدر حيث{" "}
          <strong>
            لن يقوم الموقع او تطبيق الهاتف بالإفصاح عن اسمك، عنوانك، عنوان بريدك
            الإلكتروني أو رقم هاتفك ,أو حسابك البنكي دون موافقتك المسبقة
          </strong>
          . يحتفظ الموقع او تطبيق الهاتف بحقه بتقديم خدماته و منتجاته لك و
          للجهات الاخرى بناء على التفضيلات التي اخترتها خلال تسجيلك و في أي وقت
          بعده و التي تشمل العروض التي بقدمها الموقع او تطبيق الهاتف و أو الجهات
          الاخرى.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>المراقبة</h2>
        <p className={classes.Section__Content}>
          - سيكون للموقع دوراً في توزيع المحتوى التي يقدمه المستخدم و لن تقوم
          بمراقبة تلك المواد. إذا تم لفت انتباه الموقع او تطبيق الهاتف من قبل
          مستخدم يزعم وجود مواد لا تتماشى مع هذه الشروط و القيود، قد يتحقق
          الموقع او تطبيق الهاتف من صحة هذه المزاعم و ستقوم بحسن نية اتخاذ قرار
          يقضي بحذف تلك المادة أو المطالبة بحذفها. لا يتحمل الموقع او تطبيق
          الهاتف مسؤولية تلك الأعمال التي يقوم بها مرتكبيها تجاه المستخدمين
          الآخرين. يحتفظ الموقع او تطبيق الهاتف بحقه في :
        </p>
        <ol className={classes.Section__List}>
          <li className={classes.Section__List__Item}>
            <strong>طرد</strong> المستخدمين و منعهم من الدخول للموقع عند خرق هذه
            الشروط و القيود أو القانون.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>حذف</strong> المشاركات الغير قانونية و الخادشة للخلق العام و
            تلك التي تهدف لأثارة الفوضى،
          </li>
          <li className={classes.Section__List__Item}>
            <strong>اتخاذ</strong> إجراء ضروري بخصوص مادة قام بنشرها مستخدم ما و
            رأى الموقع او تطبيق الهاتف أن تلك المادة قد تعرضها إلى المسألة أو قد
            تؤدي إلى خسارة الموقع او تطبيق الهاتف جزء من أو كل الخدمات التي تحصل
            عليها من مزودي خدمة الإنترنت أو غيرهم من مزودين.
          </li>
        </ol>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>الروابط للمواقع الاخرى</h2>
        <p className={classes.Section__Content}>
          - يحتوي الموقع او تطبيق الهاتف على وصلات لمواقع اخرى. يقوم الموقع او
          تطبيق الهاتف بتوفير هذه الوصلات لراحتكم و لا تدعم و لا تتحمل مسؤولية
          المواد التي تحتويها هذه الوصلات. لا يتحمل الموقع او تطبيق الهاتف
          مسؤولية الفايروسات التي قد تتسلل من خلال تلك المواقع. ستتحمل مسؤولية
          قرارك في دخولك إلى المواقع الاخرى عبر تلك الوصلات.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>التسجيل و كلمة السر</h2>
        <p className={classes.Section__Content}>
          - تتحمل مسؤولية الحفاظ على سرية معلوماتك و كلمة سرك. ستتحمل نتائج كافة
          الاستخدامات و النشاطات التي تنجم عن استخدام كلمة سرك بغض النظر فيما
          إذا كانت مرخصة من قبلك. توافق على{" "}
          <strong>
            إعلام الموقع او تطبيق الهاتف على الفور في حال استخدام كلمة سرك أو
            تسجيلك بشكل غير مرخص
          </strong>
          . لن يتحمل الموقع او تطبيق الهاتف مسؤولية ضياع أو خراب يطرأ نتيجة
          الاستخدام الغير مرخص لكلمة سرك و تسجيلك.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>
          مسؤولية الموقع او تطبيق الهاتف
        </h2>
        <p className={classes.Section__Content}>
          - تقر صراحة بأنك <strong>المسؤول الوحيد</strong> عن شكل المحتوى و
          ملكيتك لأي مادة قمت بوضعها على الموقع او تطبيق الهاتف. الموقع او تطبيق
          الهاتف غير مسؤولين عن أي مادة منشورة على الموقع او تطبيق الهاتف و التي
          قد تكون مؤذية بمحتواها أو غير دقيقة. لا يستطيع الموقع او تطبيق الهاتف
          ضمان و لن تقوم بضمان هوية كل مستخدم للموقع.
        </p>
        <p className={classes.Section__Content}>
          - لا يتحمل الموقع او تطبيق الهاتف مسؤولية{" "}
          <strong>في حالة حصول خلاف بينك و بين مستخدم آخر</strong>، سيعفي الموقع
          او تطبيق الهاتف و وكلائه و موظفيه من أضرار (حقيقية، تبعية، مباشرة و
          غير مباشرة) من أي نوع، معروف أو غير معروف تنتج عن هذا الاختلاف.
        </p>
        <p className={classes.Section__Content}>
          - قد تحتوي المادة على أخطاء مطبعية أو معلومات غير دقيقة. لن يقدم
          الموقع او تطبيق الهاتف بيانات عن حالة صحة، دقة، اكتمال المادة أو
          المواد المنشورة من قبل المستخدمين. إن استخدام الموقع او تطبيق الهاتف و
          المادة المنشورة عليه و أي اعتماد على المادة التي قام بنشرها المستخدمين{" "}
          <strong>ستتحمل أنت مسؤوليته</strong>. يخضع الموقع او تطبيق الهاتف بشكل
          دوري لتغيرات يتم إجرائها من وقت لوقت. لا يضمن الموقع او تطبيق الهاتف
          تشغيل الموقع او تطبيق الهاتف بشكل يخلو من الأخطاء و لا تضمن خلو الموقع
          او تطبيق الهاتف و خادمه (أو أية برامج أو مواد يمكن الدخول إليها عبر
          الموقع او تطبيق الهاتف) من الفيروسات أو أية برامج مؤذية اخرى. لن يتحمل
          الموقع او تطبيق الهاتف تكاليف استبدال المعدات أو المعلومات التي قد
          تنتج عن استخدامك للموقع أو للمادة الموجودة عليه.
        </p>
        <p className={classes.Section__Content}>
          - يخلي الموقع او تطبيق الهاتف مسؤوليته لأقصى درجة يسمح بها القانون من
          تقديم الضمانات، سواء أكانت ضمانات ضمنية أو مصرحة تشمل بلا استثناء
          الضمانات الشرائية، ضمانات جودة لأي غرض معين، ضمانات العلامة التجارية و
          خرقها. لا يقدم الموقع او تطبيق الهاتف أية ضمانات على دقة، صحة، اكتمال
          أو صلاحية المحتوى، الخدمات، البرامج، النص، التصاميم الجرافيك و
          الوصلات.
        </p>
        <p className={classes.Section__Content}>
          - لن يقوم الموقع او تطبيق الهاتف، مزوديها أو أي من الأطراف الاخرى
          المذكورة في الموقع او تطبيق الهاتف مسؤولية أي ضرر مهما كان{" "}
          <strong>
            (سواء أكان ضررا مباشرا، غير مباشرا، تأديبيا، خاصا، تبعيا أو ضررا نتج
            عن معلومات ضائعة أو تدخل في شؤون العمل)
          </strong>{" "}
          نتج عن أو مرتبط بعلاقة ما بطريقة استخدام أو عدم القدرة على استخدام
          الموقع او تطبيق الهاتف (أو أي مواقع مرتبطة به) و المواد أو لأي معلومات
          أو برامج، منتجات و خدمات مقدمة من خلال الموقع او تطبيق الهاتف إذا كانت
          تعتمد على كفالة، عقد، ترجمة أو أي نظرية قانونية و إذا كانت أو لم يكن
          الموقع او تطبيق الهاتف عالمة بإمكانية حدوث أضرار كهذه.
        </p>
        <p className={classes.Section__Content}>
          - في حال وقوع اية خلافات، سيتم تنفذ قانون يستند على القانون المطبّق في
          جمهورية مصر العربية.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>الإبطال و التعويض المنصف</h2>
        <p className={classes.Section__Content}>
          في حالة خرقك لأي مادة أو لم يكن الموقع او تطبيق الهاتف قادر على التحقق
          و إثبات صحة أي معلومات قدمتها، قد يقوم الموقع او تطبيق الهاتف بمتابعة
          جميع حلولها القانونية و التي تشمل دون استثناء شطب إعلاناتك التعليمية
          او الوظيفية من الموقع او تطبيق الهاتف و/ أو الإبطال الفوري لتسجيلك أو
          قدرتك على الدخول إلى الموقع او تطبيق الهاتف أو أية خدمة اخرى يقدمها
          الموقع او تطبيق الهاتف لك. سيمتلك الموقع او تطبيق الهاتف حق إبطال
          دخولك للموقع في أي وقت دون أي إنذار مسبق و وقف متابعة أو تعديل
          المعلومات التي يحتويها الموقع او تطبيق الهاتف أو الموقع او تطبيق
          الهاتف نفسه في أي وقت. توافق على أحقية الموقع او تطبيق الهاتف في
          الحصول على تعويض منصف بالإضافة إلى الضرر المالي في حال حدوث خرق من
          جهتك للموقع.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>التعويض</h2>
        <p className={classes.Section__Content}>
          أنت موافق على الدفاع عن و تعويض الموقع او تطبيق الهاتف و موظفيه
          الإداريين و أعضاء مجلس إدارته و موظفيه و وكلائه من أي ادعاءات أو مطالب
          تشمل دون استثناء تكاليف الإجراءات القانونية و تكاليف المحاسبة التي
          نتجت عن استخدامك للمادة أو خرقك لهذه الشروط و القيود أو لضمانتك و أو
          أي حق من حقوق الأطراف الاخرى و التي تشمل حقوق المالك و الملكية
          الفكرية.
        </p>
      </div>
      <div className={classes.Section}>
        <h2 className={classes.Section__Title}>متفرقات</h2>
        <ol className={classes.Section__List}>
          <li className={classes.Section__List__Item}>
            <strong>صلاحية الشروط و القيود.</strong> يسمح باستخدام نسخة مطبوعة
            من هذه الشروط و القيود في الإجراءات القانونية و الإدارية المبنية على
            و المتعلقة بهذا الاتفاق و تخضع هذه النسخة إلى الشروط نفسها التي تخضع
            لها الوثائق التجارية الاخرى و السجلات التي يتم حفظ نسخ مطبوعة عنها.
            أنت موافق على عدم تأثر صلاحية و شرعية هذه الشروط و القيود بكونها
            نسخة إلكترونية بأي شكل من الأشكال. أنت توافق أيضا على اعتبار ضغطك
            لمربع "موافق" الموجود على الموقع او تطبيق الهاتف على أنه موافقتك
            الشرعية و القانونية على لالتزام بها و العمل وفقها.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>صلاحيات اخرى</strong>. قد لا يكون الدخول إلى المواد قانونيا
            من قبل بعض الأشخاص أو في بعض الدول و ستتحمل المسؤولية كاملة في حال
            دخولك للموقع بما يتماشى مع قوانين السلطة القضائية. يعتبر استخدام هذا
            الموقع او تطبيق الهاتف غير قانونيا في الأماكن التي لا تدعم هذه
            الشروط و القيود.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>تحويل الحقوق و التنازل عنها.</strong> لا يحق لك تحويل حقوق
            أي جزء من هذا الاتفاق دون حصولك على موافقة الموقع او تطبيق الهاتف
            المكتوبة في وقت سابق. لن يتم التنازل عن أي واجب من الواجبات أو أي حق
            من الحقوق المناط بها كل من الطرفين إلا عند وجود نسخة مكتوبة من هذا
            التنازل تم استيفائها من قبل الطرف الذي ستجري عملية التنازل ضده.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>القوانين الحاكمة للاتفاق.</strong> إن هذه الشروط و القيود
            محكومة من قبل القوانين الداخلية لجمهورية مصر العربية. فأية نزاعات
            لأي ادعاءات تخص هذا الاتفاق ستكون مقصورة فقط على المحاكم التي تقع في
            جمهورية مصر العربية
          </li>
          <li className={classes.Section__List__Item}>
            <strong>قابلية الفصل.</strong> إذا وجدت محكمة ذات صلاحية عدم صلاحية
            مادة من مواد هذه الشروط و القيود، لن تؤثر على صلاحية الشروط و القيود
            المتبقية.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>الاتفاق</strong> لن يحدث أي تغير لهذه الشروط إلا بنشر نسخة
            مراجعة في هذه الصفحة. تخضع أقسام معنية للموقع لشروط إضافية. باستخدام
            هذه الأقسام عليك أن تلتزم بالشروط الإضافية المطبقة في تلك الأقسام.
          </li>
          <li className={classes.Section__List__Item}>
            <strong>الاسترجاع</strong> .لا يحق للمستخدم استرجاع قيمة خدمة أو محتوى سواء مرئي أو مسموع، الا اذا كان هذا المحتوى أو الخدمة تالفاً أو لا يعمل، وذلك بتقديم طلب من خلال البريد الالكتروني info@bookcloudme.com             
            
          </li>

          <li className={classes.Section__List__Item}>
            <strong>الشحن</strong> المواد المتاحة على الموقع هي مواد الكترونية لا تتطلب الشحن للعميل.        
            
          </li>
        </ol>
      </div>
    </Layout>
  );
};

export default TermsAndConditions;
