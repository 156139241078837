export const booksSortOptions = [
  { label: "الأحدث إلي الأقدم", key: 2 },
  { label: "الأقدم إلي الأحدث", key: 3 },
  { label: "الأعلي تقييماً", key: 4 },
  { label: " الأكثر شراءاً", key: 1 },
];

export const postsSortOptions = [
  { label: "الأحدث إلي الأقدم", key: 2 },
  { label: "الأقدم إلي الأحدث", key: 3 },
  { label: "الأكثر تعليقاً", key: 4 },
  { label: "الأكثر إعجاباً", key: 1 },
];
