// components
import NavBar from "./NavBar/NavBar";
import Search from "./Search/Search";
import HeroText from "./HeroText/HeroText";
// css
import classes from "./Header.module.css";
// classnames
import classNames from "classnames";

const Header = (props) => {
  const { shortHeader, navbarOnly, hideSearch } = props;
  return (
    <header
      className={classNames(
        classes.Header,
        {
          [classes["Header--short"]]: shortHeader,
        },
        { [classes["Header--NavbarOnly"]]: navbarOnly }
      )}
    >
      <NavBar />
      {!navbarOnly && (
        <>
          {!hideSearch && <Search />}
          {!shortHeader && <HeroText />}
        </>
      )}
    </header>
  );
};

export default Header;
