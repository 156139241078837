// react
import { useRef, useState, useEffect } from "react";
// css
import classes from "./SortDropdown.module.css";
// classnames
import classNames from "classnames";
// assets
import arrowDown from "../../assets/icons/arrowDown.svg";

const SortDropdown = (props) => {
  // props
  const { onSortOptionClick, sortOptions, initialValue } = props;
  const sortDropdownRef = useRef();
  // state
  const [selectSortOption, setSelectedSortOption] = useState(
    initialValue || ""
  );
  const [open, setOpen] = useState(false);

  // handlers
  const selectSortClickedHandler = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const sortOptionClickedHandler = (option) => {
    setSelectedSortOption(option.label);
    setOpen(false);
    onSortOptionClick(option.key);
  };

  // effect
  useEffect(() => {
    const sortDropdownClosedHandler = (e) => {
      if (
        sortDropdownRef.current &&
        !sortDropdownRef.current.contains(e.target)
      ) {
        setOpen(false);
      }
    };

    document.addEventListener("click", sortDropdownClosedHandler);

    return () => {
      document.removeEventListener("click", sortDropdownClosedHandler);
    };
  }, []);
  return (
    <div className={classes.SortDropdown} ref={sortDropdownRef}>
      <div
        className={classes.SortDropdown__Selected}
        onClick={selectSortClickedHandler}
      >
        <span className={classes.SortDropdown__Selected__Text}>
          {selectSortOption || "الترتيب"}
        </span>

        <img
          src={arrowDown}
          alt="arrow down"
          className={classes.SortDropdown__Selected__Arrow}
        />
      </div>
      {open && (
        <ul className={classes.SortDropdown__Options}>
          {sortOptions.map((option, index) => (
            <li
              className={classNames(classes.SortDropdown__Options__Option, {
                [classes["SortDropdown__Options__Option--active"]]:
                  option.label === selectSortOption,
              })}
              key={index}
              onClick={() => sortOptionClickedHandler(option)}
              role="button"
            >
              {option.label}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SortDropdown;
