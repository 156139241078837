// components
import AddResourceLink from "../../AddResourceLink/AddResourceLink";
// css
import classes from "./ContestsIntro.module.css";

const ContestsIntro = (props) => {
  // props
  const { enableJoin, isJoined, contestId } = props;
  return (
    <section className={classes.ContestsIntro}>
      <header className={classes.ContestsIntro__Header}>
        <h2 className={classes.ContestsIntro__Header__Title}>
          التعريف بفكرة المسابقات
        </h2>
        {enableJoin &&
          (isJoined ? (
            <p className="text-success">مشترك بالفعل</p>
          ) : (
            <AddResourceLink
              path={`/contests/${contestId}/novels/publish`}
              resourceName="اشترك في المسابقة"
            />
          ))}
      </header>
      <p className={classes.ContestsIntro__Description}>فكرة المسابقات</p>
    </section>
  );
};

export default ContestsIntro;
