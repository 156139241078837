// react
import { useCallback, useEffect, useState } from "react";
// react router
import { Link, useLocation } from "react-router-dom";
// redux
import { useDispatch } from "react-redux";
import { login } from "../../../store/authSlice";
// google login
import { useGoogleLogin } from "@react-oauth/google";
// facebook login
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
// components
import Spinner from "../../UI/Spinner/Spinner";
import Error from "../../UI/Error/Error";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/bootstrap.css'
// services
import {
  loginUser,
  googleLogin,
  authenticateFacebookExternalUser,
} from "../../../services/account";
// helpers
import {
  parseJWT,
  persisSessionAuth,
  persistAuth,
} from "../../../helpers/auth";
// css
import classes from "./LoginForm.module.css";
// classnames
import classNames from "classnames";
// assets
import facebook from "../../../assets/images/facebook.png";
import google from "../../../assets/images/google.png";
import Consent from "./Consent/Consent";
import HoldScreen from "../../UI/HoldScreen/HoldScreen";
import ar from 'react-phone-input-2/lang/ar.json'


const LoginForm = () => {
  // state
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [rememberMe, setRememberMe] = useState(true);
  const [loginUserLoading, setLoginUserLoading] = useState(false);
  const [formError, setFormError] = useState("");
  const [isPhoneNumberRequired, setIsPhoneNumberRequired] = useState(null);
  const [tokensLoading, setTokensLoading] = useState(false);
  const [exchangedToken, setExchangedToken] = useState(null);
  // redux
  const dispatch = useDispatch();

  // handlers
  const usernameChangedHandler = (event) => {
    const { value } = event.target;
    setUsername(value);
  };
  const passwordChangedHandler = (event) => {
    const { value } = event.target;
    setPassword(value);
  };
  const rememberMeChangedHandler = (event) => {
    const { checked } = event.target;
    setRememberMe(checked);
  };
  const isSubmitDisabled = () => {
    if (username.trim() === "" || password.trim() === "" || loginUserLoading) {
      return true;
    }
    return false;
  };
  const processToken = useCallback(
    (accessToken, refreshToken) => {
      const user = parseJWT(accessToken);
      // remember me check
      if (rememberMe) {
        persistAuth(accessToken);
      } else {
        persisSessionAuth(accessToken);
      }
      dispatch(login(user));
    },
    [dispatch, rememberMe]
  );
  const loginUserHandler = async (event) => {
    event.preventDefault();
    // validate
    if (!username.trim() || !password.trim()) {
      return;
    }
    // submit
    setLoginUserLoading(true);
    const data = {
      PhoneNumber: username,
      Password: password,
    };

    try {
      const response = await loginUser(data);
      // tokens and user
      const { accessToken, refreshToken } = response.data;
      processToken(accessToken, refreshToken);
    } catch (error) {
      if (error.response?.status === 400) {
        setFormError(
          "بيانات الدخول غير صحيحة برجاء التاكد منها و المحاولة مرة أخري"
        );
      } else {
        setFormError("حدث خطأ ما برجاء المحاولة مرة أخري");
      }
    }

    setLoginUserLoading(false);
  };

  // google
  const loginByGoogle = useGoogleLogin({
    onSuccess: async (response) => {
      const data = {
        idToken: response.code,
      };
      setTokensLoading(true);
      try {
        const response = await googleLogin(data);
        const { accessToken, externalUserPhoneUpdated } = response.data;
        if (externalUserPhoneUpdated) {
          processToken(accessToken);
        } else {
          setIsPhoneNumberRequired(true);
          setExchangedToken(accessToken);
        }
      } catch (error) {
        setFormError("حدث خطأ ما برجاء المحاولة مرة اخري");
      }
      setTokensLoading(false);
    },
    onError: () => setFormError("حدث خطأ ما برجاء المحاولة مرة اخري"),
    flow: "auth-code",
  });

  const loginByFacebookHandler = async (response) => {
    const data = {
      name: response.name,
      email: response.email,
      pictureURl: response.picture.data.url,
    };
    setTokensLoading(true);
    try {
      const response = await authenticateFacebookExternalUser(data);
      const { accessToken, externalUserPhoneUpdated } = response.data;
      if (externalUserPhoneUpdated) {
        processToken(accessToken);
      } else {
        setIsPhoneNumberRequired(true);
        setExchangedToken(accessToken);
      }
    } catch (error) {
      setFormError("حدث خطأ ما برجاء المحاولة مرة اخري");
    }
    setTokensLoading(false);
  };

  // show toast if there is a success message state in the history
  const location = useLocation();
  const { state: locationState } = location;
  const [phone, setPhone] = useState("");

  useEffect(() => {
    const { successMessage } = locationState || {};
    if (successMessage) {
      toast.success(successMessage, { position: "bottom-right" });
      window.history.replaceState({}, document.title);
    }
  }, [locationState]);

  // view
  return (
    <>
      {tokensLoading && <HoldScreen />}
      {formError && (
        <div className={classes.FormError}>
          <Error errorMessage={formError} />
        </div>
      )}
      {isPhoneNumberRequired ? (
        <Consent accessToken={exchangedToken} processToken={processToken} />
      ) : (
        <form
          className={classes.LoginForm}
          method="POST"
          onSubmit={loginUserHandler}
        >
           
          <div className={classes.LoginForm__InputWrapper}>
            <input
              id="username"
              type="hidden"
              value={username}
              onChange={usernameChangedHandler}
              required
              className={classNames(
                classes.LoginForm__Input,
                classes["LoginForm__Input--username"]
              )}
            />
            
    <div>
      <PhoneInput
id="phonenumber"
localization={ar}
style={{direction: "ltr", width: '100%'}}
country={"eg"}
enableSearch={true}
value={phone}
onChange={(phone) => {
setUsername(phone);
}}
/>

{/* 
<label
              htmlFor="phonenumber"
              className={classNames(classes.LoginForm__InputWrapper__Label, {
              })}
            >
              رقم الهاتف
            </label> */}
</div>

{/* <label
              htmlFor="username"
              className={classNames(classes.LoginForm__InputWrapper__Label, {
                [classes["LoginForm__InputWrapper__Label--top"]]: username,
              })}
            >
              البريد الإلكتروني
            </label> */}
          
    

          
           
          </div>

          <div className={classes.LoginForm__InputWrapper}>
            <input
              id="password"
              type="password"
              value={password}
              onChange={passwordChangedHandler}
              required
              className={classNames(
                classes.LoginForm__Input,
                classes["LoginForm__Input--password"]
              )}
            />
            <label
              htmlFor="password"
              className={classNames(classes.LoginForm__InputWrapper__Label, {
                [classes["LoginForm__InputWrapper__Label--top"]]: password,
              })}
            >
              كلمة السر
            </label>
          </div>

          <div className={classes.LoginForm__InputWrapper}>
            <div className={classes.LoginForm__InputWrapper__Row}>
              <Link
                to="/account/password-reset"
                className={classes.LoginForm__InputWrapper__ForgetPassword}
              >
                هل نسيت كلمة السر ؟
              </Link>
              <div className={classes.LoginForm__InputWrapper__RememberMe}>
                <label
                  htmlFor="rememberMe"
                  className={classes.LoginForm__InputWrapper__RememberMe__Label}
                >
                  تذكرني
                </label>
                <label
                  htmlFor="rememberMe"
                  className={
                    classes.LoginForm__InputWrapper__RememberMe__Switch
                  }
                >
                  <input
                    id="rememberMe"
                    type="checkbox"
                    checked={rememberMe}
                    onChange={rememberMeChangedHandler}
                    className={classes.LoginForm__InputWrapper__Switch__Input}
                  />
                  <span
                    className={classes.LoginForm__InputWrapper__Switch__Slider}
                  />
                </label>
              </div>
            </div>
          </div>

          <button
            className={classes.LoginForm__Submit}
            disabled={isSubmitDisabled()}
          >
            {loginUserLoading ? <Spinner /> : "تسجيل الدخول"}
          </button>
          <p className={classes.LoginForm__OptionsText}>أو سجل عن طريق</p>
          <ul className={classes.LoginForm__LoginOptions}>
            <li className={classes.LoginForm__LoginOptions__Option}>
              <button
                onClick={() => loginByGoogle()}
                className={classes.LoginForm__LoginOptions__Option__Btn}
                type="button"
              >
                Google
                <img
                  src={google}
                  alt="تسجيل عن طريق جوجل"
                  className={classes.LoginForm__Options__Option__Img}
                />
              </button>
            </li>
            {/* <li className={classes.LoginForm__LoginOptions__Option}>
              <FacebookLogin
                appId={'1081006039273577'}
                fields="name,email,picture"
                callback={loginByFacebookHandler}
                authType="reauthenticate"
                render={(renderProps) => (
                  <button
                    className={classes.LoginForm__LoginOptions__Option__Btn}
                    onClick={renderProps.onClick}
                    disabled={renderProps.isDisabled}
                    type="button"
                  >
                    Facebook
                    <img
                      src={facebook}
                      alt="تسجيل عن طريق فيسبوك"
                      className={classes.LoginForm__Options__Option__Img}
                    />
                  </button>
                )}
              />
            </li> */}
          </ul>
          <p className={classes.LoginForm__NoAccount}>ليس لديك حساب ؟</p>
          <Link
            to="/account/register"
            className={classes.LoginForm__NoAccountLink}
          >
            التسجيل
          </Link>
        </form>
      )}
      <ToastContainer rtl autoClose={500} closeButton={false} />
    </>
  );
};

export default LoginForm;
