// react router
import { Link } from "react-router-dom";
// css
import classes from "./NavList.module.css";
import classNames from "classnames";

const NavList = () => {
  return (
    <nav className={classes.FooterNav}>
      <ul className={classes.FooterNav__List}>
        <li className={classNames(classes.FooterNav__List__Item)}>
          <Link to="/our-privacy-policy">سياسة الخصوصية</Link>
        </li>

        <li className={classNames(classes.FooterNav__List__Item)}>
          <Link to="/terms-and-conditions">شروط وأحكام المستخدم</Link>
        </li>

        <li className={classes.FooterNav__List__Item}>
          <Link to="/about-us">تعرف علينا</Link>
        </li>
        <li className={classes.FooterNav__List__Item}>
        <a className={classes.FooterNav__List__Item}
            href="mailto:info@bookcloudme.com"
          >
            تواصل معنا
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default NavList;
