import Layout from "../Layout/Layout";
import classes from "./ContactUs.module.css";
import contactUsImageOne from "../../assets/images/professional-it-developer-working-home 1.jpg";
import contactUsImageTwo from "../../assets/images/contactUsImageTwo.png";
import contactUsImageThree from "../../assets/images/contactUsImageThree.png";
import contactUsImageFour from "../../assets/images/contactUsImageFour.png";
import contactUsImageFive from "../../assets/images/ContactUsImageFive.png";
import HorizontalDash from "../AboutUs/HorizontalDash/HorizontalDash";
import classNames from "classnames";
import darkLogo from "../../assets/icons/darkLogo.svg";
import stillness from "../../assets/icons/stillness.svg";
import knowledge from "../../assets/icons/knowledge.svg";
import passion from "../../assets/icons/passion.svg";
import centerPoint from "../../assets/icons/centerPoint.svg";
import protection from "../../assets/icons/protection.svg";
import playStore from "../../assets/icons/playStore.svg";
// css
const ContactUs = () => {
  return (
    <Layout shortHeader hideSearch>

<section className={classNames(classes.AboutUs, "mb-26")}>
  <h1 className={classNames(classes.AboutUs__Heading, "mb-26")}>
    من نحن
  </h1>
  <p className={classes.AboutUs__Text}>
    بوكّلاود هي منصّة النشر الذاتي الأولى من نوعها التي تم تسطير كل سطر من
    سطورها منذ مراحلها الأولية بعناية فائقة لتمكين كل فرد من أفراد العالم
    الإبداعي من القرّاء والكتّاب والناشرين والنقاد، من كسر الحواجز
    والاندماج وتبديل الأدوار فيما بينهم، مع الحرص على حماية أعمالهم
    الأصلية ضد القرصنة، لنتمكن من تحقيق هدفنا المشترك نحو مجتمعنا المثالي.
  </p>
</section>

<section className={classNames(classes.OurResources)}>
  <div className={classes.OurResources__Logo}>
    <div className={classes.OurResources__Logo__Dash} />
    <div className={classes.OurResources__Logo__Icon}>
      <img
        className={classes.OurResources__Logo__Icon__Image}
        src={darkLogo}
        alt="bookcloud-logo"
      />
    </div>
    <div className={classes.OurResources__Logo__Dash} />
  </div>
  <h1 className={classes.AboutUs__Heading}>مصادر الهامنا</h1>
</section>

<ul className={classes.InspirationList}>
  <li className={classes.InspirationList__Item}>
    <div className={classes.InspirationList__Item__Icon}>
      <img src={stillness} alt="stillness-icon" />
    </div>
    <div>
      <h2 className={classes.InspirationList__Item__Header}>السكون</h2>
      <p className={classes.AboutUs__Text}>
        استمتع بالسكون وصفاء الذهن وسلاسة الاستخدام من خلال منصّة
        بوكّلاود.
      </p>
    </div>
  </li>
  <HorizontalDash />
  <li className={classes.InspirationList__Item}>
    <div className={classes.InspirationList__Item__Icon}>
      <img src={knowledge} alt="knowledge-icon" />
    </div>
    <div>
      <h2 className={classes.InspirationList__Item__Header}>
        الاندماج المعرفي
      </h2>
      <p className={classes.AboutUs__Text}>
        الحلقات في تصميمنا تمثل المواهب المختلفة لكل فرد من أفراد المجتمع
        الثقافي، في مجتمع بوكّلاود تندمج كل هذه الحلقات لتشكّل كياناً
        مبدعاً مترابطاً قادراً على العطاء الفكري بشكل يواكب تطور عالم
        الثقافة.
      </p>
    </div>
  </li>
  <HorizontalDash />
  <li className={classes.InspirationList__Item}>
    <div className={classes.InspirationList__Item__Icon}>
      <img src={passion} alt="passion-icon" />
    </div>
    <div>
      <h2 className={classes.InspirationList__Item__Header}>الشغف</h2>
      <p className={classes.AboutUs__Text}>
        من قلب الكاتب الى هوى القارئ، ومن ابداع الناشر الى حماس الناقد،
        يترابط مجتمع بوكّلاود في رحلة البحث عن الشغف.
      </p>
    </div>
  </li>
  <HorizontalDash />
  <li className={classes.InspirationList__Item}>
    <div className={classes.InspirationList__Item__Icon}>
      <img src={centerPoint} alt="center-point-icon" />
    </div>
    <div>
      <h2 className={classes.InspirationList__Item__Header}>
        نقطة التمحور
      </h2>
      <p className={classes.AboutUs__Text}>
        بوكّلاود هي نقطة التمحور التي تنطلق منها كل مسارات الإبداع، فأياً
        كانت رحلتك، يمكنك دائماً البدء من هنا.
      </p>
    </div>
  </li>
  <HorizontalDash />
  <li className={classes.InspirationList__Item}>
    <div className={classes.InspirationList__Item__Icon}>
      <img src={protection} alt="protection-icon" />
    </div>
    <div>
      <h2 className={classes.InspirationList__Item__Header}>الحماية</h2>
      <p className={classes.AboutUs__Text}>
        نضع ضمن أهم أولوياتنا حماية حقوق التأليف والنشر من خلال قارئ
        إلكتروني مضاد للقرصنة.
      </p>
    </div>
  </li>
</ul>




<section className={classNames(classes.OurResources)}>
  <div className={classes.OurResources__Logo}>
    <div className={classes.OurResources__Logo__Dash} />
    <div className={classes.OurResources__Logo__Icon}>
      <img
        className={classes.OurResources__Logo__Icon__Image}
        src={darkLogo}
        alt="bookcloud-logo"
      />
    </div>
    <div className={classes.OurResources__Logo__Dash} />
  </div>
  <h1 className={classes.AboutUs__Heading}>خدمات بوكلاود</h1>
  <br></br>
</section>

      <section className={classes.ContactUs}>
        <div className={classes.ContactUs__ImageCol}>
          <img
            className={classes.ContactUs__ImageCol__Image}
            src={contactUsImageOne}
            alt="Publish your book easily"
          />
        </div>
        <div className={classes.ContactUs__TextCol}>
          <p className={classes.ContactUs__TextCol__Text}>
            <span className={classes.ContactUs__TextCol__Text__Main}>
              انشــــر
            </span>{" "}
            <span className={classes.ContactUs__TextCol__Text__Second}>
              كتابـــــك
            </span>
          </p>
          <p className={classes.ContactUs__TextCol__Text__Feat}>بلمسة واحدة</p>
        </div>

        <div className={classes.ContactUs__TextCol}>
          <p className={classes.ContactUs__TextCol__Text}>
            <span className={classes.ContactUs__TextCol__Text__Main}>
              قــارئ
            </span>{" "}
            <span className={classes.ContactUs__TextCol__Text__Second}>
              الكتروني
            </span>
          </p>
          <p className={classes.ContactUs__TextCol__Text__Feat}>
            مؤمّن بالكامل
          </p>
        </div>
        <div className={classes.ContactUs__ImageCol}>
          <img
            className={classes.ContactUs__ImageCol__Image}
            src={contactUsImageTwo}
            alt="Secured E-Reader"
          />
        </div>

        <div className={classes.ContactUs__ImageCol}>
          <img
            className={classes.ContactUs__ImageCol__Image}
            src={contactUsImageThree}
            alt="Original works"
          />
        </div>
        <div className={classes.ContactUs__TextCol}>
          <p className={classes.ContactUs__TextCol__Text}>
            <span className={classes.ContactUs__TextCol__Text__Main}>اقتن</span>{" "}
            <span className={classes.ContactUs__TextCol__Text__Second}>
              أعمال أصلية
            </span>
          </p>
          <p className={classes.ContactUs__TextCol__Text__Feat}>
            بأسعار مناسبة.
          </p>
        </div>

        <div className={classes.ContactUs__TextCol}>
          <p className={classes.ContactUs__TextCol__Text}>
            <span className={classes.ContactUs__TextCol__Text__Main}>دوّن</span>{" "}
            <span className={classes.ContactUs__TextCol__Text__Second}>
              أفكارك في
            </span>
          </p>
          <p className={classes.ContactUs__TextCol__Text__Feat}>
            مجتمع بوكّلاود
          </p>
        </div>
        <div className={classes.ContactUs__ImageCol}>
          <img
            className={classes.ContactUs__ImageCol__Image}
            src={contactUsImageFour}
            alt="Bookcloud community"
          />
        </div>

        <div className={classes.ContactUs__ImageCol}>
          <img
            className={classes.ContactUs__ImageCol__Image}
            src={contactUsImageFive}
            alt="Share what you think with us"
          />
        </div>
        <div className={classes.ContactUs__TextCol}>
          <p className={classes.ContactUs__TextCol__Text}>
            <span className={classes.ContactUs__TextCol__Text__Main}>
              شاركنا
            </span>{" "}
            <span className={classes.ContactUs__TextCol__Text__Second}>
              رأيك في
            </span>
          </p>
          <p className={classes.ContactUs__TextCol__Text__Feat}>
            اعمال بوكّلاود
          </p>
        </div>
      </section>

      <section className={classes.Motto}>
  <p className={classes.Motto__Text}>
    كن مبدعاً واضف كتابك وتدويناتك واحظ بالقرّاء الجدد حمل التطبيق الآن
    واستمتع بمزايا بوكّلاود الفريدة 
  </p>
  <div style={{textAlign: "center"}} >
  <a
        href="https://play.google.com/store/apps/details?id=com.bookcloudme"
        target="_blank"
        rel="noreferrer"
        className={classNames(
          classes.BookCloudApps__Store,
          classes.BookCloudApps__PlayStore
        )}
      >
        <img  style ={{width: "50px", marginTop: "24px"}} src={playStore} alt="play store" />
      </a>
  </div>
  
</section>


</Layout>
  );
};

export default ContactUs;
