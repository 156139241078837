// css
import classes from "./CircleStat.module.css";
// classnames
import classNames from "classnames";

const CircleStat = (props) => {
  // props
  const { number, smallText } = props;

  // view
  return (
    <div
      className={classNames(classes.CircleStat, {
        [classes["CircleStat--small-text"]]: smallText,
      })}
    >
      {number}
    </div>
  );
};

export default CircleStat;
