import { createSlice } from "@reduxjs/toolkit";
// helpers
import { getInitialAuthentication } from "../helpers/auth";

export const authSlice = createSlice({
  name: "auth",
  initialState: getInitialAuthentication(),
  reducers: {
    login: (_, action) => {
      return { isAuthenticated: true, user: action.payload };
    },
    addUserInfo: (state, action) => {
      return { ...state, user: { ...state.user, ...action.payload } };
    },
    logout: () => {
      return { isAuthenticated: false, user: null };
    },
  },
});

export const { login, logout, addUserInfo } = authSlice.actions;

export default authSlice.reducer;
