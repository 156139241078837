import { createSlice } from "@reduxjs/toolkit";

export const favouriteBooksSlice = createSlice({
  name: "favouriteBooks",
  initialState: [],
  reducers: {
    toggleFavouriteBook: (state, action) => {
      if (state.find((book) => book.id === action.payload.id)) {
        return state.filter((book) => book.id !== action.payload.id);
      } else {
        state.push(action.payload);
      }
    },
    addFavouriteBooks: (state, actions) => {
      return [...state, ...actions.payload];
    },
  },
});

export const { toggleFavouriteBook, addFavouriteBooks } =
  favouriteBooksSlice.actions;

export default favouriteBooksSlice.reducer;
