// react
// import { useState } from "react";
// components
// import AuthorDetailedStats from "./AuthorDetailedStats/AuthorDetailedStats";
import AuthorSummarizedStats from "./AuthorSummarizedStats/AuthorSummarizedStats";
// css
import classes from "./AuthorStats.module.css";
// classnames
// import classNames from "classnames";

const AuthorStats = (props) => {
  // props
  const { user } = props;
  // state
  // const [displayType, setDisplayType] = useState("details");

  // handlers
  // const displayChangedHandler = (display) => {
  //   setDisplayType(display);
  // };

  // view
  // let content;
  // if (displayType === "details") {
  //   content = <AuthorDetailedStats data={authorData} />;
  // } else if (displayType === "summary") {
  //   content = <AuthorSummarizedStats data={authorData} />;
  // }

  return (
    <section className={classes.AuthorStats}>
      {/* <ul className={classes.AuthorStats__Tabs}>
        <li
          className={classNames(classes.AuthorStats__Tabs__Tab, {
            [classes["AuthorStats__Tabs__Tab--active"]]:
              displayType === "summary",
          })}
          role="button"
          onClick={() => displayChangedHandler("summary")}
        >
          ارقام اجمالية
        </li>
        <li
          className={classNames(classes.AuthorStats__Tabs__Tab, {
            [classes["AuthorStats__Tabs__Tab--active"]]:
              displayType === "details",
          })}
          role="button"
          onClick={() => displayChangedHandler("details")}
        >
          التفصيل
        </li>
      </ul> */}
      <div className={classes.AuthorStats__Content}>
        <AuthorSummarizedStats user={user} />
      </div>
    </section>
  );
};

export default AuthorStats;
