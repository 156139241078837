// components
import SiteMap from "./SiteMap/SiteMap";
import CopyBar from "./CopyBar/CopyBar";
// css
import classes from "./Footer.module.css";

const Footer = () => {
  return (
    <footer className={classes.Footer}>
      <SiteMap />
      <CopyBar />
    </footer>
  );
};

export default Footer;
