// react
import { useRef, useState } from "react";
// components
import Spinner from "../../../../UI/Spinner/Spinner";
import CancelButton from "../../../../../components/UI/CancelButton/CancelButton";
import PrimaryButton from "../../../../UI/PrimaryButton/PrimaryButton";
import Error from "../../../../UI/Error/Error";
// services
import { validateVerificationCode } from "../../../../../services/account";
// css
import classes from "./OTPBox.module.css";
import classNames from "classnames";

const OTPBox = (props) => {
  // props
  const { email, setIsCodeVerified, setCode } = props;
  // state
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpError, setOtpError] = useState("");
  const [verifyCodeLoading, setVerifyCodeLoading] = useState(false);
  // refs
  const submitButtonRef = useRef();

  // handle OTP change
  const otpChangedHandler = (event, index) => {
    if (isNaN(event.target.value)) {
      return false;
    } else {
      setOtp((prevOtp) =>
        prevOtp.map((otp, idx) => (idx === index ? event.target.value : otp))
      );

      // autofocus the next input
      event.target.nextSibling?.focus();
    }
  };

  // handle backspace keydown
  const keyDownHandler = (event, index) => {
    if (event.key === "Backspace") {
      setOtp((prevOtp) =>
        prevOtp.map((otp, idx) =>
          index - idx === 1 || idx === index ? "" : otp
        )
      );

      event.target.previousSibling?.focus();
    } else if (event.key === "Enter") {
      submitButtonRef.current.click();
    }
  };

  // handle reset button
  const resetOtpHandler = () => {
    setOtp((prevOtp) => prevOtp.map(() => ""));
  };

  // handle verify button

  const verifyCodeHandler = async (event) => {
    event.preventDefault();
    const data = { VerificationCode: otp.join("") };
    setVerifyCodeLoading(true);
    try {
      const response = await validateVerificationCode(data);
      const { succeeded } = response.data;
      if (succeeded) {
        setIsCodeVerified(true);
        setCode(data.VerificationCode);
      } else {
        setOtpError("رمز التحقق غير صحيح");
      }
    } catch (error) {
      setOtpError("حدث خطا ما برجاء المحاولة مرة أخري");
    }
    setVerifyCodeLoading(false);
  };

  return (
    <>
      {otpError && (
        <div className={classNames("text-center", classes.OTPError)}>
          <Error errorMessage={otpError} />
        </div>
      )}

      <div className={classes.OTPBox}>
        <p className={classes.OTPBox__Info}>
          تم ارسال كود الي البريد الالكتروني {email}
        </p>
        <div className={classes.OTPBox__Boxes} dir="ltr">
          {otp.map((otpField, index) => (
            <input
              autoFocus={index === 0}
              key={index}
              value={otpField}
              maxLength={1}
              className={classes.OTPBox__Boxes__Box}
              onChange={(event) => otpChangedHandler(event, index)}
              onKeyDown={(event) => keyDownHandler(event, index)}
              onFocus={(event) => event.target.select()}
            />
          ))}
        </div>

        <div className={classes.OTPBox__Controls}>
          <PrimaryButton
            type="submit"
            clicked={verifyCodeHandler}
            disabled={verifyCodeLoading}
            text="إرسال"
            buttonRef={submitButtonRef}
          />
          <CancelButton
            type="button"
            clicked={resetOtpHandler}
            disabled={verifyCodeLoading}
            text="مسح"
          />
        </div>
        {verifyCodeLoading && (
          <div className="spinner-parent ">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default OTPBox;
