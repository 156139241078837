// react
import { useState } from "react";
// components
import Overlay from "../../Overlay/Overlay";
import Modal from "../Modal";
import Spinner from "../../Spinner/Spinner";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CancelButton from "../../CancelButton/CancelButton";
import { toast } from "react-toastify";
// services
import { updateProfile } from "../../../../services/account";
// css
import classes from "./UsernameModal.module.css";
// classnames
import classNames from "classnames";

const UsernameModal = (props) => {
  // props
  const { visible, close, getDetails, currentUsername } = props;
  // state
  const [username, setUsername] = useState({
    value: currentUsername,
    hasError: true,
    touched: false,
    errorMessage: "",
  });
  const [updateLoading, setUpdateLoading] = useState(false);

  // handler
  const usernameChangedHandler = (event) => {
    const { value } = event.target;
    // validate
    let valid = true;
    if (value.trim() === "") {
      valid = false;
    }
    // update state
    setUsername({
      touched: true,
      value,
      hasError: valid ? false : true,
      errorMessage: valid ? "" : "من فضلك ادخل الاسم الجديد",
    });
  };

  const closeHandler = () => {
    setUsername({
      value: currentUsername,
      hasError: true,
      touched: false,
      errorMessage: "",
    });
    setUpdateLoading(false);
    close();
  };

  const updateUsername = async (event) => {
    event.preventDefault();
    // validate
    if (username.hasError) {
      return;
    }
    // data
    const data = new FormData();
    data.append("userName", username.value);
    setUpdateLoading(true);

    try {
      await updateProfile(data);
      getDetails();
      toast.success("تم تحديث اسمك بنجاح", {
        position: "bottom-right",
      });
      close();
    } catch {
      toast.error("حدث خطأ ما اثناء تحديث الاسم", {
        position: "bottom-right",
      });
    }

    setUpdateLoading(false);
  };

  return (
    <>
      {visible && <Overlay clicked={closeHandler} />}
      <Modal visible={visible}>
        <form onSubmit={updateUsername} className={classes.UsernameForm}>
          <div className="input-control">
            <label
              htmlFor="username"
              className={classNames(
                "input-control__label",
                "input-control__label--required"
              )}
            >
              ادخل اسم جديد
            </label>
            <input
              id="username"
              type="text"
              value={username.value}
              onChange={usernameChangedHandler}
              className="input-control__input"
            />
            {username.touched && username.hasError && (
              <p className="input-control__error">{username.errorMessage}</p>
            )}
          </div>

          <div className={classes.UsernameForm__Actions}>
            <div className={classes.UsernameForm__Actions__Cancel}>
              <CancelButton
                type="button"
                clicked={closeHandler}
                text="غلق"
                rounded
              />
            </div>
            {updateLoading && (
              <div className="spinner-parent">
                <Spinner />
              </div>
            )}
            <div className={classes.UsernameForm__Actions__Submit}>
              <PrimaryButton
                type="submit"
                text="تحديث"
                clicked={updateUsername}
                rounded
                disabled={username.hasError || updateLoading}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UsernameModal;
