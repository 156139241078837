// react
import { useState } from "react";
// components
import Overlay from "../../Overlay/Overlay";
import Modal from "../Modal";
import Spinner from "../../Spinner/Spinner";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CancelButton from "../../CancelButton/CancelButton";
// react toastify
import { toast } from "react-toastify";
// services
import { addBookReview } from "../../../../services/books";
// css
import classes from "./ReviewModal.module.css";
// classnames
import classNames from "classnames";

const ReviewModal = (props) => {
  // props
  const { visible, close, bookId, starCount } = props;
  // state
  const [form, setForm] = useState({
    title: {
      value: "",
      touched: false,
      hasError: false,
      errorMessage: "",
      rules: {},
    },
    review: {
      value: "",
      touched: false,
      hasError: false,
      errorMessage: "",
      rules: {},
    },
  });
  const [reviewLoading, setReviewLoading] = useState(false);

  // handler
  const checkValidity = (value, rules) => {
    const validity = { valid: true, message: "" };

    if (rules.isRequired) {
      if (value.trim() === "") {
        validity.valid = false;
        validity.message = "هذا الحقل مطلوب";
      }
    }

    return validity;
  };

  const inputChangedHandler = (event) => {
    const { name, value } = event.target;
    const { valid, message } = checkValidity(value, form[name].rules);
    setForm((prevForm) => ({
      ...prevForm,
      [name]: {
        ...prevForm[name],
        touched: true,
        hasError: !valid,
        errorMessage: message,
        value,
      },
    }));
  };

  const closeHandler = () => {
    setForm({
      title: {
        value: "",
        touched: false,
        hasError: false,
        errorMessage: "",
        rules: {
          isRequired: true,
        },
      },
      review: {
        value: "",
        touched: false,
        hasError: false,
        errorMessage: "",
        rules: {
          isRequired: true,
        },
      },
    });
    setReviewLoading(false);
    close();
  };

  const isCanSubmitted = () => {
    let can = true;
    for (let key in form) {
      if (form[key].hasError) {
        can = false;
      }
    }
    return can;
  };

  const review = async (event) => {
    event.preventDefault();
    // validate
    if (!isCanSubmitted) {
      return;
    }
    // date
    const data = new FormData();
    data.append("BookId", Number(bookId));
    data.append("Title", form.title.value);
    data.append("Review", form.review.value);
    data.append("StarCount", starCount);
    setReviewLoading(true);
    try {
      await addBookReview(data);
      toast.success("تم حفظ التقييم بنجاح", { position: "bottom-right" });
      closeHandler();
    } catch (error) {
      toast.error("حدث خطأ ما أثناء حفظ التقييم", {
        position: "bottom-right",
      });
    }
    setReviewLoading(false);
  };

  return (
    <>
      {visible && <Overlay clicked={() => closeHandler()} />}
      <Modal visible={visible}>
        <form onSubmit={review} className={classes.ReviewForm}>
          <div className="input-control">
            <label
              htmlFor="title"
              className={classNames("input-control__label")}
            >
              اضف عنوان
            </label>
            <input
              id="title"
              name="title"
              value={form.title.value}
              onChange={inputChangedHandler}
              className="input-control__input"
            />
            {form.title.touched && form.title.hasError && (
              <p className="input-control__error">{form.title.errorMessage}</p>
            )}
          </div>
          <div className="input-control">
            <label
              htmlFor="review"
              className={classNames("input-control__label")}
            >
              من فضلك اكتب تقييمك هنا ... تقييمك يساعد الاخرين .
            </label>
            <textarea
              id="review"
              name="review"
              rows={6}
              value={form.review.value}
              onChange={inputChangedHandler}
              className="input-control__input"
            />
            {form.review.touched && form.review.hasError && (
              <p className="input-control__error">{form.review.errorMessage}</p>
            )}
          </div>

          <div className={classes.ReviewForm__Actions}>
            <div className={classes.ReviewForm__Actions__Cancel}>
              <CancelButton
                type="button"
                clicked={() => closeHandler()}
                text="غلق"
                rounded
              />
            </div>
            {reviewLoading && (
              <div className="spinner-parent">
                <Spinner />
              </div>
            )}
            <div className={classes.ReviewForm__Actions__Submit}>
              <PrimaryButton
                type="submit"
                text="تأكيد النشر"
                clicked={review}
                rounded
                disabled={!isCanSubmitted() || reviewLoading}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ReviewModal;
