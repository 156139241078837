// react
import { useState } from "react";
// react router dom
import { useLocation, useNavigate } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// components
import ShareModal from "../../../UI/Modal/ShareModal/ShareModal";
import CommentModal from "../../../UI/Modal/CommentModal/CommentModal";
import { toast } from "react-toastify";
// services
import { likePost, sharePost } from "../../../../services/posts";
// css
import classes from "./PostActions.module.css";
// assets
import share from "../../../../assets/icons/share.svg";
import comment from "../../../../assets/icons/comment.svg";
import like from "../../../../assets/icons/like.svg";
import liked from "../../../../assets/images/liked.png";

const PostActions = (props) => {
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // react router
  const location = useLocation();
  const navigate = useNavigate();
  // props
  const { postId, getPostDetails, isLiked } = props;
  // states
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [commentModalVisible, setCommentModalVisible] = useState(false);
  const [postLiked, setPostLiked] = useState(isLiked);

  // handlers

  // share
  const shareModalClosedHandler = () => {
    setShareModalVisible(false);
  };
  const postSharedHandler = () => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: false });
    } else {
      setShareModalVisible(true);
    }
  };
  const postLinkCopyHandler = async () => {
    navigator.clipboard.writeText(document.URL);
    toast.success("تم نسخ الرابط بنجاح", { position: "bottom-right" });
    await sharePost(postId);
  };

  // comment
  const commentModalClosedHandler = () => {
    setCommentModalVisible(false);
  };
  const commentOnPostHandler = () => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: false });
    } else {
      setCommentModalVisible(true);
    }
  };

  // like
  const postLikedHandler = async () => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: false });
    } else {
      const params = {
        postId: Number(postId),
      };
      try {
        await likePost(params);
        setPostLiked((prevState) => !prevState);
        let toastMessage;
        if (postLiked) {
          toastMessage = "تم إلغاء الإعجاب بالتدوينة";
        } else {
          toastMessage = "تم تسجيل الإعجاب بالتدوينة";
        }
        toast.success(toastMessage, { position: "bottom-right" });
      } catch (error) {
        let toastMessage;
        if (postLiked) {
          toastMessage = "حدث خطا ما اثناء الغاء الاعجاب";
        } else {
          toastMessage = "حدث خطا ما اثناء تسجيل الاعجاب";
        }
        toast.error(toastMessage, {
          position: "bottom-right",
        });
      }
    }
  };

  // view
  return (
    <>
      <ul className={classes.PostActions}>
        <li className={classes.PostActions__Action} title="مشاركة">
          <img
            src={share}
            alt="share"
            onClick={postSharedHandler}
            role="button"
          />
        </li>

        <li className={classes.PostActions__Action} title="اترك تعليق">
          <img
            src={comment}
            alt="comment"
            onClick={commentOnPostHandler}
            role="button"
          />
        </li>

        <li className={classes.PostActions__Action} title="أعجبني">
          <img
            src={postLiked ? liked : like}
            alt="like"
            onClick={postLikedHandler}
            role="button"
          />
        </li>
      </ul>
      <ShareModal
        visible={shareModalVisible}
        close={shareModalClosedHandler}
        onCopyClickHandler={postLinkCopyHandler}
      />
      <CommentModal
        visible={commentModalVisible}
        close={commentModalClosedHandler}
        postId={postId}
        getPostDetails={getPostDetails}
      />
    </>
  );
};

export default PostActions;
