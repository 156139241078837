// react router
import { Link } from "react-router-dom";
// css
import classes from "./HorizontalBooks.module.css";
// classnames
import classNames from "classnames";
// components
import Slick from "../../../UI/Slick/Slick";
import Spinner from "../../../UI/Spinner/Spinner";
import Book from "../../Book/Book";
import Error from "../../../UI/Error/Error";
import Info from "../../../UI/Info/Info";

const carouselSettings = {
  pagination: false,
  itemsToShow: 4,
  itemsToScroll: 1,
  isRTL: true,
};

const breakPoints = [
  { width: 1, itemsToShow: 2 },
  { width: 400, itemsToShow: 3 },
  { width: 740, itemsToShow: 4 },
];

const HorizontalBooks = (props) => {
  const { margin, books, isLoading, title, titleLink, booksError, noCart } =
    props;

  let content;
  if (isLoading) {
    content = <Spinner />;
  } else if (booksError) {
    content = (
      <div className={classes.HorizontalBooks__Feedback}>
        <Error errorMessage={booksError} />
      </div>
    );
  } else if (books.length !== 0) {
    content = (
      <Slick settings={carouselSettings} breakPoints={breakPoints}>
        {books.map((book, index) => (
          <Book key={index} book={book} noCart={noCart} />
        ))}
      </Slick>
    );
  } else {
    content = (
      <div className={classes.HorizontalBooks__Feedback}>
        <Info infoMessage="عفوا لا يوجد كتب حاليا" />
      </div>
    );
  }
  return (
    <section
      className={classNames(
        classes.HorizontalBooks,
        classes[`HorizontalBooks--mb-${margin.bottom}`]
      )}
    >
      <header className={classes.HorizontalBooks__Header}>
        {titleLink ? (
          <Link
            to={titleLink}
            className={classes.HorizontalBooks__Header__Title}
            target="_blank"
          >
            {title}
          </Link>
        ) : (
          <h2 className={classes.HorizontalBooks__Header__Title}>{title}</h2>
        )}
      </header>
      <section className={classes.Books}>{content}</section>
    </section>
  );
};

export default HorizontalBooks;
