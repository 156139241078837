// react
import { useState, useEffect } from "react";
// components
import Spinner from "../../../UI/Spinner/Spinner";
// css
import classes from "./Ad.module.css";
// classnames
import classNames from "classnames";
// services
import { getRandomAd } from "../../../../services/ads";

const Ad = (props) => {
  const { type, priority, margin } = props;

  const [ad, setAd] = useState(null);
  const [adLoading, setAdLoading] = useState(false);

  useEffect(() => {
    const getAd = async () => {
      const params = {
        type,
        priority,
        count: 1,
      };

      setAdLoading(true);
      try {
        const response = await getRandomAd(params);
        setAd(response.data[0]);
      } catch (error) {
        console.log(error);
      }

      setAdLoading(false);
    };

    getAd();
  }, [type, priority]);

  let content;
  if (adLoading) {
    content = (
      <div className="spinner-parent">
        <Spinner />
      </div>
    );
  } else if (ad) {
    content = (
      <div
        className={classNames(
          classes.Ad,
          classes[`Ad--${type}`],
          classes[`Ad--mt-${margin.top}`],
          classes[`Ad--mb-${margin.bottom}`]
        )}
      >
        <a
          className={classes.Ad__Link}
          href={ad.hyperlink}
          target="_blank"
          rel="noreferrer"
        >
          <img className={classes.Ad__Link__Img} src={ad?.imageUrl} alt="ad" />
        </a>
      </div>
    );
  }

  return content;
};

export default Ad;
