// components
import Author from "../components/Author/Author";
import Book from "../components/Book/Book";
import Books from "../components/Books/Books";
import MyLibrary from "../components/MyLibrary/MyLibrary";
import AddBook from "../components/Books/AddBook/AddBook";
import Cart from "../components/Cart/Cart";
import Home from "../components/Home/Home";
import Post from "../components/Post/Post";
import Posts from "../components/Posts/Posts";
import Profile from "../components/Profile/Profile";
import AddPost from "../components/Posts/AddPost/AddPost";
import Contests from "../components/Contests/Contests";
import Contest from "../components/Contests/Contest/Contest";
import PublishNovel from "../components/Contests/PublishNovel/PublishNovel";
import PrivacyPolicy from "../components/PrivacyPolicy/PrivacyPolicy";
import TermsAndConditions from "../components/TermsAndConditions/TermsAndConditions";
import AboutUs from "../components/AboutUs/AboutUs";
import CartPaymentStatus from "../components/Cart/cartPaymentStatus/CartPaymentStatus";
import ContactUs from "../components/ContactUs/ContactUs";

export const routes = [
  { path: "/", component: Home },
  { path: "/books", component: Books },
  { path: "/myBooks", component: MyLibrary, private: true },
  { path: "/books/:bookId", component: Book },
  { path: "/books/add", component: AddBook, private: true },
  { path: "/authors/:authorId", component: Author },
  { path: "/posts", component: Posts },
  { path: "/posts/add", component: AddPost, private: true },
  { path: "/posts/:postId", component: Post },
  { path: "/cart", component: Cart, private: true },
  { path: "/cart/:s", component: CartPaymentStatus, private: true },
  { path: "/profile", component: Profile, private: true },
  { path: "/contests", component: Contests, private: true },
  { path: "/contests/:contestId/novels", component: Contest, private: true },
  {
    path: "/contests/:contestId/novels/publish",
    component: PublishNovel,
    private: true,
  },
  {
    path: "/our-privacy-policy",
    component: PrivacyPolicy,
  },
  {
    path: "/terms-and-conditions",
    component: TermsAndConditions,
  },
  // {
  //   path: "/about-us",
  //   component: AboutUs,
  // },
  {
    path: "/about-us",
    component: ContactUs,
  },
];
