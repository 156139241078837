// react
import { useEffect, useRef } from "react";
// react router
import { Link, useNavigate } from "react-router-dom";
// redux
import { useDispatch } from "react-redux";
import { logout } from "../../store/authSlice";
// helpers
import { clearAuth } from "../../helpers/auth";
// css
import classes from "./UserMenu.module.css";

const UserMenu = (props) => {
  // props
  const { closeMenu, containerRef } = props;
  // refs
  const userMenuRef = useRef();
  // redux
  const dispatch = useDispatch();
  // react router
  const navigate = useNavigate();

  // handlers
  const logoutHandler = () => {
    clearAuth();
    dispatch(logout());
    navigate("/");
  };

  // effects
  useEffect(() => {
    const clickAwayHadler = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        closeMenu();
      }
    };
    document.addEventListener("mousedown", clickAwayHadler);
    return () => {
      document.removeEventListener("mousedown", clickAwayHadler);
    };
  }, [closeMenu, containerRef]);
  return (
    <ul className={classes.UserMenu} ref={userMenuRef}>
      <li className={classes.UserMenu__Item}>
        <Link to="/profile" className={classes["UserMenu__Item--Link"]}>
          الصفحة الشخصية
        </Link>
      </li>
      <li className={classes.UserMenu__Item}>
        <button
          className={classes["UserMenu__Item--Btn"]}
          onClick={logoutHandler}
        >
          تسجيل الخروج
        </button>
      </li>
    </ul>
  );
};

export default UserMenu;
