// react
import { useState, useRef } from "react";
// components
import Overlay from "../../Overlay/Overlay";
import Modal from "../Modal";
import Error from "../../Error/Error";
import Spinner from "../../Spinner/Spinner";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CancelButton from "../../CancelButton/CancelButton";
import ConvertInstructions from "./ConvertInstructions/ConvertInstructions";
// services
import { convertToEpub } from "../../../../services/books";
// css
import classes from "./ConvertModal.module.css";
// classnames
import classNames from "classnames";

const ConvertModal = (props) => {
  // props
  const { visible, close } = props;
  // state
  const [wordFile, setWordFile] = useState({
    value: null,
    hasError: false,
    touched: false,
    errorMessage: "",
  });
  const [convertLoading, setConvertLoading] = useState(false);
  const [convertError, setConvertError] = useState("");
  // refs
  const inputRef = useRef();

  // handler
  const wordFileChangedHandler = (event) => {
    const file = event.target.files[0];
    // validate
    let valid = true;
    if (!/(\.doc|\.docx|\.odt)$/i.exec(file.name)) {
      valid = false;
    }
    // update state
    setWordFile({
      touched: true,
      value: valid ? file : null,
      hasError: valid ? false : true,
      errorMessage: valid ? "" : "من فضل اختر صيغة وورد",
    });
  };

  const closeHandler = () => {
    setWordFile({
      value: null,
      hasError: false,
      touched: false,
      errorMessage: "",
    });
    setConvertError("");
    setConvertLoading(false);
    inputRef.current.value = "";
    close();
  };

  const convertFile = async (event) => {
    event.preventDefault();
    // validate
    if (!wordFile.value) {
      return;
    }
    setConvertLoading(true);
    setConvertError("");
    try {
      const response = await convertToEpub(wordFile.value);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute(
        "download",
        `${wordFile.value.name.split(".")[0]}.epub`
      );
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch {
      setConvertError("حدث خطأ ما أثناء التحويل");
    }
    setConvertLoading(false);
  };

  return (
    <>
      {visible && <Overlay clicked={closeHandler} />}
      <Modal visible={visible}>
        <form onSubmit={convertFile} className={classes.ConvertForm}>
          <div className="input-control">
            <label
              htmlFor="wordFile"
              className={classNames(
                "input-control__label",
                "input-control__label--required"
              )}
            >
              تحويل الي epub
            </label>
            <input
              id="wordFile"
              type="file"
              accept=".doc, .docx, .odt"
              onChange={wordFileChangedHandler}
              className="input-control__input"
              ref={inputRef}
            />
            {wordFile.touched && wordFile.hasError && (
              <p className="input-control__error">{wordFile.errorMessage}</p>
            )}
          </div>

          {convertError && (
            <div>
              <Error errorMessage={convertError} />
            </div>
          )}

          {/* convert instructions */}
          <ConvertInstructions />
          {/* end convert instuctions */}

          <div className={classes.ConvertForm__Actions}>
            <div className={classes.ConvertForm__Actions__Cancel}>
              <CancelButton
                type="button"
                clicked={closeHandler}
                text="غلق"
                rounded
              />
            </div>
            {convertLoading && (
              <div className="spinner-parent">
                <Spinner />
              </div>
            )}
            <div className={classes.ConvertForm__Actions__Submit}>
              <PrimaryButton
                type="submit"
                text="تحويل"
                clicked={convertFile}
                rounded
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default ConvertModal;
