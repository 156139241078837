// axios
import axios from "axios";
// helpers
import { getStorageItem, clearAuth } from "../helpers/auth";
// constants
import { ACCESS_TOKEN } from "../constants/auth";
import { BASE_URL } from "../constants/axios";

export const axiosMainInstance = axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivateInstance = axios.create({
  baseURL: BASE_URL,
});

axiosPrivateInstance.interceptors.request.use(
  (config) => {
    config.headers.Authorization = `Bearer ${getStorageItem(ACCESS_TOKEN)}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosPrivateInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (
      (error.response.status === 403 || error.response.status === 401) &&
      getStorageItem(ACCESS_TOKEN)
    ) {
      clearAuth();
      window.location.reload();
    }

    return Promise.reject(error);
  }
);
