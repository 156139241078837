// components
import Info from "../../../UI/Info/Info";
import Novel from "./Novel/Novel";
// css
import classes from "./Novels.module.css";

const Novels = (props) => {
  // props
  const { novels, onFavouriteClick, onLibraryClick } = props;

  return (
    <ul className={classes.Novels}>
      {novels.length === 0 ? (
        <div className="h-v-center text-center">
          <Info infoMessage="لا توجد روايات لعرضها" />
        </div>
      ) : (
        novels.map((novel, index) => (
          <Novel
            key={index}
            novel={novel}
            onFavouriteClick={onFavouriteClick}
            onLibraryClick={onLibraryClick}
          />
        ))
      )}
    </ul>
  );
};

export default Novels;
