// css
import classes from "./CancelButton.module.css";
// classnames
import classNames from "classnames";

const CancelButton = (props) => {
  // props
  const { text, clicked, disabled, rounded, type } = props;

  return (
    <button
      className={classNames(classes.CancelButton, {
        [classes["CancelButton--rounded"]]: rounded,
      })}
      onClick={clicked}
      disabled={disabled}
      type={type}
    >
      {text}
    </button>
  );
};

export default CancelButton;
