// css
import classes from "./PostStats.module.css";

const PostStats = (props) => {
  // props
  const { shares, comments, likes } = props;

  // view
  return (
    <ul className={classes.Stats}>
      <li className={classes.Stats__Stat}>{`${shares} مشاركة`}</li>
      <li className={classes.Stats__Stat}>{`${comments} تعليق`}</li>
      <li className={classes.Stats__Stat}>{`${likes} اعجاب`}</li>
    </ul>
  );
};

export default PostStats;
