// react
import { useState, useEffect } from "react";
// components
import PostComment from "./PostComment/PostComment";
import Info from "../../UI/Info/Info";

// css
import classes from "./PostComments.module.css";

const PostComments = (props) => {
  // props
  const { comments, getPostDetails } = props;
  // state
  const [displayComments, setDisplayComments] = useState([]);
  const [pointer, setPointer] = useState(3);

  // handlers
  const viewMoreCommentsClickedHandler = () => {
    setPointer((prevPointer) => prevPointer + 3);
  };

  // effects
  useEffect(() => {
    setDisplayComments(comments.slice(0, pointer));
  }, [pointer, comments]);

  // view
  let content;
  if (comments.length === 0) {
    content = (
      <div className="h-v-center w-60 text-center">
        <Info infoMessage="لا توجد تعليقات حالياً" />
      </div>
    );
  } else {
    content = displayComments.map((comment, index) => (
      <li key={index} className={classes.PostComments__Comments__Comment}>
        <PostComment comment={comment} getPostDetails={getPostDetails} />
      </li>
    ));
  }

  return (
    <section className={classes.PostComments}>
      <header className={classes.PostComments__Header}>
        <h2 className={classes.PostComments__Header__Title}>التعليقات</h2>
      </header>
      <ul className={classes.PostComments__Comments}>{content}</ul>
      {pointer < comments.length && (
        <button
          className={classes.PostComments__ViewMoreBtn}
          onClick={viewMoreCommentsClickedHandler}
        >
          المزيد من التعليقات
        </button>
      )}
    </section>
  );
};

export default PostComments;
