// react
import { useState, useCallback, useRef } from "react";
// react router
import { Link, useLocation } from "react-router-dom";
// redux
import { useSelector } from "react-redux";
// components
import NotificationBadge from "../../../../UI/Badge/NotificationBadge/NotificationBadge";
import UserMenu from "../../../../UserMenu/UserMenu";
// css
import classes from "./AuthenticationCorner.module.css";
// assets
import notifications from "../../../../../assets/icons/notifications.svg";
import cartIcon from "../../../../../assets/icons/cart.svg";
import AuthorBadge from "../../../../UI/Badge/AuthorBadge/AuthotBadge";
import NotificationsMenu from "../../../../NotificationsMenu/NotificationsMenu";

const AuthenticationCorner = () => {
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const user = useSelector((state) => state.auth.user);
  const cart = useSelector((state) => state.cart);
  // react router
  const location = useLocation();
  // state
  const [userMenuShown, setUserMenuShown] = useState(false);
  const [notificationsMenuShown, setNotificationsMenuShown] = useState(false);
  // refs
  const userMenuWrapperRef = useRef();
  const notificationsWrapperRef = useRef();

  // handlers
  const userMenuShownHandler = () => {
    setUserMenuShown((prevState) => !prevState);
  };
  const userMenuClosedHandler = useCallback(() => {
    setUserMenuShown(false);
  }, []);
  const showNotificationsMenuHandler = () => {
    setNotificationsMenuShown(true);
  };
  const closeNotificationsMenuHandler = () => {
    setNotificationsMenuShown(false);
  };

  let content;
  if (isAuthenticated) {
    content = (
      <div className={classes.AuthenticationCorner__Controls}>
        <Link
          to="/cart"
          className={classes.AuthenticationCorner__Controls__Cart}
        >
          <img src={cartIcon} alt="cart" />
          {cart.length !== 0 && <NotificationBadge number={cart.length} />}
        </Link>
        <div className="relative" ref={notificationsWrapperRef}>
          <img
            src={notifications}
            alt="notifications"
            className={classes.AuthenticationCorner__Controls__Notifications}
            role="button"
            onClick={showNotificationsMenuHandler}
          />
          {notificationsMenuShown && (
            <NotificationsMenu
              containerRef={notificationsWrapperRef}
              closeMenu={closeNotificationsMenuHandler}
            />
          )}
        </div>

        <div className="relative" ref={userMenuWrapperRef}>
          <button
            className={classes.AuthenticationCorner__Controls__Badge}
            onClick={userMenuShownHandler}
          >
            <AuthorBadge imageSource={user.profileImgURl} />
          </button>

          {userMenuShown && (
            <UserMenu
              closeMenu={userMenuClosedHandler}
              containerRef={userMenuWrapperRef}
            />
          )}
        </div>
      </div>
    );
  } else {
    content = (
      <Link
        className={classes.AuthenticationCorner__LoginButton}
        to="/account/login"
        state={location}
        replace={true}
      >
        قم بالدخول | التسجيل
      </Link>
    );
  }

  return <div className={classes.AuthenticationCorner}>{content}</div>;
};

export default AuthenticationCorner;
