// react
import { useState } from "react";
// components
import PrimaryButton from "../../../../components/UI/PrimaryButton/PrimaryButton";
import Spinner from "../../../UI/Spinner/Spinner";
import Error from "../../../UI/Error/Error";
// services
import { updateExternalUserInfo } from "../../../../services/account";
// css
import classes from "./Consent.module.css";
import classNames from "classnames";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import ar from 'react-phone-input-2/lang/ar.json'
const Consent = (props) => {
  // props
  const { processToken, accessToken } = props;
  // state
  const [phone,setPhone] = useState("");

  const [form, setForm] = useState({
    
    accountType: {
      value: "Writer",
      touched: false,
      hasError: false,
      errorMessage: "",
      rules: {},
    },
  });
  
  const [completeLoginLoading, setCompleteLoginLoading] = useState(false);
  const [completeLoginError, setCompleteLoginError] = useState("");

  // handlers
  const checkValidity = (value, rules) => {
    const validity = { valid: true, message: "" };

    if (rules.isRequired && value.trim() === "") {
      validity.valid = false;
      validity.message = "هذا الحقل مطلوب";
    }

    return validity;
  };

  const inputChangedHandler = (event) => {
    const { name, value } = event.target;
    const { valid, message } = checkValidity(value, form[name].rules);

    setForm((prevForm) => ({
      ...prevForm,
      [name]: {
        ...prevForm[name],
        touched: true,
        hasError: !valid,
        errorMessage: message,
        value,
      },
    }));
  };

  const isCanSubmitted = () => {
    let can = true;
    for (let key in form) {
      if (form[key].hasError) {
        can = false;
      }
    }
    return can;
  };

  const completeLoginHandler = async (event) => {
    event.preventDefault();
    const data = {
      PhoneNumber: phone,
      role: form.accountType.value,
    };
    setCompleteLoginLoading(true);
    try {
      if(phone.length > 7){
        await updateExternalUserInfo(accessToken, data);
        processToken(accessToken);
      }else{
        setCompleteLoginError("يرجي التأكد من رقم الهاتف");
      }
   
    } catch (error) {
      console.log(error.response.data);
      if(error.response.data == 'Phone Number Already Registered'){
        setCompleteLoginError("رقم الهاتف مسجل بالفعل");

      }else{

        setCompleteLoginError("حدث خطأ ما برجاء المحاولة مرة اخري");
      }
    }
    setCompleteLoginLoading(false);
  };

  return (
    <>
      {completeLoginError && (
        <div className={classes.FormError}>
          <Error errorMessage={completeLoginError} />
        </div>
      )}
      <form
        method="POST"
        onSubmit={completeLoginHandler}
        className={classes.ConsentForm}
      >
        <div className="input-control">
          <label
            htmlFor="phoneNumber"
            className={classNames(
              "input-control__label",
              "input-control__label--required"
            )}
          >
            رقم الهاتف
          </label>
         
             <PhoneInput
             isRequired={true}
      id="phoneNumber"
      style={{direction: "ltr"}}
      country={"eg"}
      enableSearch={true}
      value={phone}
      onChange={(phone) => {
        setPhone(phone);      }}

    />
         
        </div>
        <div className="input-control">
          <label
            htmlFor="accountType"
            className={classNames(
              "input-control__label",
              "input-control__label--required"
            )}
          >
            نوع الحساب
          </label>
          <select
            id="accountType"
            name="accountType"
            value={form.accountType.value}
            onChange={inputChangedHandler}
            className={classNames(
              "input-control__input",
              "input-control__input--select"
            )}
          >
            <option value="Writer">
              {`${
                form.accountType.value === "Writer" ? "" : ""
              } قارئ / كاتب`}
            </option>
            

          </select>
        </div>
        <PrimaryButton
          text="التسجيل"
          clicked={completeLoginHandler}
          rounded
          disabled={completeLoginLoading || !isCanSubmitted()}
        />
        {completeLoginLoading && (
          <div className="spinner-parent">
            <Spinner />
          </div>
        )}
      </form>
    </>
  );
};

export default Consent;
