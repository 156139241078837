// react
import { useState } from "react";
// components
import Layout from "../../Layout/Layout";
import AddResourceHeader from "../../UI/AddResourceHeader/AddResourceHeader";
import PrimaryButton from "../../UI/PrimaryButton/PrimaryButton";
import { ToastContainer, toast } from "react-toastify";
import Spinner from "../../UI/Spinner/Spinner";
// services
import { addPost } from "../../../services/posts";
// css
import classes from "./AddPost.module.css";
// classnames
import classNames from "classnames";

const AddPost = () => {
  // state
  const [form, setForm] = useState({
    postTitle: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
      },
    },
    postContent: {
      value: "",
      touched: false,
      hasError: true,
      errorMessage: "",
      rules: {
        isRequired: true,
      },
    },
  });
  const [addNewPostLoading, setAddNewPostLoading] = useState(false);

  // handlers
  const checkValidity = (value, rules) => {
    const validity = { valid: true, message: "" };

    if (rules.isRequired) {
      if (value.trim() === "") {
        validity.valid = false;
        validity.message = "هذا الحقل مطلوب";
      }
    }

    return validity;
  };

  const inputChangedHandler = (event) => {
    const { name, value } = event.target;

    const { valid, message } = checkValidity(value, form[name].rules);
    setForm((prevForm) => ({
      ...prevForm,
      [name]: {
        ...prevForm[name],
        touched: true,
        hasError: !valid,
        errorMessage: message,
        value,
      },
    }));
  };

  const isCanSubmitted = () => {
    let can = true;
    for (let key in form) {
      if (form[key].hasError) {
        can = false;
      }
    }
    return can;
  };

  const addNewPost = async (event) => {
    event.preventDefault();
    // validate
    if (!isCanSubmitted()) {
      return;
    }

    // date
    const data = new FormData();
    data.set("postTitle", form.postTitle.value);
    data.set("postContent", form.postContent.value);

    setAddNewPostLoading(true);

    try {
      await addPost(data);
      toast.success("تمت إضافة التدوينة بنجاح", { position: "bottom-right" });
    } catch (error) {
      toast.error("حدث خطأ ما اثناء اضافة التدوينة", {
        position: "bottom-right",
      });
    }

    setAddNewPostLoading(false);
  };

  return (
    <Layout shortHeader>
      <AddResourceHeader title="تدوينة جديدة" />
      <form onSubmit={addNewPost} className={classes.AddPostForm}>
        <div className="input-control">
          <label
            htmlFor="postTitle"
            className={classNames(
              "input-control__label",
              "input-control__label--required"
            )}
          >
            عنوان التدوينة
          </label>
          <input
            id="postTitle"
            type="text"
            name="postTitle"
            value={form.postTitle.value}
            onChange={inputChangedHandler}
            className="input-control__input"
          />
          {form.postTitle.touched && form.postTitle.hasError && (
            <p className="input-control__error">
              {form.postTitle.errorMessage}
            </p>
          )}
        </div>
        <div className="input-control">
          <label
            htmlFor="postContent"
            className={classNames(
              "input-control__label",
              "input-control__label--required"
            )}
          >
            المحتوي
          </label>
          <textarea
            id="postContent"
            name="postContent"
            value={form.postContent.value}
            onChange={inputChangedHandler}
            className="input-control__input"
            rows={8}
          />
          {form.postContent.touched && form.postContent.hasError && (
            <p className="input-control__error">
              {form.postContent.errorMessage}
            </p>
          )}
        </div>

        <div>
          <PrimaryButton
            type="submit"
            clicked={addNewPost}
            disabled={addNewPostLoading || !isCanSubmitted()}
            text="نشر التدوينة"
          />
        </div>
        {addNewPostLoading && (
          <div className="spinner-parent ">
            <Spinner />
          </div>
        )}
        <ToastContainer rtl autoClose={500} closeButton={false} />
      </form>
    </Layout>
  );
};

export default AddPost;
