const contestsSubDirectory = "/v1/Contests";

export const getAllContests = `${contestsSubDirectory}/GetAllContests`;

export const getContest = `${contestsSubDirectory}/GetContestById`;

export const getContestInfo = `${contestsSubDirectory}/GetContestInfo`;

export const addNovel = `${contestsSubDirectory}/AddNovel`;

export const addToLibrary = `${contestsSubDirectory}/AddToLibrary`;

export const vote = `${contestsSubDirectory}/Vote`;
