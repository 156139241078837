// react
import React from "react";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom/client";
// components
import App from "./App";
import { GoogleOAuthProvider } from "@react-oauth/google";
// redux
import store from "./store/store";
import { Provider } from "react-redux";
// css
import "./styles/globals.css";
import { Helmet } from "react-helmet";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <GoogleOAuthProvider clientId={'1012037650838-2nalitsi1r2j6nvl43bhq0tsogsbp2qm.apps.googleusercontent.com'}>
     <Helmet>
        <meta property="og:title" content="Your Website Title" />
        <meta property="og:description" content="Description of your website" />
        <meta property="og:image" content="URL of the image you want to display" />
        <meta property="og:url" content="URL of your website" />
      </Helmet>
    <Provider store={store}>
      <BrowserRouter>
      
        <App />
      </BrowserRouter>
    </Provider>
  </GoogleOAuthProvider>
);
