// css
import classes from "./HeroText.module.css";

const HeroText = () => {
  return (
    <div className={classes.HeroText}>
      {/* <p className={classes.HeroText__Subtitle}>عِش عالمك المفضّل</p> */}
      <p className={classes.HeroText__Title}>عِش عالمك المفضّل   </p>
    </div>
  );
};

export default HeroText;
