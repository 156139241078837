import { createSlice } from "@reduxjs/toolkit";

export const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    addItem: (state, action) => {
      return [...state, action.payload];
    },
    addItems: (state, actions) => {
      return [...state, ...actions.payload];
    },
    removeItem: (state, action) => {
      return state.filter((cartItem) => cartItem.id !== action.payload.itemId);
    },
    emptyCart: () => {
      return [];
    },
  },
});

export const { addItem, addItems, removeItem, emptyCart } = cartSlice.actions;

export default cartSlice.reducer;
