// react router
import { Link } from "react-router-dom";
// components
import ContestsBanner from "../ContestsBanner/ContestsBanner";
import dateFormat from "dateformat";
// css
import classes from "./ContestsSection.module.css";

const ContestsSection = (props) => {
  // props
  const { contests, open } = props;

  return contests.length === 0 ? (
    <ContestsBanner
      title={
        open ? "لا توجد مسابقات فعالة حالياً" : "لا توجد مسابقات سابقة لعرضها"
      }
    />
  ) : (
    <div className={classes.ContestsSection}>
      <h2 className={classes.ContestsSection__Title}>
        {open ? "المسابقات الحالية" : "المسابقات السابقة"}
      </h2>
      <ul className={classes.ContestsSection__List}>
        {contests.map((contest, index) => (
          <li key={index} className={classes.ContestsSection__List__Item}>
            <div className={classes.ContestsSection__List__Item__Header}>
              <Link
                to={`/contests/${contest.contestId}/novels`}
                className={classes.ContestName}
              >
                {contest.contestName}
              </Link>
              <p className={classes.ContestDate}>
                تاريخ المسابقة من{" "}
                {dateFormat(contest.contestStartDate, "dd/mm/yyyy")}
                {" | "}
                {dateFormat(contest.contestEndDate, "dd/mm/yyyy")}
              </p>
            </div>
            {!open && (
              <Link
                to={`/authors/${contest.ContestWinnerUid[0]}`}
                className={classes.ContestWinner}
              >
                الفائز
              </Link>
            )}
            <p className={classes.ContestsSection__List__Item__Description}>
              {contest.contestDescription}
            </p>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContestsSection;
