// react
import { useState } from "react";
// redux
import { useSelector, useDispatch } from "react-redux";
import { removeItem, emptyCart } from "../../../store/cartSlice";
// components
import { toast } from "react-toastify";
import Spinner from "../../UI/Spinner/Spinner";
// services
import { removeFromCart, purchaseBooks } from "../../../services/books";
// css
import classes from "./CartItems.module.css";
// assets
import removeIcon from "../../../assets/icons/removeIcon.svg";
import { Link } from "react-router-dom";
import { makePayment } from "../../../services/cart";

export const CartItems = () => {
  // redux
  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart);
  // state
  const [purchaseBookLoading, setPurchaseBookLoading] = useState(false);
  const [removeBookLoading, setRemoveBookLoading] = useState(false);

  // handlers
  const cartItemRemovedHandler = async (cartItemId) => {
    const params = {
      id: cartItemId,
    };
    setRemoveBookLoading(true);
    try {
      await removeFromCart(params);
      dispatch(removeItem({ itemId: cartItemId }));
      toast.success("تمت حذف الكتاب من العربة", { position: "bottom-right" });
    } catch (error) {
      toast.error("حدث خطأ ما اثناء حذف الكتاب من العربة", {
        position: "bottom-right",
      });
    }
    setRemoveBookLoading(false);
  };

  const purchaseHandler = async () => {
    setPurchaseBookLoading(true);

    try {
      console.log(cartItems.reduce((accum, current) => current.price + accum, 0));
      const cartTotal = cartItems.reduce((accum, current) => current.price + accum, 0);
      if(cartTotal>0){
        var token= await makePayment();
        if(token.data.token!=null){
          
          window.open('https://accept.paymob.com/api/acceptance/iframes/730880?payment_token='+token.data.token,"_self");
  
        }
      }else{
        await purchaseBooks();
              dispatch(emptyCart());
      toast.success("تمت عملية الشراء بنجاح", { position: "bottom-right" });
      }
     

    } catch (error) {
      console.log(error);
      toast.error("حدث خطأ ما اثناء عملية الشراء", {
        position: "bottom-right",
      });
    }

    setPurchaseBookLoading(false);
  };

  // view
  let content;
  if (cartItems.length === 0) {
    content = (
      <div className={classes.CartItems__Empty}>
        <h2 className={classes.CartItems__Empty__Text}>
          عربة التسوق لديك فارغة
        </h2>
        <Link to="/books" className={classes.CartItems__Empty__Link}>
          تصفح الكتب
        </Link>
      </div>
    );
  } else {
    content = (
      <>
        <table className={classes.CartItems__Table}>
          <thead>
            <tr>
              <th>الكتاب</th>
              <th>السعر</th>
              <th>الحذف</th>
            </tr>
          </thead>
          <tbody>
            {cartItems.map((cartItem) => (
              <tr key={cartItem.id}>
                <td className={classes.Cover}>
                  <a
                    href={`/books/${cartItem.id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={cartItem.coverUrl} alt="book cover" />
                  </a>
                </td>
                <td className={classes.Price}>{`${cartItem.price} جنيه`}</td>
                <td className={classes.Remove}>
                  {removeBookLoading ? (
                    <Spinner />
                  ) : (
                    <img
                      src={removeIcon}
                      alt="remove"
                      role="button"
                      onClick={() => cartItemRemovedHandler(cartItem.id)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className={classes.CartItems__Summary}>
          <p className={classes.CartItems__Summary__Text}>الاجمالي</p>
          <p className={classes.CartItems__Summary__Total}>
            {cartItems.reduce((accum, current) => current.price + accum, 0)}{" "}
            جنيه
          </p>
          <button
            className={classes.CartItems__Summary__Complete}
            onClick={purchaseHandler}
            disabled={purchaseBookLoading}
          >
            {purchaseBookLoading ? <Spinner /> : "اتمام الشراء"}
          </button>
        </div>
      </>
    );
  }
  return <div className={classes.CartItems}>{content}</div>;
};

export default CartItems;
