// react router
import { Link } from "react-router-dom";
// components
import Logo from "../../../../UI/Logo/Logo";
// css
import classes from "./LogoSection.module.css";

const LogoSection = () => {
  return (
    <div className={classes.LogoSection}>
      <Link to="/" className={classes.LogoSection__Logo}>
        <Logo />
      </Link>
    </div>
  );
};

export default LogoSection;
