// react
import { useState, useEffect } from "react";
// components
import dateFormat from "dateformat";
import Error from "../../components/UI/Error/Error";
import Spinner from "../../components/UI/Spinner/Spinner";
import Info from "../../components/UI/Info/Info";
// services
import { getNotifications } from "../../services/notification";
// css
import classes from "./NotificationsMenu.module.css";

const NotificationsMenu = (props) => {
  // props
  const { closeMenu, containerRef } = props;
  // state
  const [notifications, setNotifications] = useState([]);
  const [notificationsLoading, setNotificationsLoading] = useState(true);
  const [notificationsError, setNotificationsError] = useState("");

  // effects
  useEffect(() => {
    const fetchData = async () => {
      setNotificationsLoading(true);
      setNotificationsError("");

      try {
        const response = await getNotifications();
        setNotifications(response.data.notifications);
      } catch (error) {
        setNotificationsError("حدث خطأ اثناء تحميل الاشعارات");
      }

      setNotificationsLoading(false);
    };

    fetchData();
  }, []);

  // effects
  useEffect(() => {
    const clickAwayHadler = (event) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target)
      ) {
        closeMenu();
      }
    };
    document.addEventListener("mousedown", clickAwayHadler);
    return () => {
      document.removeEventListener("mousedown", clickAwayHadler);
    };
  }, [closeMenu, containerRef]);

  let content;
  if (notificationsLoading) {
    content = <Spinner />;
  } else if (notificationsError) {
    content = (
      <div className="h-v-center w-90 text-center">
        <Error errorMessage={notificationsError} />
      </div>
    );
  } else if (notifications.length !== 0) {
    content = (
      <ul className={classes.NotificationsMenu__List}>
        {notifications.map((notification, index) => (
          <li key={index} className={classes.NotificationsMenu__List__Item}>
            <p className={classes.NotificationTitle}>
              {notification.notificationTitle}
            </p>
            <p className={classes.NotificationBody}>
              {notification.notificationBody}
            </p>
            <p className={classes.NotificationDate}>
              {dateFormat(notification.LastModified)}
            </p>
          </li>
        ))}
      </ul>
    );
  } else {
    content = (
      <div className="text-center">
        <Info infoMessage="لا توجد اشعارات" />
      </div>
    );
  }

  return (
    <div className={classes.NotificationsMenu}>
      <header className={classes.NotificationsMenu__Header}>
        <p className={classes.NotificationsMenu__Header__Title}>الاشعارات</p>
        <button
          className={classes.NotificationsMenu__Header__Close}
          onClick={() => closeMenu()}
        >
          x
        </button>
      </header>
      {content}
    </div>
  );
};

export default NotificationsMenu;
