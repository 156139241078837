// react
import { useState } from "react";
// components
import Overlay from "../../Overlay/Overlay";
import Modal from "../Modal";
import Spinner from "../../Spinner/Spinner";
import PrimaryButton from "../../PrimaryButton/PrimaryButton";
import CancelButton from "../../CancelButton/CancelButton";
import { toast } from "react-toastify";
// services
import { updateProfile } from "../../../../services/account";
// css
import classes from "./EmailModal.module.css";
// classnames
import classNames from "classnames";

const EmailModal = (props) => {
  // props
  const { visible, close, getDetails, currentEmail } = props;
  // state
  const [email, setEmail] = useState({
    value: currentEmail,
    hasError: true,
    touched: false,
    errorMessage: "",
  });
  const [updateLoading, setUpdateLoading] = useState(false);

  // handler
  const emailChangedHandler = (event) => {
    const { value } = event.target;
    // validate
    let valid = true;
    if (!value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
      valid = false;
    }
    // update state
    setEmail({
      touched: true,
      value,
      hasError: valid ? false : true,
      errorMessage: valid ? "" : "من فضلك ادخل بريد الكتروني صحيح",
    });
  };

  const closeHandler = () => {
    setEmail({
      value: currentEmail,
      hasError: true,
      touched: false,
      errorMessage: "",
    });
    setUpdateLoading(false);
    close();
  };

  const updateEmail = async (event) => {
    event.preventDefault();
    // validate
    if (email.hasError) {
      return;
    }
    // data
    const data = new FormData();
    data.append("email", email.value);
    setUpdateLoading(true);

    try {
      await updateProfile(data);
      getDetails();
      toast.success("تم تحديث بريدك الاكتروني بنجاح", {
        position: "bottom-right",
      });
      close();
    } catch {
      toast.error("حدث خطأ ما اثناء تحديث بريدك الالكتروني", {
        position: "bottom-right",
      });
    }

    setUpdateLoading(false);
  };

  return (
    <>
      {visible && <Overlay clicked={closeHandler} />}
      <Modal visible={visible}>
        <form onSubmit={updateEmail} className={classes.EmailForm}>
          <div className="input-control">
            <label
              htmlFor="email"
              className={classNames(
                "input-control__label",
                "input-control__label--required"
              )}
            >
              ادخل بريد الكتروني جديد
            </label>
            <input
              id="email"
              type="email"
              value={email.value}
              onChange={emailChangedHandler}
              className="input-control__input"
            />
            {email.touched && email.hasError && (
              <p className="input-control__error">{email.errorMessage}</p>
            )}
          </div>

          <div className={classes.EmailForm__Actions}>
            <div className={classes.EmailForm__Actions__Cancel}>
              <CancelButton
                type="button"
                clicked={closeHandler}
                text="غلق"
                rounded
              />
            </div>
            {updateLoading && (
              <div className="spinner-parent">
                <Spinner />
              </div>
            )}
            <div className={classes.EmailForm__Actions__Submit}>
              <PrimaryButton
                type="submit"
                text="تحديث"
                clicked={updateEmail}
                rounded
                disabled={email.hasError || updateLoading}
              />
            </div>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default EmailModal;
