// react
import { useState } from "react";
// redux
import { useSelector } from "react-redux";
// react router
import { useLocation, useNavigate } from "react-router-dom";
// components
import { ToastContainer, toast } from "react-toastify";
import ShareModal from "../../../UI/Modal/ShareModal/ShareModal";
import ReportModal from "../../../UI/Modal/ReportModal/ReportModal";
// services
import { likeBook, shareBook } from "../../../../services/books";
// css
import classes from "./BookActions.module.css";
// assets
import share from "../../../../assets/icons/share.svg";
import like from "../../../../assets/icons/like.svg";
import liked from "../../../../assets/images/liked.png";
import reportProblem from "../../../../assets/icons/reportProblem.svg";

const BookActions = (props) => {
  // redux
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  // props
  const { bookId, isLiked } = props;
  // state
  const [shareModalVisible, setShareModalVisible] = useState(false);
  const [reportModalVisible, setReportModalVisible] = useState(false);
  const [bookLiked, setBookLiked] = useState(isLiked);
  // react router
  const navigate = useNavigate();
  const location = useLocation();

  // handlers

  // share
  const shareModalClosedHandler = () => {
    setShareModalVisible(false);
  };
  const bookSharedHandler = () => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: false });
    } else {
      setShareModalVisible(true);
    }
  };

  const bookLinkCopyHandler = async () => {
    navigator.clipboard.writeText(document.URL);
    toast.success("تم نسخ الرابط بنجاح", { position: "bottom-right" });
    await shareBook(bookId);
  };

  // like
  const bookLikedHandler = async () => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: false });
    } else {
      const params = {
        bookId: Number(bookId),
      };
      try {
        await likeBook(params);
        setBookLiked((prevState) => !prevState);
        let toastMessage;
        if (bookLiked) {
          toastMessage = "تم إلغاء الإعجاب بالكتاب";
        } else {
          toastMessage = "تم تسجيل الإعجاب بالكتاب";
        }
        toast.success(toastMessage, { position: "bottom-right" });
      } catch (error) {
        let toastMessage;
        if (bookLiked) {
          toastMessage = "حدث خطا ما اثناء الغاء الاعجاب";
        } else {
          toastMessage = "حدث خطا ما اثناء تسجيل الاعجاب";
        }
        toast.error(toastMessage, {
          position: "bottom-right",
        });
      }
    }
  };

  // report
  const reportModalClosedHandler = () => {
    setReportModalVisible(false);
  };
  const bookReportedHandler = () => {
    if (!isAuthenticated) {
      navigate("/account", { state: location, replace: false });
    } else {
      setReportModalVisible(true);
    }
  };

  // view
  return (
    <>
      <ul className={classes.BookActions}>
        <li className={classes.BookActions__Action} title="مشاركة">
          <img
            src={share}
            alt="share"
            onClick={bookSharedHandler}
            role="button"
          />
        </li>
        <li className={classes.BookActions__Action} title="أعجبني">
          <img
            src={bookLiked ? liked : like}
            alt="like"
            onClick={bookLikedHandler}
            role="button"
          />
        </li>
        <li className={classes.BookActions__Action} title="الابلاغ عن مشكلة">
          <img
            src={reportProblem}
            alt="report problem"
            onClick={bookReportedHandler}
            role="button"
          />
        </li>
      </ul>
      <ShareModal
        visible={shareModalVisible}
        close={shareModalClosedHandler}
        onCopyClickHandler={bookLinkCopyHandler}
      />
      <ReportModal
        visible={reportModalVisible}
        close={reportModalClosedHandler}
        bookId={bookId}
      />
      <ToastContainer rtl autoClose={500} closeButton={false} />
    </>
  );
};

export default BookActions;
