// react
import { useState, useEffect, useCallback } from "react";
// react router
import { useParams } from "react-router-dom";
// components
import Layout from "../Layout/Layout";
import PostDetails from "./PostDetails/PostDetails";
import Info from "../UI/Info/Info";
import Error from "../UI/Error/Error";
import PostComments from "./PostComments/PostComments";
import { ToastContainer } from "react-toastify";
// sevices
import { getPost } from "../../services/posts";
import Spinner from "../UI/Spinner/Spinner";
// css
import classes from "./Post.module.css";

const Post = () => {
  // params
  const { postId } = useParams();
  // state
  const [post, setPost] = useState(null);
  const [postLoading, setPostLoading] = useState(true);
  const [postError, setPostError] = useState("");

  const getPostDetails = useCallback(
    async (withSpinner) => {
      const params = {
        Id: postId,
      };
      if (withSpinner) {
        setPostLoading(true);
      }
      setPostError("");
      try {
        const response = await getPost(params);
        setPost(response.data.post);
      } catch (error) {
        setPostError("حدث خطأ ما أثناء تحميل تفاصيل التدوينة");
      }
      setPostLoading(false);
    },
    [postId]
  );

  // effects
  useEffect(() => {
    if (postId && !isNaN(postId)) {
      getPostDetails(true);
    } else {
      setPostLoading(false);
    }
  }, [postId, getPostDetails]);

  let content;
  if (postLoading) {
    content = <Spinner />;
  } else if (postError) {
    content = (
      <div className="h-v-center w-60 text-center">
        <Error errorMessage={postError} />
      </div>
    );
  } else if (post) {
    content = (
      <>
        <PostDetails
          post={post}
          getPostDetails={getPostDetails}
          isLiked={post.isLiked}
        />
        <PostComments
          comments={post.comments || []}
          getPostDetails={getPostDetails}
        />
      </>
    );
  } else {
    content = (
      <div className="h-v-center w-60 text-center">
        <Info infoMessage="عفواً لا يوجد تفاصيل لهذه التدوينة" />
      </div>
    );
  }

  return (
    <Layout shortHeader>
      <div className={classes.Post}>{content}</div>
      <ToastContainer rtl autoClose={500} closeButton={false} />
    </Layout>
  );
};

export default Post;
