// react router dom
import { Link } from "react-router-dom";
// components
import Book from "../../Books/Book/Book";
import AuthorBadge from "../../UI/Badge/AuthorBadge/AuthotBadge";
import BookActions from "./BookActions/BookActions";
import BookStats from "./BookStats/BookStats";
import BookReview from "./BookReview/BookReview";
import Info from "../../UI/Info/Info";
// css
import classes from "./BookDetails.module.css";
import classNames from "classnames";
import { Helmet } from "react-helmet";

const BookDetails = (props) => {
  // props
  const { book } = props;

  return (

    <section className={classes.BookDetails}>
          <Helmet>
          <meta charSet="utf-8" />
                <title>Book Details</title>
                <meta property="og:url"                content="https://www.bookcloudme.com" />
    <meta property="og:type"               content="article" />
    <meta property="og:title"              content="BookCloud Selfpublishing platform" />
    <meta property="og:description"        content="المنصة الرقمية الاولى في الشرق الأوسط.. بوك كلاود هي المنصة الأنسب لنشر عملك الإبداعي.. فقط بضغطة زر تنشر ابداعاتك وسط الجمهور..ويتواجد كتابك على ارفف مكتبات القراء الالكترونية... معنا ستنشر ابداعاتك وتؤثر فيمن حولك ...فلتكن ”سحاباً يمطر ابداعاً” ... واجعل قرائك يحلقون في سماء ابداعاتك."    />
    <meta property="og:image"              content="https://media.licdn.com/dms/image/C4D0BAQH9wyjafHoCsg/company-logo_200_200/0/1673901184510?e=1693440000&v=beta&t=chSkAvVBmtu5QStHIRdhGs_OuZzYq2_eGAJWyubz-pA" />
      </Helmet>
      <div className={classes.BookDetails__Book}>
        <Book book={book} hideTitle hideAuthorName />
      </div>
      <div className={classes.BookDetails__Details}>
        <header className={classes.BookDetails__Details__Header}>
          <div>
            <ul
              className={classes.BookDetails__Details__Header__Classifications}
            >
              {book.categories.map((category, index) => (
                <li
                  key={index}
                  className={
                    classes.BookDetails__Details__Header__Classifications__Classification
                  }
                >
                  <Link
                    to={`/books?categoryId=${category.CategoryId}`}
                    target="_blank"
                  >
                    {category.CategoryName}
                  </Link>
                </li>
              ))}
            </ul>
            <h2 className={classes.BookDetails__Details__Header__Title}>
              {book.title}
            </h2>
            <div className={classes.BookDetails__Details__Header__Author}>
              <div
                className={classes.BookDetails__Details__Header__Author__Badge}
              >
                <AuthorBadge imageSource={book.writerImageUrl} />
              </div>
              <Link
                to={`/authors/${book.writerUserId}`}
                className={classes.BookDetails__Details__Header__Author__Name}
                target="_blank"
              >
                {book.writerUserName}
              </Link>
            </div>
          </div>
          <div>
            <p className={classes.BookDetails__Details__Header__ReviewText}>
              قيّم الكتاب
            </p>
            <BookReview bookId={book.id} />
          </div>
        </header>
        {book.description ? (
          <p className={classes.BookDetails__Details__About}>
            {book.description}
          </p>
        ) : (
          <div
            className={classNames(
              classes.BookDetails__Details__About,
              "text-center w-60 mx-auto"
            )}
          >
            <Info infoMessage="لا يوجد وصف لهذا الكتاب" />
          </div>
        )}

        <footer className={classes.BookDetails__Details__Footer}>
          <BookActions bookId={book.id} isLiked={book.isLiked} />
          <BookStats
            shares={book.NumOfShares}
            comments={book.numberOfRatings}
            likes={book.NumOfLikes}
          />
        </footer>
      </div>
    </section>
  );
};

export default BookDetails;
