// assets
import prevArrow from "../../assets/icons/prevArrow.svg";
import nextArrow from "../../assets/icons/nextArrow.svg";
// css
import classes from "./Pagination.module.css";
// classnames
import ReactPaginate from "react-paginate";

const Pagination = (props) => {
  // props
  const { total, pageSize, changePage, currentPage } = props;

  // data
  const pageCount = Math.ceil(total / pageSize);

  const pageChangedHandler = (event) => {
    changePage(event.selected + 1);
  };

  // the view
  return (
    <div className={classes.Pagination}>
      <ReactPaginate
        forcePage={currentPage - 1}
        nextLabel={
          <img
            src={nextArrow}
            className={classes.Pagination__arrow}
            alt="next arrow"
          />
        }
        nextClassName={classes.Pagination__Pages__Page}
        onPageChange={pageChangedHandler}
        pageRangeDisplayed={10}
        marginPagesDisplayed={2}
        pageCount={pageCount}
        renderOnZeroPageCount={null}
        previousLabel={
          <img
            src={prevArrow}
            className={classes.Pagination__arrow}
            alt="prev arrow"
          />
        }
        previousClassName={classes.Pagination__Pages__Page}
        pageClassName={classes.Pagination__Pages__Page}
        breakLabel="..."
        breakClassName={classes.Pagination__Pages__Page}
        containerClassName={classes.Pagination__Pages}
        activeClassName={classes["Pagination__Pages__Page--selected"]}
      />
    </div>
  );
};

export default Pagination;
