// react router
import { useLocation } from "react-router-dom";
// components
import Ad from "./Ad/Ad";
// constants
import { VERTICAL_AD } from "../../../constants/ads";
// helpers
import { getAdPriority } from "../../../helpers/ad";
// css
import classes from "./AdSider.module.css";

const AdSider = () => {
  const location = useLocation();
  const { pathname } = location;

  return (
    <aside className={classes.AdSider}>
      <Ad
        type={VERTICAL_AD}
        margin={{ bottom: 33 }}
        priority={getAdPriority(pathname)}
      />
      <Ad
        type={VERTICAL_AD}
        margin={{ bottom: 33 }}
        priority={getAdPriority(pathname)}
      />
      <Ad
        type={VERTICAL_AD}
        margin={{ bottom: 0 }}
        priority={getAdPriority(pathname)}
      />
    </aside>
  );
};

export default AdSider;
