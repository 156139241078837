// assets
import mobileWhiteLogo from "../../../../assets/images/mobileWhiteLogo.png";
// css
import classes from "./MobileLogo.module.css";

const MobileLogo = () => {
  return (
    <img src={mobileWhiteLogo} alt="book-cloud-logo" className={classes.Logo} />
  );
};

export default MobileLogo;
