// css
import classes from "./SiteMap.module.css";
// components
import LogoSection from "./LogoSection/LogoSection";
import SocialAccounts from "./SocialAccounts/SocialAccounts";
import AppSection from "./AppSection/AppSection";

const SiteMap = () => {
  return (
    <section className={classes.Sitemap}>
      <LogoSection />
      <SocialAccounts />
      <AppSection />
    </section>
  );
};

export default SiteMap;
