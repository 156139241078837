// react
import { useEffect, useState } from "react";
// components
import Overlay from "../../UI/Overlay/Overlay";
import Error from "../../UI/Error/Error";
import Info from "../../UI/Info/Info";
// services
import { getBookCategories } from "../../../services/books";
import Spinner from "../../UI/Spinner/Spinner";
// css
import classes from "./FilterSider.module.css";
import classNames from "classnames";

const FilterSider = (props) => {
  // props
  const { categorySelectedHandler, currentCategoryId } = props;
  // state
  const [bookCategories, setBookCategories] = useState([]);
  const [bookCategoriesLoading, setBookCategoriesLoading] = useState(true);
  const [bookCategoriesError, setBookCategoriesError] = useState("");
  const [filterOpen, setFilterOpen] = useState(false);

  // handlers
  const showFilter = () => {
    setFilterOpen(true);
  };
  const hideFilter = () => {
    setFilterOpen(false);
  };

  useEffect(() => {
    const getCategories = async () => {
      setBookCategoriesLoading(true);

      try {
        const response = await getBookCategories();
        setBookCategories(response.data);
      } catch (error) {
        console.log(error);
        setBookCategoriesError("حدث خطأ أثناء تحميل الأقسام");
      }

      setBookCategoriesLoading(false);
    };

    getCategories();
  }, []);

  // the view
  let content;
  if (bookCategoriesLoading) {
    content = <Spinner />;
  } else if (bookCategoriesError) {
    content = (
      <div className="h-v-center w-90">
        <Error errorMessage={bookCategoriesError} />
      </div>
    );
  } else if (bookCategories.length !== 0) {
    content = (
      <ul className={classes.FilterSider__List}>
        {bookCategories.map((category) => (
          <li
            key={category.id}
            role="button"
            onClick={() => categorySelectedHandler(category.id)}
            className={classNames(classes.FilterSider__List__Item, {
              [classes["FilterSider__List__Item--selected"]]:
                category.id === Number(currentCategoryId),
            })}
          >
            {category.name}
          </li>
        ))}
      </ul>
    );
  } else {
    content = (
      <div className="h-v-center w-90">
        <Info infoMessage="عفوأ لا توجد أقسام حاليا" />
      </div>
    );
  }

  return (
    <>
      <aside
        className={classNames(classes.FilterSider, {
          [classes["FilterSider--open"]]: filterOpen,
        })}
      >
        <h2 className={classes.FilterSider__Header}>فلتر الاقسام</h2>
        {content}
      </aside>
      <button className={classes.FilterToggle} onClick={showFilter}>
        <span className={classes.FilterToggle__Icon}></span>
      </button>
      {filterOpen && <Overlay clicked={hideFilter} />}
    </>
  );
};

export default FilterSider;
