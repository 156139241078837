// react router
import { useLocation } from "react-router-dom";
// components
import Ad from "../Layout/AdSider/Ad/Ad";
import Layout from "../Layout/Layout";
import CartItems from "./CartItems/CartItems";
import { ToastContainer } from "react-toastify";
// constants
import { HORIZONTAL_AD } from "../../constants/ads";
// helpers
import { getAdPriority } from "../../helpers/ad";

const Cart = () => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <Layout shortHeader>
      <CartItems />
      <Ad
        type={HORIZONTAL_AD}
        margin={{ bottom: 664 }}
        priority={getAdPriority(pathname)}
      />
      <ToastContainer rtl autoClose={500} closeButton={false} />
    </Layout>
  );
};

export default Cart;
