// endpoints
import * as cartEndPoints from "../constants/endpoints/cart";
// axios
import { axiosPrivateInstance } from "../axios/axios";

export const getCartItems = () => {
  return axiosPrivateInstance({
    url: cartEndPoints.getCartItems,
    method: "GET",
  });
};
export const makePayment = () => {
  return axiosPrivateInstance({
    url: cartEndPoints.makePayment,
    method: "GET",
  });
};