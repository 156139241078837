// react router
import { Link } from "react-router-dom";
// css
import classes from "./Novel.module.css";
import classNames from "classnames";

const Novel = (props) => {
  // props
  const { novel, onFavouriteClick, onLibraryClick } = props;

  return (
    <li className={classes.Novel}>
      <div className={classes.Novel__Author}>
        <p className={classes.Novel__Name}>{novel.Name}</p>
        <p className={classes.Novel__Author__Name}>
          <span>تأليف</span>
          <Link to={`/authors/${novel.WriterUserId}`}>
            {novel.WriterUserName}
          </Link>
        </p>
      </div>
      <p className={classes.Novel__Content}>{novel.Content}</p>
      <div className={classes.Novel__Actions}>
        <button
          className={classNames(
            classes.Novel__Actions__Lib,
            classes.Novel__Actions__Action
          )}
          onClick={() => onLibraryClick(novel.Id, novel.IsAddedToLibrary)}
        >
          {novel.IsAddedToLibrary ? "ازالة من المكتبة" : "اضف للمكتبة"}
        </button>
        <button
          onClick={() => onFavouriteClick(novel.Id, novel.IsFavourite)}
          className={classNames(
            classes.Novel__Actions__Fav,
            classes.Novel__Actions__Action,
            {
              [classes["Novel__Actions__Fav--yes"]]: novel.IsFavourite,
            }
          )}
        />
      </div>
    </li>
  );
};

export default Novel;
