// react router
import { Link } from "react-router-dom";
// components
import Slick from "../UI/Slick/Slick";
import Info from "../UI/Info/Info";
// css
import classes from "./HorizontalNovels.module.css";

const carouselSettings = {
  pagination: false,
  itemsToShow: 4,
  itemsToScroll: 1,
  isRTL: true,
};

const breakPoints = [
  { width: 1, itemsToShow: 1 },
  { width: 400, itemsToShow: 2 },
  { width: 740, itemsToShow: 4 },
];

const HorizontalNovels = (props) => {
  const { novels, title } = props;

  let content;

  if (novels.length !== 0) {
    content = (
      <Slick settings={carouselSettings} breakPoints={breakPoints}>
        {novels.map((novel, index) => (
          <div className={classes.Novel} key={index}>
            
          <img
            src={novel.NovelImageUrl}
            alt="novel cover"
            className={classes.Book__Cover__Img}
          />
   
            <p className={classes.Novel__Name}>{novel.Name}</p>
            <Link
              className={classes.Novel__WriterName}
              to={`/authors/${novel.WriterUserId}`}
            >
              {novel.WriterName}
            </Link>
          </div>
        ))}
      </Slick>
    );
  } else {
    content = (
      <div className="h-v-center text-center">
        <Info infoMessage="لا توجد لديك روايات" />
      </div>
    );
  }
  return (
    <section className={classes.HorizontalNovels}>
      <header className={classes.HorizontalNovels__Header}>
        <h2 className={classes.HorizontalNovels__Header__Title}>{title}</h2>
      </header>
      <section>{content}</section>
    </section>
  );
};

export default HorizontalNovels;
