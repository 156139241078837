// components
import Layout from "../Layout/Layout";
// css
import classes from "./PrivacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <Layout shortHeader>
      <h1>سياسة الخصوصية لموقع BookCloudme.com</h1>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>
          الخصوصية وبيان سريّة المعلومات
        </h2>
        <p className={classes.Policy__Content}>
          نقدر مخاوفكم واهتمامكم بشأن خصوصية بياناتكم على شبكة الإنترنت. لقد تم
          إعداد هذه السياسة لمساعدتكم في تفهم طبيعة البيانات التي نقوم بتجميعها
          منكم عند زيارتكم لموقعنا على شبكة الانترنت وكيفية تعاملنا مع هذه
          البيانات الشخصية.
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>التصفح</h2>
        <p className={classes.Policy__Content}>
          لم نقم بتصميم هذا الموقع من أجل تجميع بياناتك الشخصية من جهاز
          الكمبيوتر الخاص بك أو جهازك المحمول أثناء تصفحك لهذا الموقع, وإنما
          سيتم فقط استخدام البيانات المقدمة من قبلك{" "}
          <strong>بمعرفتك ومحض إرادتك.</strong>
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>
          عنوان بروتوكول شبكة الإنترنت (IP)
        </h2>
        <p className={classes.Policy__Content}>
          في أي وقت تزور فيه اي موقع انترنت بما فيها هذا الموقع , سيقوم السيرفر
          المضيف بتسجيل عنوان بروتوكول شبكة الإنترنت <strong>(IP)</strong> الخاص
          بك , تاريخ ووقت الزيارة ونوع متصفح الإنترنت الذي تستخدمه والعنوان URL
          الخاص بأي موقع من مواقع الإنترنت التي تقوم بإحالتك إلى هذا الموقع على
          الشبكة.
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>عمليات المسح على الشبكة</h2>
        <p className={classes.Policy__Content}>
          إن عمليات المسح التي نقوم بها مباشرة على الشبكة تمكننا من تجميع بيانات
          محددة مثل البيانات المطلوبة منك بخصوص{" "}
          <strong>نظرتك وشعورك تجاه موقعنا</strong> . تعتبر ردودك ذات أهمية قصوى
          , ومحل تقديرنا حيث أنها تمكننا من تحسين مستوى موقعنا, ولك كامل الحرية
          والاختيار في تقديم البيانات المتعلقة باسمك والبيانات الأخرى.
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>
          الروابط بالمواقع الأخرى على شبكة الإنترنت
        </h2>
        <p className={classes.Policy__Content}>
          قد يشتمل موقعنا على روابط بالمواقع الأخرى على شبكة الإنترنت. او
          اعلانات من مواقع اخرى مثل Google AdSense ولا نعتبر مسئولين عن أساليب
          تجميع البيانات من قبل تلك المواقع, يمكنك الاطلاع على سياسات السرية
          والمحتويات الخاصة بتلك المواقع التي يتم الدخول إليها من خلال أي رابط
          ضمن هذا الموقع. قد نستعين بشركات إعلان لأطراف ثالثة لعرض الإعلانات
          عندما تزور موقعنا على الويب. يحق لهذه الشركات أن تستخدم معلومات حول
          زياراتك لهذا الموقع ولمواقع الويب الأخرى{" "}
          <strong>
            (باستثناء الاسم أو العنوان أو عنوان البريد الإلكتروني أو رقم الهاتف ومعلوماتك البنكية)
          </strong>
          ، وذلك من أجل تقديم إعلانات حول البضائع والخدمات التي تهمك. إذا كنت
          ترغب في مزيد من المعلومات حول هذا الأمر وكذلك إذا كنت تريد معرفة
          الاختيارات المتاحة لك لمنع استخدام هذه المعلومات من قِبل هذه الشركات ،
          فالرجاء التواصل معنا.
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>إفشاء المعلومات</h2>
        <p className={classes.Policy__Content}>
          سنحافظ في كافة الأوقات على <strong>خصوصية وسرية</strong> كافة البيانات
          الشخصية التي نتحصل عليها. ولن يتم <strong>إفشاء</strong> هذه المعلومات
          إلا إذا كان ذلك مطلوباً بموجب أي قانون أو عندما نعتقد بحسن نية أن مثل
          هذا الإجراء سيكون مطلوباً أو مرغوباً فيه للتماشي مع القانون, أو للدفاع
          عن أو حماية حقوق الملكية الخاصة بهذا الموقع أو الجهات المستفيدة منه.
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>
          البيانات اللازمة لتنفيذ المعاملات المطلوبة من قبلك
        </h2>
        <p className={classes.Policy__Content}>
          عندما نحتاج إلى أية بيانات خاصة بك , فإننا{" "}
          <strong>سنطلب منك تقديمها بمحض إرادتك</strong>. حيث ستساعدنا هذه
          المعلومات في الاتصال بك وتنفيذ طلباتك حيثما كان ذلك ممكناً. لن يتم
          اطلاقاً <strong>بيع البيانات المقدمة من قبلك إلى أي طرف ثالث</strong>{" "}
          بغرض تسويقها لمصلحته الخاصة دون الحصول على موافقتك المسبقة والمكتوبة
          ما لم يتم ذلك على أساس أنها ضمن بيانات جماعية تستخدم للأغراض الإحصائية
          والأبحاث دون اشتمالها على أية بيانات من الممكن استخدامها للتعريف بك.
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>عند الاتصال بنا</h2>
        <p className={classes.Policy__Content}>
          سيتم التعامل مع كافة البيانات المقدمة من قبلك على أساس أنها{" "}
          <strong>سرية</strong> . تتطلب النماذج التي يتم تقديمها مباشرة على
          الشبكة تقديم البيانات التي ستساعدنا في تحسين موقعنا. سيتم استخدام
          البيانات التي يتم تقديمها من قبلك في الرد على كافة استفساراتك ,
          ملاحظاتك , أو طلباتك من قبل هذا الموقع أو أيا من المواقع التابعة له.
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>إفشاء المعلومات لأي طرف ثالث</h2>
        <p className={classes.Policy__Content}>
          لن نقوم{" "}
          <strong>ببيع , المتاجرة , تأجير , أو إفشاء أية معلومات</strong> لمصلحة
          أي طرف ثالث خارج هذا الموقع, أو المواقع التابعة له. وسيتم الكشف عن
          المعلومات فقط في حالة صدور أمر بذلك من قبل أي سلطة قضائية أو تنظيمية.
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>جمع المعلومات واستخدامها</h2>
        <p className={classes.Policy__Content}>
          لتستمتع بتجربة أفضل عند استخدام خدماتنا٬ قد نطلب منك تزويدنا ببعض
          المعلومات المحددة للهوية الشخصية. سنحتفظ بالمعلومات التي نطلبها
          ونستخدمها حسب ما هو موضّح في سياسة الخصوصية هذه يستخدم تطبيق بوكّلاود  
          خدمات أطراف ثالثة قد تجمع معلومات قادرة على تحديد هويتك الشخصية. هذه
          روابط لمقدّمي الخدمات من الأطراف الثالثة التي يستخدمها تطبيق 
          بوكّلاود :
        </p>
        <ul className={classes.Policy__Content__Refs}>
          <li className={classes.Policy__Content__Refs__Ref}>
            <a
              className={classes.Policy__Content__Refs__Ref__Link}
              href="https://policies.google.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Google Play Services
            </a>
          </li>
          <li className={classes.Policy__Content__Refs__Ref}>
            <a
              className={classes.Policy__Content__Refs__Ref__Link}
              href="https://firebase.google.com/policies/analytics"
              target="_blank"
              rel="noreferrer"
            >
              Google Analytics for Firebase
            </a>
          </li>
          <li className={classes.Policy__Content__Refs__Ref}>
            <a
              className={classes.Policy__Content__Refs__Ref__Link}
              href="https://firebase.google.com/support/privacy/"
              target="_blank"
              rel="noreferrer"
            >
              Firebase Crashlytics
            </a>
          </li>
          <li className={classes.Policy__Content__Refs__Ref}>
            <a
              className={classes.Policy__Content__Refs__Ref__Link}
              href="https://www.facebook.com/about/privacy/update/printable"
              target="_blank"
              rel="noreferrer"
            >
              Facebook
            </a>
          </li>
          <li className={classes.Policy__Content__Refs__Ref}>
            <a
              className={classes.Policy__Content__Refs__Ref__Link}
              href="https://onesignal.com/privacy_policy"
              target="_blank"
              rel="noreferrer"
            >
              One Signal
            </a>
          </li>
          <li className={classes.Policy__Content__Refs__Ref}>
            <a
              className={classes.Policy__Content__Refs__Ref__Link}
              href="https://amplitude.com/privacy"
              target="_blank"
              rel="noreferrer"
            >
              Amplitude
            </a>
          </li>
        </ul>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>
          التعديلات على سياسة سرية وخصوصية المعلومات
        </h2>
        <p className={classes.Policy__Content}>
          نحتفظ بالحق في تعديل بنود وشروط سياسة سرية وخصوصية المعلومات إن لزم
          الأمر ومتى كان ذلك ملائماً. سيتم تنفيذ التعديلات هنا او على صفحة سياسة
          الخصوصية الرئيسية وسيتم بصفة مستمرة إخطارك بالبيانات التي حصلنا عليها
          , وكيف سنستخدمها والجهة التي سنقوم بتزويدها بهذه البيانات.
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>الاتصال بنا</h2>
        <p className={classes.Policy__Content}>
          يمكنكم الاتصال بنا عند الحاجة من خلال الضغط على رابط اتصل بنا المتوفر
          في روابط موقعنا او الارسال الى بريدنا{" "}
          <a
            href="mailto:info@bookcloudme.com"
            className={classes.Policy__Content__Link}
          >
            info@bookcloudme.com
          </a>
        </p>
      </div>
      <div className={classes.Policy}>
        <h2 className={classes.Policy__Title}>اخيرا</h2>
        <p className={classes.Policy__Content}>
          إن مخاوفك واهتمامك بشأن{" "}
          <strong>
            سرية وخصوصية البيانات تعتبر مسألة في غاية الأهمية بالنسبة لنا
          </strong>
          . نحن نأمل أن يتم تحقيق ذلك من خلال هذه السياسة.
        </p>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;

<strong></strong>;
