// react
import { useEffect } from "react";
// css
import classes from "./Overlay.module.css";

const Overlay = (props) => {
  // porps
  const { clicked } = props;
  // effect
  useEffect(() => {
    const windowResizedHandler = () => {
      if (window.innerWidth >= 1024) {
        clicked();
      }
    };
    window.addEventListener("resize", windowResizedHandler);

    return () => {
      window.removeEventListener("resize", windowResizedHandler);
    };
    // eslint-disable-next-line
  }, []);

  // view
  return <div onClick={clicked} className={classes.Overlay} />;
};

export default Overlay;
