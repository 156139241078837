// components
import Overlay from "../../Overlay/Overlay";
import Modal from "../Modal";
// css
import classes from "./ShareModal.module.css";

const ShareModal = (props) => {
  // props
  const { visible, close, onCopyClickHandler } = props;

  // handlers
  const copyToClipboardHandler = async () => {
    onCopyClickHandler();
    close();
  };

  return (
    <>
      {visible && <Overlay clicked={close} />}
      <Modal visible={visible}>
        <div className={classes.ShareModal}>
          <p className={classes.ShareModal__Text}>رابط الصفحة</p>
          <div className={classes.ShareModalControl}>
            <input
              type="text"
              defaultValue={document.URL}
              className={classes.ShareModalControl__Input}
            />
            <button
              className={classes.ShareModalControl__Button}
              onClick={copyToClipboardHandler}
              title="نسخ الرابط"
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ShareModal;
